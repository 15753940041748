<template>

    <overlay :disabled="!open">

        <Transition name="sidebar" appear>

            <div :class="getClass" v-click-outside="hide" v-if="open">

                <div class="icon-close" @click.stop="hide">

                    <svg-icon :viewbox="'0 0 32 32'" width="32" height="32"><close-icon /></svg-icon>

                </div>

                <div class="title-group" v-if="options.header">

                    <label class="label">{{ options.header.type }}</label>

                    <h2 class="title">{{ options.header.title }}</h2>

                    <h4 class="subtitle" v-if="options.header.subTitle">{{ options.header.subTitle }}</h4>
                    <status-tag v-if="options.header.statusTag" :type="options.header.statusTag.type" :icon="options.header.statusTag.type">
                        {{ options.header.statusTag.text }}
                    </status-tag>

                </div>

                <spinner-loader v-if="loading" />

                <div class="container" v-else>

                    <slot></slot>

                </div>

                <div class="sidebar-button-container">

                    <button-input class="button background-success grey-darker-text-color icon-reverse" type="submit" iconImg="arrow-full-right" :text="options.submit.text ? options.submit.text : $t('see')" @click="submit(options.submit.action)" v-if="options.submit" :disabled="options.submit.disabled" />

                    <button-input class="button background-transparent grey-dark-text-color grey-light-border-color" type="submit" :text="options.edit.text ? options.edit.text : $t('see')" iconColor="#767676" iconImg="edit" @click="submit(options.edit.action)" v-if="options.edit && !options.edit.disabled" />

                    <button-input class="button background-transparent grey-dark-text-color grey-light-border-color" type="submit" :text="options.delete.text ? options.delete.text : $t('see')"  iconColor="#767676" iconImg="delete" @click="submit(options.delete.action)" v-if="options.delete" :disabled="options.delete.disabled" />

                </div>

            </div>

        </Transition>

    </overlay>

</template>

<script>
    import Overlay from '~/components/overlay'
    import SvgIcon from '~/components/svg-icon'
    import SpinnerLoader from '~/components/spinner-loader'
    import CloseIcon from '~/components/icons/button-icons/close-icon'
    import StatusTag from "@/components/status-tag.vue";

    export default {
        components: {
            StatusTag,
            Overlay,
            SvgIcon,
            SpinnerLoader,
            CloseIcon
        },

        data() {
            return {
                open: false,
                loading: false
            }
        },

        props: {
            options: {
                type: Object,
                default() {
                    return {}
                }
            }
        },

        methods: {
            async submit(action) {

                await action.call()

                this.hide()
            },

            async show() {
                try {

                    this.open = true

                    this.loading = true

                    if (typeof this.options.fetch !== 'undefined') await this.options.fetch.call()

                } catch(error) {

                    throw error

                } finally {

                    this.loading = false
                }
            },

            async hide() {
                try {

                    if (typeof this.options.close !== 'undefined') {

                        await this.options.close.call()
                    }

                    this.open = false

                } catch(error) {

                    throw error
                }
            }
        },

        computed : {
            getClass() {
                const className = "sidebar";
                return this.options.class ? ` ${className} ${this.options.class} ` : className;
            }

        }

    }
</script>

<i18n>
    {
        "fr": {
            "see": "Voir la fiche complète"
        }
    }
</i18n>

<style lang="scss" scoped>
    $blue-black: #BED000;
    $linear-gradient: linear-gradient(to top, white 75%, transparent);

    .sidebar-enter-active {
        animation: slideX-anim .2s ease-in-out;
    }

    .sidebar-leave-active {
        animation: slideX-anim .2s reverse ease-in-out;
    }

    .sidebar {
        z-index: 20;
        position: fixed;
        right: 0;
        top: 0;
        height: 100%;
        z-index: 100;
        background: white;
        width: 325px;
        overflow: auto;
        padding: 50px 24px;

        &.large {
            width:400px;
        }

        .title-group {
            margin-top: 15px;
            border-bottom: 1px solid #DEE3EF;
            padding-bottom: 24px;
            margin-bottom: 24px;

            .label {
                color: $blue-black;
                font-size: 10.5pt;
                font-weight: 500;
            }

            .title {
                @include body;
                font-size: 18pt;
                margin-top: 8px;
                font-weight: 500;
            }

            .subtitle {
                margin-top: 8px;
                color: #8D939F;
            }

            .status-tag {
                margin-top:10px
            }
        }

        .container {
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: 8px;
            padding-bottom: 260px;

            &::v-deep {
                .section-header {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    margin-top: 10px;

                    .title {
                        font-size: 13.5pt;
                        margin-left: 10px;
                    }
                }

                .content-text {
                    white-space: break-spaces;

                    &.empty {
                        color: #BED000 !important;
                        text-decoration: underline !important;
                    }
                }

                .readonly-spacing {
                    margin-bottom: 18px;
                }

                .separator {
                    border: 0;
                    background: #DEE3EF;
                    margin: 6px 0;
                    height: 1px;
                }

                .separator-min {
                    margin: 7px auto 25px auto;
                    width: 72px;
                    height: 1px;
                    background: #F2F2F2;
                    border-radius: 5px;
                    border: none;
                }
            }
        }

        .sidebar-button-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: $linear-gradient;
            position: fixed;
            bottom: 0;
            padding-bottom: 20px;
            width:inherit;
            gap:10px;

            .button {
                width: 100%;
            }
        }
    }

    .icon-close {
        position: absolute;
        margin-bottom: 24px;
        top: 24px;
        right: 24px;
        position: fixed;
        z-index: 20;
        cursor: pointer;
    }
</style>
