<template>
    
    <div :class="['tenant-list-property', { 'empty': !tenants.length }]">

        <div class="tenant-list-wrapper">

            <svg-icon class="icon" iconColor="#BED000"  width="24" height="24" :viewbox="'0 0 24 24'" ><tenant-icon /></svg-icon>

            <span class="tenant" v-if="!tenants.length">-</span>

            <div class="tenant" @click.stop="fetchTenant" v-else-if="tenants.length">

                {{ $t('nb_tenants', { nb: tenants.length }) }}

                <arrow class="arrow" :active="!collapsed" :width="16" :height="16" color="#BED000" />

            </div>

        </div>

        <div class="tenant-infos" v-for="(tenant, index) in tenantsData" :key="index" @click.stop="showSideBar(index)">
            
            {{ getProfileFullName(tenant) }}

            <tenant-sidebar ref="TenantSidebar" :tenantId="tenant['_jv'].id" :data-key="index"/>

        </div>
        
    </div>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import TenantIcon from '~/components/icons/table-cell-icons/tenant-icon'
    import FullNameMixin from '~/components/mixins/full-name'
    import Arrow from '~/components/arrow'
    import TenantSidebar from '~/components/sidebar/tenant-sidebar'
    import { mapGetters } from 'vuex'

    export default {

        fetchOnServer: false,
        
        mixins: [FullNameMixin],

        components: {
            Arrow,
            TenantSidebar,
            SvgIcon,
            TenantIcon
        },

        data() {
            return {
                tenantsData: [],
                collapsed: true
            }
        },

        props: {
            tenantIds: {
                type: Array,
                required: true
            },
            openSidebar: {
                type: Boolean,
                default: true
            }
        },

        methods: {
            showSideBar(i) {
                const sidebar = this.$refs['TenantSidebar'].find(
                    el => el.$attrs['data-key'] === i
                );

                if (this.isManager) sidebar.show()
               
            },

            async fetchTenant() {
                this.tenantsData = []

                if (this.collapsed) {
                    
                       for (let tenantId of this.tenantIds) {

                        let data = await this.$store.dispatch('profile/fetchTenant', tenantId)

                        this.tenantsData.push(data)
                    }
                }

                this.collapsed = !this.collapsed
            }
        },

        computed: {
            tenants() {
                return this.tenantIds.map(tenantId => {
                    
                    return this.$store.getters['tyltoApi/get'](`tenants/${tenantId}`)
                })
            },

            ...mapGetters({
                isManager: 'isManager'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "nb_tenants": "{nb} locataires"
        }
    }
</i18n>

<style lang="scss" scoped>
    $tenant: #BED000;

    .tenant-list-property {
        margin-top: 6px;

        &.empty {
            opacity: .5;
        }

        .tenant-list-wrapper {
            display: flex;

            .tenant {
                display: flex;
                align-items: center;
                @include body;
                color: $tenant;
                text-decoration-color: $tenant;

                .arrow {
                    border-bottom-color: $tenant;
                    margin-left: 9px;
                }

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .icon {
                margin-right: 7px;
                min-width: 24px;
            }
        }

        .tenant-infos {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            @include body;
            color: $tenant;
            text-decoration-color: $tenant;
            margin: 4px 0px 0px 32px;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
</style>
