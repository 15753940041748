<template>

    <div class="header">
        
        <header class="courses-navigation-bar">
    
            <ul class="list">
                
                <li class="nav-item logo"> <logo /> </li>
    
                <li class="nav-item title">{{ title }}</li>
    
                <li class="nav-item dropdown"><user-settings-drop-down /></li>
    
            </ul>
    
        </header>

        <div class="explore">
            <h1>{{$t('new_oskar_functionality.page_title')}}</h1>
            <h3>{{$t('new_oskar_functionality.subtitle')}}</h3>
        </div>

    </div>

</template>

<script>
    import Logo from '~/components/logo'
    import ConfirmBeforeExitModal from '~/components/modal/confirm-before-exit'
    import UserSettingsDropDown from '~/components/navigation/user-settings-drop-down'

    export default {
        components: { 
            Logo,
            ConfirmBeforeExitModal,
            UserSettingsDropDown
        },

        props: {
            title: {
                type: String,
                required: true
            },
        }
    }
</script>

<style lang="scss" scoped>
    $white: #ffffff;
    $white-bar: #EFEFEF;
    $progress-bar-background-color: rgba(#6c6c6c, 0.25);
    $progress-bar-color: #BED000;
    $header-height: 68px;

    .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .courses-navigation-bar {
            min-height: $header-height;
            display: flex;
            flex-direction: column;
            position: sticky;
            top: 0;
            z-index: 9;
            width: 100%;
    
            .list {
                display: flex;
                flex-direction: row;
                flex: 1;
                background: $white;
                color: white;
    
                .nav-item {
                    display: flex;
                    align-items: center;
                    padding: 15px 30px;
                    
                    &.logo {
                        border-right: 1px solid $white-bar;
                    }
    
                    &.title {
                        font-size: 14pt;
                        font-weight: 600;
                        color: #393939;
                    }
    
                    &.saving-status {
                        margin-top: 3px;
                        font-size: 11pt;
                        color: #9B9B9B;
                    }
    
                    &.dropdown {
                        margin-left: auto;
                    }
    
                }
            }
        }

        .explore {
            display: flex;
            padding: 52px 0px;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
            align-self: stretch;

            background: linear-gradient(0deg, rgba(98, 170, 71, 0.70) 0%, rgba(98, 170, 71, 0.70) 100%), url('~/static/img/widget/oskar-new-functionalities-layout-background1440x171.jpeg') lightgray 50% / cover no-repeat;

            h1 {
                align-self: stretch;
                color: #FFF;
                text-align: center;

                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
            }

            h3 {
                align-self: stretch;
                color: #FFF;
                text-align: center;

                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%;
            }
        }
    }

</style>
