import {queryBuilder, queryResponse} from '~/utils/query'

export default {

    async deleteProperty({dispatch, getters, commit}) {
        try {

            await dispatch('tyltoApi/delete', '/properties/' + getters['getId'], {root: true})

            commit('resetDefaultState')

        } catch (error) {

            throw error
        }
    },

    async fetchProperty({dispatch}, propertyId) {
        try {

            return await dispatch('tyltoApi/get', 'properties/' + propertyId, {root: true})

        } catch (error) {

            throw error
        }
    },

    async fetchAndFillProperty({dispatch}, propertyId) {
        let property = await dispatch('fetchProperty', propertyId)

        dispatch('fillProperty', property)

        return property
    },

    async fetchFeesRelationship({dispatch, commit, getters}, params) {
        try {

            let propertyId = typeof params.propertyId !== 'undefined' ? params.propertyId : getters['getId']

            const {data} = await this.$axios({method: 'get', url: `properties/${propertyId}/relationships/fees`})

            const fees = await Promise.all(data['data'].map(relationship => dispatch('fees/fetchFee', relationship.id, {root: true})))

            commit('setRelatedFees', fees)

            return fees

        } catch (error) {

            throw error
        }
    },

    async fetchAppliedFeesRelationship({dispatch, commit, getters}, {params}) {
        try {

            let propertyId = typeof params.propertyId !== 'undefined' ? params.propertyId : getters['getId']

            const {data} = await this.$axios({
                method: 'get',
                url: `properties/${propertyId}/relationships/applied_fees`
            })

            const appliedFees = await Promise.all(data['data'].map(relationship => dispatch('fees/fetchFee', relationship.id, {root: true})))

            commit('setRelatedAppliedFees', appliedFees)

            return appliedFees

        } catch (error) {

            throw error
        }
    },

    async fetchProperties({dispatch}, params) {
        try {

            const response = await dispatch('tyltoApi/get', ['properties', {params: queryBuilder(params)}], {root: true})

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchPropertyAccountingEntries({dispatch, getters}, params) {
        try {

            const id = getters['getId'] !== '' ? getters['getId'] : params.propertyId

            const response = await dispatch('tyltoApi/get', [`properties/${id}/accounting-entries`, {params: queryBuilder(params)}], {root: true})

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchAccountingAccountRelationship({dispatch, getters, commit}, propertyId) {
        try {

            let accountingAccount = {}

            if (Object.keys(getters['getRelatedAccountingAccount']).length) accountingAccount = getters['getRelatedAccountingAccount']

            else {

                const id = getters['getId'] !== '' ? getters['getId'] : propertyId

                const {data} = await this.$axios({
                    method: 'get',
                    url: `properties/${id}/relationships/accounting_account`
                })

                if (data['data'] !== null) accountingAccount = await dispatch('accounting-account/fetchAccountingAccount', data['data']['id'], {root: true})
            }

            commit('setRelatedAccountingAccount', accountingAccount)

            return accountingAccount

        } catch (error) {

            throw error
        }
    },

    async fetchDiagnosesRelationship({dispatch, getters, commit}) {
        try {

            let files = []

            if (getters['getRelatedDiagnoses'].length) files = getters['getRelatedDiagnoses']

            else {

                const {data} = await this.$axios({
                    method: 'get',
                    url: `properties/${getters['getId']}/relationships/diagnoses`
                })

                files = await Promise.all(data['data'].map(relationship => dispatch('file/fetchFile', relationship.id, {root: true})))
            }

            commit('setRelatedDiagnoses', files)

            return files

        } catch (error) {

            throw error
        }
    },

    async fetchMandatesRelationship({dispatch, getters, commit}, propertyId) {
        try {

            let propertyMandate = []

            const id = getters['getId'] !== '' ? getters['getId'] : propertyId

            const {data} = await this.$axios({method: 'get', url: `properties/${id}/relationships/mandates`})

            propertyMandate = await Promise.all(data['data'].map(relationship => dispatch('mandate/fetchMandate', relationship.id, {root: true})))

            commit('setRelatedMandates', propertyMandate)

            return propertyMandate

        } catch (error) {

            throw error
        }
    },

    async fetchRentalsRelationship({dispatch, getters, commit}, propertyId) {
        try {

            let propertyRentals = []

            const id = propertyId !== undefined ? propertyId : getters['getId']

            const {data} = await this.$axios({method: 'get', url: `properties/${id}/relationships/rentals`})

            propertyRentals = await Promise.all(data['data'].map(relationship => dispatch('rental/fetchRental', relationship.id, {root: true})))

            commit('setRelatedRentals', propertyRentals)

            return propertyRentals

        } catch (error) {

            throw error
        }
    },

    async fetchTenantsRelationship({dispatch, getters, commit}, propertyId) {

        let propertyTenants = []    

        const id = propertyId !== undefined ? propertyId : getters['getId']

        const {data} = await this.$axios({method: 'get', url: `properties/${id}/relationships/tenants`})

        propertyTenants = await Promise.all(data['data'].map(relationship => dispatch('profile/fetchTenant', relationship.id, {root: true})))
        
        commit('setRelatedTenants', propertyTenants)

        return propertyTenants
    },

    async fetchLandlordRelationship({dispatch, getters, commit}, propertyId) {
        try {

            let id = typeof propertyId !== 'undefined' ? propertyId : getters['getId']

            let propertyLandlord = {}

            const {data} = await this.$axios({method: 'get', url: `properties/${id}/relationships/landlord`})

            if (data['data'] !== null) propertyLandlord = await dispatch('profile/fetchLandlord', data['data']['id'], {root: true})

            commit('setRelatedLandlord', propertyLandlord)

            return propertyLandlord

        } catch (error) {

            throw error
        }
    },

    async fillProperty({commit, dispatch}, property) {
        commit('resetDefaultState')

        if (typeof property['count_main_rooms'] !== 'undefined')
            commit('setCountMainRooms', property['count_main_rooms'])

        if (typeof property['address'] !== 'undefined')
            commit('setAddress', property['address'])

        if (typeof property['reference'] !== 'undefined')
            commit('setReference', property['reference'])

        if (typeof property['area'] !== 'undefined')
            commit('setArea', property['area'])

        if (typeof property['area_tension'] !== 'undefined')
            commit('setAreaTension', property['area_tension'])


        if (typeof property['description'] !== 'undefined')
            commit('setDescription', property['description'])

        if (typeof property['dwelling_type'] !== 'undefined')
            commit('setDwellingType', property['dwelling_type'])

        if (typeof property['case_number'] !== 'undefined')
            commit('setCaseNumber', property['case_number'])

        if (typeof property['fiscal_reference_number'] !== 'undefined')
            commit('setFiscalReferenceNumber', property['fiscal_reference_number'])

        if (typeof property['dpe'] !== 'undefined') {

            if (typeof property['dpe'].consumption !== 'undefined')
                commit('setDpeConsumption', property['dpe'].consumption)

            if (typeof property['dpe'].emission !== 'undefined')
                commit('setDpeEmission', property['dpe'].emission)

            if (typeof property['dpe'].delivery_date !== 'undefined')
                commit('setDpeDeliveryDate', property['dpe'].delivery_date)

            if (typeof property['dpe'].expiration_date !== 'undefined')
                commit('setDpeExpirationDate', property['dpe'].expiration_date)

            commit('setDpeLetter', property['dpe'].letter)
        }


        if (typeof property['_jv'] !== 'undefined') {

            commit('setId', property['_jv'].id)

            if (typeof property['_jv']['meta'] !== 'undefined' && typeof property['_jv']['meta']['user_privileges'] !== 'undefined')
                commit('setUserPrivileges', property['_jv']['meta']['user_privileges'])
        }

    },

    async updateDiagnosesRelationship({getters, commit}) {
        try {

            let diagnoses = {
                data: []
            }

            if (getters['getRelatedDiagnoses'].length) {
                diagnoses['data'] = getters['getRelatedDiagnoses'].map(diagnose => {
                    return {
                        type: 'files',
                        id: diagnose['_jv'].id
                    }
                })
            }

            return await this.$axios({
                method: 'patch',
                url: 'properties/' + getters['getId'] + '/relationships/diagnoses',
                data: diagnoses
            })

        } catch (error) {

            throw error
        }
    },

    async fetchRentals({dispatch, getters, commit}, propertyId) {

        const {data} = await this.$axios({method: 'get', url: `properties/${propertyId}/rentals`});

        return {
            propertyId: propertyId,
            rentals: data
        };
    },

    async fetchMandates({dispatch}, {params, page, filters}) {

        const response = await dispatch('tyltoApi/get',
            ['properties/' + params.propertyId + '/mandates', {params: queryBuilder({page, filters})}], {root: true})

        return queryResponse(response)

    },

}
