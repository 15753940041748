export default {
    methods: {
        /**
         * [getFullName description]
         * @param  {[type]} profile [description]
         * @return {[type]}         [description]
         */
        getProfileFullName(profile) {

            if (typeof profile.legal_persons !== 'undefined' && profile.legal_persons.length) {

                return profile.legal_persons.map(person => this.getLegalPersonFullName(person)).join(', ')
            } else if (typeof profile.legal_person !== 'undefined') {

                return this.getLegalPersonFullName(profile.legal_person)
            } else return ''
        },

        getLegalPersonFullName(legalPerson) {
            if (typeof legalPerson !== 'undefined') {

                if (legalPerson.type === 'juridical_person') {
                    return typeof legalPerson.trade_name !== 'undefined' &&
                    legalPerson.trade_name !== '' &&
                    legalPerson.trade_name !== null ? legalPerson.trade_name : legalPerson.business_name
                } else if (legalPerson.type === 'natural_person' && legalPerson.full_name) return legalPerson.full_name

                else return legalPerson.first_name + ' ' + legalPerson.last_name
            } else return ''
        },

        getLegalPersonsFullName(legalPersons) {

            if (typeof legalPersons !== 'undefined' && legalPersons.length) {

                return legalPersons.map(person => this.getLegalPersonFullName(person)).join(', ')
            } else return ''

        }
    }
}


