<template>

    <div :class="['tenant-list-table-cell', { 'empty': !tenants.length }]" v-if="!$fetchState.error">

        <svg-icon class="icon"  iconColor="#BED000" width="24" height="24" :viewbox="'0 0 24 24'" ><tenant-icon /></svg-icon>

        <spinner-loader v-if="loading && $fetchState.pending" />

        <span class="tenant" v-else-if="!tenants.length">-</span>

                <div class="tenant" @click.stop="showTenantSidebar" v-else-if="tenants.length === 1">

                    {{ getProfileFullName(tenants[0]) }}

                    <tenant-sidebar ref="TenantSidebar" :tenantId="tenants[0]['_jv'].id" v-if="tenants.length === 1"/>

                </div>

                <div class="tenant" @click.stop="$emit('expandTenants', expandedLines)" v-else>

                    {{ $t('nb_tenants', { nb: tenants.length }) }}

                    <arrow class="arrow" :active="!collapsed" :width="16" :height="16" color="#bed000" />

                </div>

    </div>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import TenantIcon from '~/components/icons/table-cell-icons/tenant-icon'
    import FullNameMixin from '~/components/mixins/full-name'
    import SpinnerLoader from '~/components/spinner-loader'
    import Arrow from '~/components/arrow'
    import TenantSidebar from '~/components/sidebar/tenant-sidebar'
    export default {

        mixins: [FullNameMixin],

        components: {
            SpinnerLoader,
            Arrow,
            TenantSidebar,
            SvgIcon,
            TenantIcon
        },

        props: {
            tenantIds: {
                type: Array,
                required: true
            },

            propTenants: {
                type: Array,
            },

            collapsed: {
                type: Boolean,
                default: true
            },

            openSidebar: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                loading: false,
                tenants: []
            }
        },

        fetchDelay: 1000,

        async fetch() {
            try {

                if (this.propTenants !== undefined) {
                    this.tenants = this.propTenants;
                    return;
                }

                const { store } = this.$nuxt.context

                this.loading = true

                this.tenants = await Promise.all(this.tenantIds.map(tenantId => {

                    return store.dispatch('profile/fetchTenant', tenantId)
                }))

            } catch(error) {

                throw error

            } finally {

                this.loading = false
            }
        },

        methods: {
            showTenantSidebar() {

                this.$nextTick(() => {

                    if (typeof this.$refs.TenantSidebar !== 'undefined' && typeof this.$refs.TenantSidebar.show !== 'undefined') {

                        if (this.openSidebar) this.$refs.TenantSidebar.show()
                    }
                })
            }
        },

        computed: {
            expandedLines() {
                return this.tenants.map(tenant => {
                    return [
                        {
                            type: 'tenant-list',
                            data: [tenant['_jv'].id],
                            colspan: 2
                        },
                        {
                            type: 'string',
                            data: typeof tenant.legal_persons?.[0]?.address !== 'undefined' ? tenant.legal_persons[0].address.formatted : '',
                            colspan: 2
                        },
                        {
                            type: 'string',
                            data: tenant.phone_number
                        }
                    ]
                })
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "nb_tenants": "{nb} locataires"
        }
    }
</i18n>

<style lang="scss">
    $tenant: #BED000;
    .tenant-list-table-cell {
        display: flex;
        &.empty {
            opacity: .5;
        }
        .tenant {
            display: flex;
            align-items: center;
            color: $tenant;
            text-decoration-color: $tenant;
            .arrow {
                border-bottom-color: $tenant;
                margin-left: 9px;
            }
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .icon {
            margin-right: 7px;
            min-width: 24px;
        }
    }
</style>
