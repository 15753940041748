import {queryBuilder, queryResponse} from '~/utils/query'

export default {
    async createCost({ dispatch, getters, rootGetters }) {
        try {

            if (getters['getId'] !== '') return await dispatch('updateCost')

            const cost = await dispatch('getCostRequestData')

            const response = await dispatch('tyltoApi/post', [cost, { url: 'properties/' + rootGetters['property/getId'] + '/costs' }], { root: true })

            dispatch('fillCost', response)

            return response

        } catch (error) {

            throw error
        }
    },

    async deleteCost({ dispatch, getters }, id) {
        try {

            let costId = typeof id !== 'undefined' ? id : getters['getId']

            return await dispatch('tyltoApi/delete', 'costs/' + costId, { root: true })

        } catch (error) {

            throw error
        }
    },

    async fetchInvoiceRelationship({ getters, dispatch, commit }, costId) {
        try {

            let costInvoice = {}

            if (Object.keys(getters['getRelatedInvoice']).length) costInvoice = getters['getRelatedInvoice']

            else {

                const id = getters['getId'] !== '' ? getters['getId'] : costId

                const { data } = await this.$axios({ method: 'get', url: `costs/${id}/relationships/invoice` })

                if (data['data'] !== null) costInvoice = await dispatch('file/fetchFile', data['data']['id'], { root: true })
            }

            commit('setRelatedInvoice', costInvoice)

            return costInvoice

        } catch (error) {

            throw error
        }
    },

    async fetchPropertyCosts({ dispatch}, { params, filters, sort, aggregates, page, scope, groups_by }) {
        try {

            const response = await dispatch('tyltoApi/get', [`properties/${params.propertyId}/costs`, { params: queryBuilder({ filters, sort, aggregates, page, scope, groups_by }) }], { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchCost({ dispatch, rootGetters }, costId) {
        try {

            let cost = rootGetters['tyltoApi/get'](`costs/${costId}`)

            if (typeof cost === 'undefined' || !Object.keys(cost).length) {

                cost = await dispatch('tyltoApi/get', 'costs/' + costId, { root: true })
            }

            return cost

        } catch (error) {

            throw error
        }
    },

    async fetchAndFillCost({ dispatch }, costId) {
        try {

            let cost = await dispatch('fetchCost', costId)

            await dispatch('fillCost', cost)

            return cost

        } catch (error) {

            throw error
        }
    },

    async fetchLandlordCosts({ dispatch, rootGetters }, params) {
        try {

            const response = await dispatch('tyltoApi/get', ['landlords/' + rootGetters['profile/getId'] + '/costs', { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchSupplierCosts({ dispatch }, { params, filters, sort, aggregates }) {
        try {

            const response = await dispatch('tyltoApi/get', [`suppliers/${params.supplierId}/costs`, { params: queryBuilder({ filters, sort, aggregates }) }], { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },


    async fetchSupplierRelationship({ getters, dispatch, commit }, costId) {
        try {

            let costSupplier = {}

            const { data } = await this.$axios({ method: 'get', url: `costs/${costId}/relationships/supplier` })

            if (data['data'] !== null)  {

                if (data['data'].type === 'suppliers') costSupplier = await dispatch('profile/fetchSupplier', data['data'].id, { root: true })

                else costSupplier = await dispatch('property-manager/fetchPropertyManagementCompany', data['data'].id, { root: true})

            }

            commit('setRelatedSupplier', costSupplier)

            return costSupplier

        } catch (error) {

            throw error
        }
    },

    async fetchPropertyManagementCompanyCosts({ dispatch}, { params, filters, sort, aggregates }) {
        try {

            const response = await dispatch('tyltoApi/get', [`property-management-companies/${params.propertyManagerId}` + '/costs', { params: queryBuilder({ filters, sort, aggregates }) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    fillCost({ commit }, cost) {
        commit('resetDefaultState')

        if (typeof cost.amount !== 'undefined')
            commit('setAmount', cost.amount)

        if (typeof cost.date !== 'undefined')
            commit('setDate', cost.date)

        if (typeof cost.description !== 'undefined')
            commit('setDescription', cost.description)

        if (typeof cost.invoice !== 'undefined' && Object.keys(cost.invoice).length)
            commit('setRelatedInvoice', cost.invoice)

        if (typeof cost.invoice_number !== 'undefined')
            commit('setInvoiceNumber', cost.invoice_number)

        if (typeof cost.items !== 'undefined')
            commit('setItems', cost.items)

        if (typeof cost.payout_date !== 'undefined')
            commit('setPayoutDate', cost.payout_date)

        if (typeof cost.payout_reserved_at !== 'undefined')
            commit('setPayoutReservedAt', cost.payout_reserved_at)

        if (typeof cost.vat_amount !== 'undefined')
            commit('setVatAmount', cost.vat_amount)

        if (typeof cost['_jv'] !== 'undefined')
            commit('setId', cost['_jv'].id)
    },

    async getCostRequestData({ getters }) {
        let cost = {
            date: getters['getDate'],
            invoice_number: getters['getInvoiceNumber'],
            _jv: {
                type: 'costs',
                relationships: {}
            }
        }

        if (getters['getDescription'] !== '') {
            cost['description'] = getters['getDescription']
        }

        if (getters['getItems'].length) {
            cost['items'] = getters['getItems'];

            cost['items'].forEach((item) => {
                if (item['type'] === 'everyday_management') {
                    delete item['vat_rate'];
                }
            });
        }

        if (getters['getPayoutDate'] !== '') {
            cost['payout_date'] = getters['getPayoutDate']
        }

        if (getters['getPayoutReservedAt'] !== '') {
            cost['payout_reserved_at'] = getters['getPayoutReservedAt']
        }

        if (getters['getId'] !== '') {
            cost['_jv']['id'] = getters['getId']
        }

        if (Object.keys(getters['getRelatedInvoice']).length) {
            cost['_jv']['relationships']['invoice'] = {
                data: {
                    type: 'files',
                    id: getters['getRelatedInvoice']['_jv'].id
                }
            }
        }

        if (Object.keys(getters['getRelatedSupplier']).length) {
            cost['_jv']['relationships']['supplier'] = {
                data: {
                    type: 'suppliers',
                    id: getters['getRelatedSupplier']['_jv'].id
                }
            }
        }

        return cost
    },

    async markAsPaidCost({ dispatch, commit }) {
        try {

            let today = this.$moment().format('YYYY-MM-DD')

            commit('setPayoutDate', today)

            return await dispatch('updateCost')

        } catch (error) {

            throw error
        }
    },

    async updateInvoiceRelationship({ getters, commit }) {
        try {

            let invoice = {
                data: null
            }

            if (Object.keys(getters['getRelatedInvoice']).length) {
                invoice['data'] = {
                    type: 'files',
                    id: getters['getRelatedInvoice']['_jv'].id
                }
            }

            return await this.$axios({method : 'patch',
                                         url : 'costs/' + getters['getId'] + '/relationships/invoice',
                                         data: invoice
                                     })

        } catch (error) {

            throw error
        }
    },

    async updateCost({ dispatch, getters, rootGetters }) {
        try {

            let request = await dispatch('getCostRequestData')

            const cachedCost = rootGetters['tyltoApi/get'](`costs/${getters['getId']}`)

            if (cachedCost['_jv'] !== undefined && cachedCost['_jv']['relationships']['invoice']['data'] !== null) await dispatch('updateInvoiceRelationship')

            const cost = await dispatch('tyltoApi/patch', request, { root: true })

            await dispatch('fillCost', cost)

            return cost

        } catch (error) {

            throw error
        }
    },

    async updateSupplierRelationship({ getters, commit }) {
        try {

            let supplier = {
                data: null
            }

            if (Object.keys(getters['getRelatedSupplier']).length) {
                supplier['data'] = {
                    type: 'suppliers',
                    id: getters['getRelatedSupplier']['_jv'].id
                }
            }

            const response = await this.$axios({ method: 'patch', url: 'costs/' + getters['getId'] + '/relationships/supplier', data: supplier })

            commit('tyltoApi/deleteRecord', { _jv: { type: 'costs', id: getters['getId'] } }, { root: true })

            return response

        } catch (error) {

            throw error
        }
    },
    async updatePayoutRelationship({ getters, commit }, payoutId ) {
        try {

            let payout = {
                data: null
            }

            if (payoutId !== null) {
                payout['data'] = {
                    type: 'payouts',
                    id: payoutId
                }
            }

            const response = await this.$axios({ method: 'patch', url: 'costs/' + getters['getId'] + '/relationships/payout', data: payout })

            commit('tyltoApi/deleteRecord', { _jv: { type: 'costs', id: getters['getId'] } }, { root: true })

            return response

        } catch (error) {

            throw error
        }
    },
}
