<template>

    <jumbotron class="property-header background-property-header"

        :iconPath="'property'"

        :title="Object.keys(address).length ? address.formatted : ''"

        :subTitle="getPropertyNatureShorthand(propertyResource)"

        :breadcrumb="breadcrumb"

        :breadcrumbBack="`/dashboard/properties`"

        :backgroundIllustration="propertyHeaderIllustration"

        :headerNav="isManager ? propertyMainNav : propertyMainNavLandlord"

        :tags="isManager && Object.keys(landlord).length ? [owners] : []" />

</template>

<script>
    import Jumbotron from '~/components/navigation/header/jumbotron'
    import FullNameMixin from '~/components/mixins/full-name'
    import PropertyNatureShorthandMixin from '~/components/mixins/property-nature-shorthand'
    import PropertyHeaderIllustration from '~/static/img/dashboard/property-header-illustration1920x234.jpeg'
    import { mapGetters } from 'vuex'

    export default {
        mixins: [FullNameMixin, PropertyNatureShorthandMixin],

        components: {
            Jumbotron
        },

        data() {
            return {
                propertyResource: {},
                propertyHeaderIllustration: PropertyHeaderIllustration
            }
        },

        async fetch() {

            const { store } = this.$nuxt.context

            this.propertyResource = await store.dispatch('property/fetchProperty', this.$route.params.propertyId)

            if (!Object.keys(this.landlord).length) {

                await store.dispatch('property/fetchLandlordRelationship', this.$route.params.propertyId)
            }
        },

        computed: {
            ...mapGetters({
                isManager: 'isManager',
                address: 'property/getAddress',
                landlord: 'property/getRelatedLandlord',
                propertyId: 'property/getId'
            }),

            breadcrumb() {

                if (!this.isManager) return []

                return [
                    {
                        text: this.$t('dashboard'),
                        redirectPath: '/dashboard'
                    },
                    {
                        text: this.$t('lots'),
                        redirectPath: '/dashboard/properties'
                    },
                    {
                        text: this.$t('address_label', { address: Object.values(this.address).length ? this.address.formatted : '' })
                    }
                ]
            },

            propertyMainNav() {
                return [
                    {
                        path: '/property/' + this.propertyId,
                        name: this.$t('dashboard')
                    },
                    {
                        path: '/property/' + this.propertyId + '/rents',
                        name: this.$t('rents')
                    },
                    {
                        path: '/property/' + this.propertyId + '/costs',
                        name: this.$t('costs')
                    },
                    {
                        path: '/property/' + this.propertyId + '/contracts',
                        name: this.$t('contracts')
                    },
                    {
                        path: '/property/' + this.propertyId + '/documents',
                        name: this.$t('documents')
                    }
                ]
            },

            propertyMainNavLandlord() {
                return [
                    {
                        path: '/property/' + this.propertyId,
                        name: this.$t('dashboard')
                    }
                ]
            },

            owners() {
                return {
                    redirect_path: '/landlord/' + this.landlord['_jv'].id,
                    text: this.getProfileFullName(this.landlord),
                    icon: 'owner'
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "dashboard": "Dashboard",
            "lots": "Lots",
            "address_label": "{address}",
            "dashboard": "Accueil",
            "documents": "Documents",
            "costs": "Dépenses",
            "rents": "Appels",
            "incident": "Incident",
            "payouts": "Versement",
            "actions": "Actions",
            "contracts": "Contrats",
            "edit_property": "Modifier le lot",
            "delete_property": "Supprimer le lot",
            "rental_history": "Historique des baux",
            "breach_of_rental": "Rompre le contrat de location",
            "delete_mandate": "Supprimer le mandat",
            "accounting-account": "Compte"
        }
    }
</i18n>
