export default async function ({ store }) {
    // Fetch user if authenticated session
    if (store.getters.isAuthenticated) {
        
        await store.dispatch('user/fetchAndFillUser')

        // use axios to fetch onboarding progress

        await store.dispatch('user/CreateOnboardingProgress')

        if (store.getters.isManager) {
            
            const propertyManagementCompany = await store.dispatch('user/fetchPropertyManagerCompanyRelationship')

            if (Object.keys(propertyManagementCompany).length) await store.dispatch('property-manager/fillPropertyManagementCompany', propertyManagementCompany)

            await store.dispatch('property-manager/fetchLogoRelationship', propertyManagementCompany._jv.id)
        }
    }
}