<template>

    <div class="user-settings-drop-down" @click="toggleDropDown">

        <!-- <progress-circle-chart class="progress-chart" :value="userOnboardingProgress" :width="30" :progressionNumberFontSize="0" circleBgColor="#EFEFEF" circleProgressBgColor="#BED000" v-if="!isProfilCompleted"/> -->

        <div class="company-logo" >

            <img class="logo-url" :src="logo.url" v-if="Object.values(logo).length" />

            <svg-icon class="logo-url" width="20" height="20"  :iconColor="'#BED000'" v-else><property-management-default-icon /></svg-icon>

        </div>

        <div :class="['user-infos', { 'collapsed': collapsed }]">

            <p class="auth-user">{{ userFullName ? userFullName : userEmailAddress }}</p>

            <p class='progression-info' v-if="userOnboardingProgress !== 100" >{{ 'Profil complété à ' + userOnboardingProgress + '%' }}</p>

        </div>

        <arrow class="arrow" :active="!collapsed" color="#393939" :width="24" :height="24" />

        <drop-down class="drop-down" :options="userOptions" @selectOption="redirect($event)" :open="!collapsed" @click-outside="toggleDropDown" />

    </div>

</template>

<script>
    import { mapGetters } from 'vuex'
    import UserSettingsDropDown from '~/components/navigation/user-settings-drop-down'
    import DropDown from '~/components/drop-down'
    import ProgressCircleChart from '~/components/widget/progress-circle-chart'
    import Modal from '~/components/modal'
    import { CreditTransferIcon } from '~/components/icons/button-icons'
    import SvgIcon from '~/components/svg-icon'
    import Arrow from '~/components/arrow'
    import PropertyManagementDefaultIcon from '~/components/icons/dashboard/property-management-default-icon'



    export default {

        components: {
            UserSettingsDropDown,
            DropDown,
            ProgressCircleChart,
            Modal,
            CreditTransferIcon,
            SvgIcon,
            Arrow,
            PropertyManagementDefaultIcon
        },

        data() {
            return {
                collapsed: true,
                isProfilCompleted: false,
            }
        },

        methods: {
            toggleDropDown() {

                this.collapsed = !this.collapsed
            },

            copy() {
                navigator.clipboard.writeText('OskarLbi')

                this.$toast.success(this.$t('copied'), { icon: 'check' })
            },

            redirect(path) {
                if (path.includes('https')) {

                    window.open(path, '_blank')

                } else {

                    this.$router.push(path)
                }
            },

            show() {
                this.$refs.modal.show()
            },

            hide() {
                this.$refs.modal.hide()
            },
        },

        computed: {
            ...mapGetters({
                userFullName: 'user/getFullName',
                userOnboardingProgress: 'user/getOnboardingProgress',
                userEmailAddress: 'user/getEmailAddress',
                swanOnboarding: 'property-manager/getSwanOnboarding',
                getWebBanking: 'property-manager/getWebBanking',
                logo: 'property-manager/getRelatedLogo',
                features: 'property-manager/getFeatures'
            }),

            userOptions() {
                return [
                    {
                        value: '/settings',
                        text: this.$t('personal_settings'),
                        icon: 'account',
                        iconColor: '#767676'
                    },
                    {
                        value: '/settings/company-details',
                        text: this.$t('company_details'),
                        icon: 'settings',
                        iconColor: '#767676'
                    },
                    {
                        value: this.getWebBanking,
                        text: this.$t('web_banking'),
                        icon: 'bank',
                        iconColor: '#767676',
                        hidden: this.swanOnboarding?.onboarding_state !== 'Completed'
                    },
                    {
                        value: 'https://support.oskar.immo/',
                        text: this.$t('help'),
                        icon: 'help',
                        iconColor: '#767676'
                    },
                    {
                        value: '/oskar-courses',
                        text: this.$t('oskar_courses'),
                        icon: 'play',
                        iconColor: '#767676'
                    },
                    {
                        value: '/nouveautes',
                        text: this.$t('new_oskar_functionality.title'),
                        icon: 'news',
                        iconColor: '#767676',
                        badge: this.features.feature_communication,
                        hidden: ! this.features.whats_new_pages
                    },
                    {
                        value: "/viewer/CGU/cgu",
                        text: this.$t('CGU'),
                        icon: 'cgu',
                        iconColor: '#767676'
                    },
                    {
                        value: '/auth/logout',
                        text: this.$t('signout'),
                        icon: 'logout',
                        iconColor: '#767676'
                    }
                ]
                    .filter(({hidden}) => !hidden)
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "signout": "Se déconnecter",
            "personal_settings": "Profil et préférences",
            "company_details": "Paramètres de l'agence",
            "switch_account": "Changer de compte",
            "web_banking": "Compte de paiement en ligne",
            "help": "Aide & support",
            "CGU": "Conditions générales d'utilisation",
            "title": "Conditions générales d'utilisation",
            "text": "Pour accéder aux conditions générales d'utilisation, un mot de passe vous sera demandé pour des raisons de sécurité.",
            "password": "OskarLbi",
            "password_title": "Mot de passe : ",
            "redirectCgu": "Accéder aux CGU",
            "copied": "Mot de passe copié !",
            "oskar_courses": "Formations Oskar"
        }
    }
</i18n>

<style lang="scss">
    $header-height: 68px;
    $drop-down-item-color: #767676;

    .user-settings-drop-down {
        max-width: 270px;
        position: relative;
        display: flex;
        align-items: center;

        .company-logo {
            width: 23px;
            height: 23px;
            background-color: white;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            box-sizing: border-box;
            cursor: pointer;

            .logo-url {
                width: 100%;
                max-height: 100%;
                border-radius: 100%;
                color: #525254;
            }
        }

        .progress-chart {
            position: initial;
            margin-right: 12px;
        }

        .user-infos {
            position: relative;
            padding-right: 8px;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
        }

        .auth-user {
            color: #393939;
            overflow: hidden;
            font-weight: 400;
            text-overflow: ellipsis;
        }

        .progression-info {
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            color: rgba(57, 57, 57, 0.6);
        }

        .drop-down {
            border: 1px solid #D4D9E7;
            border-radius: 4px;
            box-shadow: 0 5px 20px 0 rgba(4,16,41,0.02);
            background: white;
            color:  #0E0A07;
            font-size: 11pt;
            max-height: 400px;
            min-width: 350px;
            overflow-y: auto;
            transform-origin: top;
            font-weight: 400;
            top: calc(#{$header-height} - 25px);
            right: 0;
            position: absolute;

                .drop-down-option {
                    cursor: pointer;
                    margin-bottom: 10px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #F2F2F2;
                    display: flex;
                    align-items: center;
                    font-weight: 400 !important;
                }
            }


        .drop-down::-webkit-scrollbar {
            display: none;
        }
    }
</style>
