import defaultState from './default'

export default {
    resetDefaultState(state) {
        Object.assign(state, defaultState())
    },

    setAddress(state, value) {
        state.address = value
    },

    setAmountOfSecurity(state, value) {
        state.amount_of_security = value
    },

    setBusinessCardNumber(state, value) {
        state.business_card_number = value
    },

    setBusinessCardPlaceOfIssue(state, value) {
        state.business_card_place_of_issue = value
    },

    setBusinessName(state, value) {
        state.business_name = value
    },

    setCreatedAt(state, value) {
        state.created_at = value
    },

    setIncorporationDate(state, value) {
        state.incorporation_date = value
    },

    setManagementAndOwnership(state, value) {
        state.management_and_ownership = value
    },

    setEmailAddress(state, value) {
        state.email_address = value
    },

    setPhoneNumber(state, value) {
        state.phone_number = value
    },

    setIdentificationNumber(state, value) {
        state.identification_number = value
    },

    setId(state, value) {
        state.id = value
    },

    setTradeName(state, value) {
        state.trade_name = value
    },

    setShareCapital(state,  value) {
        state.share_capital = value
    },

    setIsEmailNotificationEnabled(state, value) {
        state.is_email_notification_enabled = value
    },

    setSwanOnboarding(state, value) {
        if (typeof value.onboarding_state !== 'undefined') {
            state.swan_onboarding.onboarding_state = value.onboarding_state
        }
        if (typeof value.onboarding_url !== 'undefined') {
            state.swan_onboarding.onboarding_url = value.onboarding_url
        }
    },

    setSwanAccountHolder(state, value) {
        if (typeof value.verification_status !== 'undefined') {
            state.swan_account_holder.verification_status = value.verification_status
        }
    },

    setWebBanking(state, value) {
        state.web_banking = value
    },

    setDefaultFeeVatRate(state, value) {
        state.default_fee_vat_rate = value
    },

    setAccountingCode(state, value) {
        state.accounting_code = value
    },

    setRelatedBankAccounts(state, value) {
        state.relationships.bank_accounts = value
    },

    setRelatedFees(state, value) {
        state.relationships.fees = value
    },

    setRelatedMandates(state, value) {
        state.relationships.mandates = value
    },

    setRelatedGuaranteeFund(state, value) {
        state.relationships.guarantee_fund = value
    },

    setRelatedLogo(state, value) {
        state.relationships.logo = value
    },

    setRelatedMediator(state, value) {
        state.relationships.mediator = value
    },

    setRelatedPaymentMethod(state, value) {
        state.relationships.payment_method = value
    },

    setFeatures(state, value) {
        state.features = value
    }
}
