export default {
    getAddress(state) {
        return state.address
    },

    getArea(state) {
        return state.area
    },

    getDescription(state) {
        return state.description
    },

    getDwellingType(state) {
        return state.dwelling_type
    },

    getIsInTenseArea(state) {
        return state.area_tension === 1
    },

    getIsInVeryTenseArea(state) {
        return state.area_tension === 2
    },

    getFiscalReferenceNumber(state) {
        return state.fiscal_reference_number
    },

    getId(state) {
        return state.id
    },

    getUserPrivileges(state) {
        return state.user_privileges
    },

    getRelatedAppliedFees(state) {
        return state.relationships.applied_fees
    },

    getRelatedAccountingAccount(state) {
        return state.relationships.accounting_account
    },

    getRelatedDiagnoses(state) {
        return state.relationships.diagnoses
    },

    getRelatedFees(state) {
        return state.relationships.fees
    },

    getRelatedLandlord(state) {
        return state.relationships.landlord
    },

    getRelatedRentals(state) {
        return state.relationships.rentals
    },

    getRelatedMandates(state) {
        return state.relationships.mandates
    },

    getRelatedTenants(state) {
        return state.relationships.tenants
    },

    isDwellingTypeEqualFlatOrHouse(state, getters) {
        return getters['getDwellingType'].includes('house') || getters['getDwellingType'].includes('flat')
    }
}
