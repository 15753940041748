<template>

    <modal class="edit-landlord-fees-modal" :title="$t('edit_fees')" :options="modalOptions" @canceled="hide" @close="hide" ref="modal">

        <spinner-loader v-if="loading" />

        <template v-else>

            <step-group :name="$t('default_fees')" v-if="isDefaultFees">

                <form-info class="form-info" :text="$t('edit_default_fees')" />

                <table-list class="fullsize" :tHead="parentFeesHeaders" :tBodies="parentFeesRows" v-if="landlordAppliedFees.length"/>

                <p v-else-if="!landlordAppliedFees.length"> {{ $t('no_fees') }} </p>

            </step-group>

            <step-group :name="$t('landlord_fees')" :formInfo="$t('set_landlord_fees')" v-else>

                <back-to-button :label="$t('back')" :onClick="() => isDefaultFees = true" />

                <step-field class="fullsize" name="landlord_fees" :label="$t('landlord_fees')" :options="feeListOptions" getter="profile/getRelatedFees" setter="profile/setRelatedFees" type="fees-list" />

            </step-group>

        </template>

    </modal>

</template>

<script>
import Modal from '~/components/modal'
import SpinnerLoader from '~/components/spinner-loader'
import BackToButton from '~/components/widget/back-to-button'
import { mapGetters } from 'vuex'

export default {
    components: {
        Modal,
        SpinnerLoader,
        BackToButton
    },

    data() {
        return {
            loading: false,
            isDefaultFees: true
        }
    },

    computed: {
        ...mapGetters({
            fees: 'profile/getRelatedFees',
            landlordAppliedFees: 'profile/getRelatedAppliedFees',
            landlordId: 'profile/getId'
        }),

        feeListOptions() {
            return {
                onSubmit: this.createLandlordFee,
                afterSubmit: this.refreshLandlordFees,
                excludeOptions: this.fees.map(fee => fee.type)
            }
        },

        modalOptions() {
            if (this.isDefaultFees) return {
                largeOption: {
                    mode: 'expanded',
                    subtitle: this.$t('fees_details'),
                    icon: 'fees'
                },
                submit: {
                    buttonText: this.$t('add_fees'),
                    action: () => this.switchFees(),
                    iconImg: 'add_new',
                    class: 'background-transparent green-text-color'
                }
            }

            else return {
                largeOption: {
                    mode: 'expanded',
                    subtitle: this.$t('fees_details'),
                    icon: 'fees'
                },
                submit: {
                    buttonText: this.$t('close'),
                    action: this.editFees
                }
            }
        },

        parentFeesHeaders() {
            return [
                {
                    label: this.$t('type'),
                    field: "type",
                    type: "text",
                    highlight: true,
                    width: 1.8
                },
                {
                    label: this.$t('amount'),
                    field: "amount",
                    type: "string",
                    highlight: true,
                    width: 0.8
                },
                {
                    label: this.$t('applies_to'),
                    field: "applies_to",
                    type: "text",
                },
                {
                    label: this.$t('level'),
                    field: "level",
                    type: "tag"
                }
            ]
        },

        parentFeesRows() {
            return this.landlordAppliedFees.filter(fee => fee.amount_in_currency !== 0).map(fees => {

                let amount = ''

                if (typeof fees.amount_in_percent !== 'undefined') amount = `${fees.amount_in_percent} %`

                else if (typeof fees.amount_in_currency !== 'undefined') amount = this.$n(fees.amount_in_currency / 100, 'currency')

                let applies_to = ''

                if (typeof fees.applies_to !== 'undefined' && fees.applies_to.length) applies_to = fees.applies_to.map(apply => this.$t(`rental_item_types.${apply}`)).join(', ')

                else if (typeof fees.amount_in_percent !== 'undefined') applies_to = this.$t('rental_item_types.all')

                let row = {
                    type: this.$t(`fees.${fees.type}`),
                    amount: amount,
                    applies_to: applies_to,
                    level: {
                        type: 'default',
                        label: this.$t(`level_options.${fees['_jv']['relationships']['level']['data']['type']}`)
                    }
                }

                if (fees['_jv']['relationships']['level']['data']['type'] === 'property-management-companies') row.actions = {
                    delete: () => this.resetLandlordFee(fees)
                }

                return row
            })
        }
    },

    methods: {
        switchFees() {
            this.isDefaultFees = !this.isDefaultFees
        },

        async createLandlordFee() {
            try {

                await this.$store.dispatch('fees/createLandlordFee', this.landlordId)

                return await Promise.all([
                    this.$store.dispatch('profile/fetchAppliedFeesRelationship', {
                        params: {
                            landlordId: this.landlordId
                        }
                    }),
                    this.$store.dispatch('profile/fetchFeesRelationship', {
                        params: {
                            landlordId: this.landlordId
                        }
                    })
                ])

            } catch(error) {

                throw error
            }
        },

        async resetLandlordFee(fees) {
            try {

                this.loading = true

                let copyFee = {...fees}

                copyFee.amount_in_currency = 0

                copyFee.amount_in_percent = 0

                await this.$store.dispatch('fees/fillFee', copyFee)

                await this.createLandlordFee()

            } catch(error) {

                throw error

            } finally {

                this.loading = false
            }
        },

        async refreshLandlordFees() {
            try {

                return await Promise.all([
                    this.$store.dispatch('profile/fetchFeesRelationship', {
                        params: {
                            landlordId: this.landlordId
                        }
                    }),
                    this.$store.dispatch('profile/fetchAppliedFeesRelationship', {
                        params: {
                            landlordId: this.landlordId
                        }
                    })
                ])

            } catch(error) {

                throw error
            }
        },

        async show() {
            try {

                this.loading = true

                this.isDefaultFees = true

                this.$refs.modal.show()

                await Promise.all([
                    this.$store.dispatch('profile/fetchAppliedFeesRelationship', {
                        params: {
                            landlordId: this.landlordId
                        }
                    }),
                    this.$store.dispatch('profile/fetchFeesRelationship', {
                        params: {
                            landlordId: this.landlordId
                        }
                    })
                ])

            } catch(error) {

                this.$refs.modal.setErrors(error)

            } finally {

                this.loading = false
            }
        },

        hide() {
            this.$refs.modal.hide()
        },

        async editFees() {
            try {

                await Promise.all(this.fees.filter(fee => typeof fee['_jv'] === 'undefined' || typeof fee['_jv'].id === 'undefined').map(async fee => {

                    await this.$store.dispatch('fees/fillFee', fee, { root: true })

                    return await this.$store.dispatch('fees/createLandlordFee', { root: true })
                }))

                await this.$store.dispatch('profile/fetchFeesRelationship', {
                    params: {
                        landlordId: this.landlordId
                    }
                })

                this.hide()

                this.$toast.success(this.$t('fees_edited'), { icon: 'check' })

            } catch(errors) {

                throw errors

            } finally {

                this.$store.commit('fees/resetDefaultState')
            }
        }
    }
}
</script>

<i18n>
{
    "fr": {
        "edit_fees": "Modification des honoraires de gestion",
        "fees_details": "Ajoutez ou supprimez vos honoraires de gestion",
        "fees_edited": "Honoraires modifiés",
        "add_fees": "Ajouter des honoraires",
        "fees": "Honoraires",
        "landlord_fees": "Nouveaux honoraires du propriétaire",
        "no_fees": "Aucun honoraire prédéfini.",
        "default_fees": "Honoraires qui s'appliquent sur ce propriétaire",
        "step_resume": "<b>{nb}</b> honoraires",
        "set_landlord_fees": "Honoraires uniquement appliqués à ce propriétaire. Cliquez sur la ligne pour modifier un honoraire.",
        "edit_default_fees": "Honoraires définis dans les paramètres de l'agence.",
        "applies_to": "S'applique sur",
        "type": "Type",
        "amount": "Montant",
        "level": "Niveau",
        "level_options": {
            "property-management-companies": "Agence",
            "properties": "Lot",
            "rentals": "Contrat de location",
            "landlords": "Propriétaire"
        },
        "back": "Retour à la liste",
        "close": "Terminer"
    }
}
</i18n>
