<template>

    <div id="dynamical-agent" class="relative" @click="createProperty(onboardingSteps)">

        <div style="z-index:3000;cursor:pointer;" class="agentContainer relative " :class="triangle">

            <div class="flexrow my-3" v-if="title.length > 1">

                <Agent v-if="imgAgentSteps.includes(onboardingSteps)" :center="false" width="50%" />

                <light v-if="imgLightSteps.includes(onboardingSteps)" width="50%" />

                <div class="title"> {{ title }}</div>

            </div>

            <div v-else class="mx-auto">

                <Agent v-if="imgAgentSteps.includes(onboardingSteps)" width="170" />

                <light v-if="imgLightSteps.includes(onboardingSteps)" />

            </div>

            <div class="content"> {{ content }}</div>

        </div>

        <ButtonInput v-if="!specialSteps.includes(onboardingSteps)" @click="setStep(0)" class="btn-agent background-transparent white-text-color" :text="$t('button_exit')"></ButtonInput>

    </div>

</template>

<script>

    import Agent from '~/components/icons/onboarding-icons/agent.vue'
    import Light from '~/components/icons/onboarding-icons/light.vue'
    import { mapGetters } from 'vuex'

    export default {

        components: {
            Agent,
            Light
        },

        data() {
            return {
                specialSteps: [3, 7], //set step when we need just borders
                imgAgentSteps: [2, 6, 9, 5], //set steps when we need Detective image else nothing
                imgLightSteps: [4, 8], //set step when we nee Light image else nothing
                title: '',
                triangle: '',
                content: '',
                img: '',
                special: false,
                agentData: {
                    id: 0,
                    settings: {
                        active: true,
                        modal: false,
                        card: true
                    },
                    cardExtStyle: {// basical configuration of external card
                        zIndex: 21,
                        width: 272,
                        height: 400,
                        center: true,
                        cardPositionType: 'fixed',
                        cardPositionX: 1200,
                        cardPositionY: 1200,
                        opacity: 0,
                        isTopLeft: true,
                        borderRadius: 8,
                        cardBgColor: '#BED000'
                    }
                }
            }
        },

        watch: {
            async agentPosition(newCount, oldCount) {
                try {
                    const style = this.agentData.cardExtStyle

                    style.opacity = 0

                    //AI parameters conditions define where the card will be automatically positionned

                    if (newCount.x > window.screen.width / 2 && !this.special ) {
                        style.width = 272
                        style.height = 320
                        style.cardPositionX = Math.round(newCount.x - style.width - 40)
                        style.cardPositionY = Math.round(newCount.y - style.height + 40)
                        this.triangle = 'triangleRight'
                        this.agentData.cardExtStyle.cardBgColor = '#BED000'
                    }

                    if (newCount === 0){
                        style.width = 272
                        style.height = 320
                        style.cardPositionX = Math.round(newCount.x - style.width - 40)
                        style.cardPositionY = Math.round(newCount.y - style.height + 60)
                        this.triangle = 'triangleRight'
                        this.agentData.cardExtStyle.cardBgColor = '#BED000'
                    }

                    if (newCount.x < window.screen.width / 2 && !this.special ) {
                        style.width = 272
                        style.height = 400
                        style.cardPositionX = Math.round(newCount.width + newCount.x + 40)
                        style.cardPositionY = Math.round(newCount.y)
                        this.triangle = 'triangleLeft'
                        this.agentData.cardExtStyle.cardBgColor = '#BED000'

                    }

                    if (this.special) {
                        style.cardPositionX = Math.round(newCount.left)
                        style.cardPositionY = Math.round(newCount.top)
                        style.width = Math.round(newCount.width - 10)
                        style.height = Math.round(newCount.height - 7)
                        this.triangle = null
                        this.agentData.cardExtStyle.cardBgColor = 'transparent'
                    }


                    this.checkSpecialSteps(this.onboardingSteps)

                    //setUp all texts
                    this.setupFields(this.onboardingSteps)

                } catch (error) {

                    throw error
                }
            },
            onboardingSteps(newCount, oldCount) {
                this.checkSpecialSteps(newCount)
                this.setupFields(newCount)

            }
        },

        computed: {

            agentPosition() {

                if (this.$store.getters['onboarding/getAgentPosition'].left) {

                    return this.$store.getters['onboarding/getAgentPosition']
                }
            },

            onboardingSteps() {

                if (this.$store.getters['onboarding/getOnboardingSteps']) {

                    return this.$store.getters['onboarding/getOnboardingSteps']
                }
            },

            ...mapGetters({
                getAuthUser: 'getAuthUser',
            })
        },

        created() {

            this.agentData.id = Date.now()

            this.setupFields(this.onboardingSteps)

            this.$store.commit('onboarding/setOnboardingAgentData', this.agentData)
        },

        methods: {

            createProperty(step) {

                if (step === 3){
                    step = 4
                }

                if (step === 4) {

                    this.setStep(step)

                    this.$router.push({ path: '/workflow/property/form' })
                }

                if (step >= 5 && step <=7 ){

                    const route = this.$router.currentRoute.fullPath // To get actual route

                    this.setStep(8)

                    this.$router.push({ path: `/workflow${route}/rental/create` })
                }
            },

            checkSpecialSteps(step) {
                this.specialSteps.includes(step) ? this.special = true : this.special = false
                this.imgAgentSteps.includes(step) ? this.img = 'agent' : this.img = null
                this.imgLightSteps.includes(step) ? this.img = 'light' : this.img = null
            },

            async setStep(step) {
                this.$store.commit('onboarding/setOnboardingSteps', { step: step, userId: this.getAuthUser['_jv'].id })

                if (step === 0) {

                    this.setAgentData({})

                    const onboardingData = {
                        onboarding_finished: true,
                        onboarding_step: 99
                    }

                    await this.$axios({ method: 'put', url: `/fapi/onboardings/${this.getAuthUser['_jv'].id}`, data: onboardingData })
                }
            },

            setAgentData(data) {
                this.$store.commit('onboarding/setOnboardingAgentData', data)
            },

            setupFields(step) {

                if (this.specialSteps.includes(step)){

                    this.title = ''

                    this.content = ''

                } else {

                    this.title = this.$t(`title${step}`)

                    this.content = this.$t(`content${step}`)
                }
            }
        },
    }

</script>

<i18n>
    {
        "fr": {
            "title2": " ",
            "content2": "Mission n°1 – Créer une fiche de lot",
            "title4": "Indice n°1",
            "content4": "Renseignez les informations des 5 étapes de la création d’une fiche de lot.Une fois toutes les étapes renseignées, validez le formulaire complet et passez à la mission suivante.",
            "title5": "Mission n°2 - Créer un contrat",
            "content5": "Choisissez le lot vacant sur lequel vous allez générer un contrat",
            "title6": "Mission n°2 - Créer un contrat",
            "content6": "Cliquez sur le bouton 'Créer' et séléctionnez  'Contrat de location'",
            "title8": "Indice n°2",
            "content8": "Renseignez les informations de toutes les étapes de création d’un contrat.Une fois toutes les étapes renseignées, validez le formulaire complet et passez à la mission suivante.",
            "title9": "Mission n°3",
            "content9": "Cliquez sur ce bouton pour envoyer une demande de signature aux personnes nommées dans ce contrat.",
            "button_exit": "Quitter l'onboarding"
        }
    }
</i18n>

<style lang="scss" scoped>
    #dynamical-agent {
        width: 100%;
    }

    .relative {
        position: relative;
        width: 100%;
    }

    .mx-auto{
        margin: 10px auto 20px auto;
    }
    .triangleRight:after {
        content: ' ';
        width: 0px;
        height: 0px;
        position: absolute;
        bottom: 0px;
        right: -14px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px;
        border-color: transparent transparent #BED000 #BED000;
        transform: rotate(225deg);
        border-radius: 5px;
    }

    .triangleLeft:before {
        content: ' ';
        width: 0px;
        height: 0px;
        position: absolute;
        top: 25px;
        left: -14px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px;
        border-color: transparent transparent #BED000 #BED000;
        transform: rotate(45deg);
        border-radius: 5px;
    }

    .agentContainer {
        display: flex;
        flex-flow: wrap;
        padding: 0 20px 0 20px;
        width: auto;
        height:85%;
    }

    .flexrow {
        display: flex;
        flex-flow: row;
        width: 100%;
    }

    .flex {
        display: flex;
        flex-flow: wrap;
    }

    .content {
        color: white;
        font-size: 1rem;
        margin-bottom: 8px;
        border-bottom: 1px solid white;
        padding-bottom: 8px;
    }

    .my-3 {
        margin: 10px 0 10px 0;
    }

    .row {
        width: 100%;
    }

    .title {
        width: auto !important;
        padding: 55px 0px 0 20px;
        text-align: center;
        color: white;
        font-weight: bold;
        font-size: 1.2rem;
    }

    .addBorder {
        border: 3px solid orange;
    }
</style>
