<template>

    <Transition name="drop-down">

        <div class="drop-down" v-if="open" v-click-outside="clickOutside">

            <slot name="header"></slot>

            <ul class="drop-down-list">

                <spinner-loader v-if="loading || fetchLoading" />

                <li class="drop-down-option" v-for="(option, optionKey) in fetchedOptions" :key="optionKey" @click="selectOption(option.value, option.text, option)" :disabled="option.disableButton" v-else>

                    <svg-icon class="drop-down-icon" v-if="option.icon === 'settings'" :iconColor="option.iconColor ? option.iconColor : '#BED000'" width="24" height="24" viewBox="0 0 24 24"><settings-icon /></svg-icon>

                    <svg-icon class="drop-down-icon" v-else-if="option.icon === 'logout'" width="24" height="24" viewBox="0 0 24 24" :iconColor="option.iconColor ? option.iconColor : '#BED000'"><log-out-icon /></svg-icon>

                    <svg-icon class="drop-down-icon" width="24" height="24" viewBox="0 0 24 24" :iconColor="option.iconColor ? option.iconColor : '#BED000'" v-else-if="option.icon === 'account'" ><account-icon /></svg-icon>

                    <svg-icon class="drop-down-icon" width="24" height="24" viewBox="0 0 24 24" :iconColor="option.iconColor ? option.iconColor : '#BED000'" v-else-if="option.icon === 'bank'"><bank-icon /></svg-icon>

                    <svg-icon class="drop-down-icon" width="24" height="24" viewBox="0 0 24 24" v-else-if="option.icon === 'help'"><support-icon /></svg-icon>

                    <svg-icon class="drop-down-icon" width="24" height="24" viewBox="0 0 24 24" :iconColor="option.iconColor ? option.iconColor : '#BED000'" v-else-if="option.icon === 'cgu'"><cgu-icon /></svg-icon>

                    <svg-icon class="drop-down-icon" v-else-if="option.icon === 'exchange'" :iconColor="option.iconColor ? option.iconColor : '#BED000'"><exchange-icon /></svg-icon>

                    <svg-icon class="drop-down-icon" v-else-if="option.icon === 'rents'" width="20" height="20" viewBox="0 0 24 24" :iconColor="option.iconColor ? option.iconColor : '#BED000'"><rent-status-icon /></svg-icon>

                    <svg-icon class="drop-down-icon" v-else-if="option.icon === 'costsTenant'" width="20" height="20" viewBox="0 0 24 24" :iconColor="option.iconColor ? option.iconColor : '#BED000'"><cost-tenant-icon /></svg-icon>

                    <svg-icon class="drop-down-icon" v-else-if="option.icon === 'shared'" width="20" height="20" viewBox="0 0 24 24" :iconColor="option.iconColor ? option.iconColor : '#BED000'"><shared-icon /></svg-icon>

                    <svg-icon class="drop-down-icon" v-else-if="option.icon === 'furnished'" width="20" height="20" viewBox="0 0 24 24" :iconColor="option.iconColor ? option.iconColor : '#BED000'"><furnished-icon /></svg-icon>

                    <svg-icon class="drop-down-icon" v-else-if="option.icon === 'unfurnished'" width="20" height="20" viewBox="0 0 24 24" :iconColor="option.iconColor ? option.iconColor : '#BED000'"><unfurnished-icon /></svg-icon>

                    <svg-icon class="drop-down-icon" v-else-if="option.icon === 'play'" width="24" height="24" viewBox="0 0 24 24" :iconColor="option.iconColor ? option.iconColor : '#BED000'"><play-icon /></svg-icon>

                    <svg-icon class="drop-down-icon" v-else-if="option.icon === 'news'" width="24" height="24" viewBox="0 0 24 24" :iconColor="option.iconColor ? option.iconColor : '#BED000'"><whats-new-icon /></svg-icon>

                    <svg class="red-dot" xmlns="http://www.w3.org/2000/svg" v-if="option.badge" width="10" height="10" viewBox="0 0 10 10" fill="none"><circle cx="5" cy="5" r="4" fill="#FA3737" stroke="white" stroke-width="2"/></svg>

                    <span :class="['select-icon', {'active': computedValue === option.text}]" v-if="computedValue"></span>

                    <template v-if="option.text"> {{ formatted(option.text) }}</template>

                    <div class="slot-option" v-else>

                        <slot name="option" v-bind:option="option"></slot>

                    </div>

                </li>

                <p class="no-options" v-if="!fetchedOptions.length && !loading && !fetchLoading">{{ getLabelEmptyResult }}</p>

            </ul>

        </div>

    </Transition>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import SettingsIcon from '~/components/icons/navigation-icons/settings-icon'
    import LogOutIcon from '~/components/icons/navigation-icons/log-out-icon'
    import AccountIcon from '~/components/icons/navigation-icons/account-icon'
    import ExchangeIcon from '~/components/icons/navigation-icons/exchange-icon'
    import SupportIcon from '~/components/icons/navigation-icons/support-icon'
    import CguIcon from '~/components/icons/navigation-icons/cgu-icon'
    import SpinnerLoader from '~/components/spinner-loader'
    import BankIcon from '~/components/icons/button-icons/bank-icon.vue'
    import RentStatusIcon from '~/components/icons/rent-status-icons/rent-status-icon'
    import CostTenantIcon from '~/components/icons/rent-status-icons/cost-tenant-icon'
    import PlayIcon from '~/components/icons/button-icons/play-icon'
    import WhatsNewIcon from "~/components/icons/button-icons/whats-new-icon";
    import {SharedIcon, FurnishedIcon, UnfurnishedIcon } from '~/components/icons/button-icons'

    export default {
        components: {
            SvgIcon,
            SettingsIcon,
            LogOutIcon,
            AccountIcon,
            ExchangeIcon,
            SupportIcon,
            SpinnerLoader,
            BankIcon,
            CguIcon,
            RentStatusIcon,
            CostTenantIcon,
            SharedIcon,
            FurnishedIcon,
            UnfurnishedIcon,
            PlayIcon,
            WhatsNewIcon
        },

        data() {
            return {
                fetchedOptions: [],
                fetchLoading: false
            }
        },

        watch: {
            options: {
                immediate: true,
                async handler(newVal) {

                    this.fetchLoading = true

                    if (typeof newVal === 'function') this.fetchedOptions = await newVal.call()

                    else this.fetchedOptions = newVal

                    this.fetchLoading = false
                }
            }
        },

        props: {
            options: {
                type: [Array, Function],
                required: true,
            },
            loading: {
                type: Boolean,
                required: false
            },
            open: {
                type: Boolean,
                default: true
            },
            disabled: {
                type: Boolean,
                default: true
            },
            computedValue: {
                type: String,
                default: ''
            },
            labelEmptyResult : {
                type:String
            }
        },

        methods: {
            clickOutside() {
                this.$emit('click-outside')
            },

            formatted(option) {
                return typeof option === 'string'
                    ? option.charAt(0).toUpperCase() + option.slice(1)
                    : option
            },

            selectOption(value, text, option) {
                this.$emit('selectOption', value, text, option)
            }
        },
        computed : {
            getLabelEmptyResult() {
                return this.labelEmptyResult || this.$t('no_options')
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "no_options": "Aucune option."
        }
    }
</i18n>

<style lang="scss" scoped>
    $color-black: #0E0A07;
    $border-color: #D4D9E7;
    $separator-color: #F2F2F2;

    .drop-down {
        border: 1px solid $border-color;
        border-radius: 4px;
        box-shadow: 0 5px 20px 0 rgba(4,16,41,0.02);
        background: #EFEFEF;
        color: #878787;
        font-size: 9.75pt;
        max-height: 216px;
        min-width: 160px;
        overflow-y: auto;
        transform-origin: top;
        font-weight: 600;
        position: absolute;
        z-index: 1;
        &.drop-down-enter-active {
            animation: scale-anim .2s ease-in-out;
        }

        &.drop-down-leave-active {
            animation: scale-anim .2s reverse ease-in-out;
        }

        .drop-down-list {
            cursor: default;
            padding: 15px 20px;
            font-size: inherit;
            color: inherit;

            .drop-down-option {
                margin-bottom: 10px;
                border-bottom: 1px solid $separator-color;
                display: flex;
                align-items: center;
                font-size: inherit;
                color: inherit;
                font-weight: 600;
                position: relative;

                .red-dot {
                    position: absolute;
                    left: 15px;
                    top: 1px;
                }

                .drop-down-icon {
                    flex-grow: 0;
                    margin-right: 8px;
                    height: 24px;
                }


                .select-icon {
                    display: flex;
                    width: 12px;
                    height: 12px;
                    padding: 2px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 20px;
                    border: 1px solid #6C6C6C;
                    background:  #FFF;
                    margin-right: 8px;

                    &.active {
                        background:  #C9DD00;
                        border-color:  #C9DD00;
                    }

                    &.active::after {
                        border-radius: 2px;
                        position: absolute;
                        display: flex;
                        border-bottom: 2px solid #fff;
                        border-left: 2px solid #fff;
                        transform: rotate(-45deg);
                        content: '';
                        width: calc(20px / 3);
                        height: calc(20px / 6);
                        margin-bottom: 2px;
                    }
                }

                .slot-option {
                    white-space: normal;
                    width: 100%;
                }

                &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: 0;
                }

                &[disabled] {
                    color: #b8b8b8;
                    pointer-events: none;
                }
                &:not([disabled]) {
                    cursor: pointer;
                }
            }
            .drop-down-option:hover {
                color: $color-black;
            }
        }
    }
</style>
