// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../static/img/widget/oskar-new-functionalities-layout-background1440x171.jpeg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header[data-v-073b7a7a]{align-items:flex-start}.header[data-v-073b7a7a],.header .courses-navigation-bar[data-v-073b7a7a]{display:flex;flex-direction:column;width:100%}.header .courses-navigation-bar[data-v-073b7a7a]{min-height:68px;position:sticky;top:0;z-index:9}.header .courses-navigation-bar .list[data-v-073b7a7a]{display:flex;flex-direction:row;flex:1;background:#fff;color:#fff}.header .courses-navigation-bar .list .nav-item[data-v-073b7a7a]{display:flex;align-items:center;padding:15px 30px}.header .courses-navigation-bar .list .nav-item.logo[data-v-073b7a7a]{border-right:1px solid #efefef}.header .courses-navigation-bar .list .nav-item.title[data-v-073b7a7a]{font-size:14pt;font-weight:600;color:#393939}.header .courses-navigation-bar .list .nav-item.saving-status[data-v-073b7a7a]{margin-top:3px;font-size:11pt;color:#9b9b9b}.header .courses-navigation-bar .list .nav-item.dropdown[data-v-073b7a7a]{margin-left:auto}.header .explore[data-v-073b7a7a]{display:flex;padding:52px 0;flex-direction:column;align-items:flex-start;grid-gap:12px;gap:12px;align-self:stretch;background:linear-gradient(0deg,rgba(98,170,71,.7),rgba(98,170,71,.7)),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") #d3d3d3 50%/cover no-repeat}.header .explore h1[data-v-073b7a7a]{font-size:32px}.header .explore h1[data-v-073b7a7a],.header .explore h3[data-v-073b7a7a]{align-self:stretch;color:#fff;text-align:center;font-style:normal;font-weight:600;line-height:120%}.header .explore h3[data-v-073b7a7a]{font-size:16px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
