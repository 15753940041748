<template>

    <form-group :label="label" :name="name" :rules="rules" ref="formGroup" v-slot="{ errors }">

        <input type="text" :value="value" v-show="false"/>

            <div :class="['bank-widget', { 'active': isActive(account) }, {'border': !isActive(account) && border}, { 'errors': errors.length }]" v-for="(account, key) in bankAccounts" :key="key" @click="$emit('input', account)">

                <div class="bank-title">

                    <svg-icon class="logo" width="28" height="28" viewBox="0 0 28 28" :iconColor="'#E5F9ED'" v-if="account.bank_name === 'all'"><all-logo /></svg-icon>

                    <svg-icon class="logo" width="28" height="28" viewBox="0 0 32 32" :iconColor="'#9166FF'" v-else-if="account.bank_name === 'Swan'"><swan-logo /></svg-icon>

                    <img-sprite class="logo border" :path="bankLogoSprite" :width="60" :height="60" :dpi="300" :total="bankOptions.length" :horizontalShift="bankOptions.indexOf(account.bank_name) + 1" v-else-if="bankOptions.includes(account.bank_name)" />

                    <svg-icon class="logo" width="28" height="28" viewBox="0 0 28 28" :iconColor="'#E5F9ED'" v-else><all-logo /></svg-icon>

                    <div class="info-bank-account">

                        <h4 class="title">{{ account.bank_name === 'Swan' ? 'Compte ' + account.bank_name : account.bank_name }}</h4>

                        <span class="iban">{{ ' •••••• ' + (account.iban || '••••').slice(-4) }}</span>

                    </div>

                </div>

                <div class="checkmark-box">

                    <span :class="['checkmark', { 'checked': isActive(account) === true }]"></span>

                </div>

            </div>

    </form-group>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'
    import BankAccountIconMixin from '~/components/mixins/icons/bank-account'
    import SvgIcon from '~/components/svg-icon'
    import ImgSprite from '~/components/img-sprite'

    export default {

        mixins: [BankAccountIconMixin],

        components: {
            FormGroup,
            SvgIcon,
            ImgSprite
        },

        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            rules: {
                type: [String, Object],
                default: ''
            },
            border:{
                type: Boolean,
                default: false
            },
            bankAccounts: {
                type: Array,
                required: true
            },
            value: {
                type: Object,
                required: true
            }
        },

        methods: {
            isActive(account) {
                return this.value?.iban === account.iban
            }
        },
    }
</script>

<i18n>
    {
        "fr": {
            "auto_sync": "Synchronisation auto.",
            "last_sync": "Actualisé {from}"
        }
    }
</i18n>

<style lang="scss" scoped>
    $grey-dark: #8D939F;
    $border-color: #DEE3EF;
    $basic-fakeblack: #333333;
    $radio-button-border-color: #6C6C6C;
    $radio-button-border-color-cheked: #BED000;

    .form-group ::v-deep .form-group-input {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 32px;
        grid-row-gap: 10px;
        width: 100%;
        .bank-widget {
            display: flex;
            justify-content: space-between;
            width: 324px;
            box-sizing: border-box;
            background: white;
            border-radius: 10px;
            border: 1px solid #DEE3EF;
            height: 56px;
            width: 100%;

            &.border{
                border: 1px solid #DEE3EF;
            }

            &.active {
                border-color: #BED000;
            }

            &.errors {
                border-color: #F5A623;
            }

            .bank-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 12px 18px;

                .logo {
                    margin-right: 7px;

                    &.border {
                        border: 1px solid $border-color;
                        border-radius: 6px;
                        padding: 3px;
                    }
                }

                .info-bank-account{
                    margin-left: 3px;

                    .title {
                        font-weight: 600;
                        font-size: 9.75pt;
                        color: #000;
                    }

                    .iban {
                        font-size: 7.5pt;
                        font-weight: 400;
                        color: #393939;
                    }
                }
            }

            .balance {
                color: $basic-fakeblack;
                font-family: 'Montserrat';
                font-size: 15pt;
            }

            .last_sync {
                display: flex;
                align-items: center;

                .icon {
                    margin-right: 5px;
                    background: #F3F4F4;
                    border-radius: 100%;
                    padding: 4px;
                }

                .text {
                    text-transform: uppercase;
                    font-weight: 600;
                    color: $grey-dark;
                    font-size: 7.5pt;
                }
            }

            .checkmark-box {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                background: #EFEFEF;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                .checkmark {
                    height: 14px;
                    width: 14px;
                    margin-right: 1px;
                    border: 1px solid $radio-button-border-color;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;

                    &:after {
                        content: "";
                        display: none;
                    }

                    &.checked {
                        border-color: $radio-button-border-color-cheked;
                        background: $radio-button-border-color-cheked;

                        &:after {
                            display: block;
                            width: calc(100% - 6px);
                            height: calc(100% - 6px);
                            border-radius: 100%;
                            background: white;
                        }
                    }
                }
            }
        }
    }
</style>
