<template>

    <div :class="['customer-auth-menu', { 'active': menuIsOpen }]">

        <div class="close-icon" @click="$emit('closeMenu', false)">

            <svg-icon :viewbox="'0 0 24 24'" :width="'24'" :height="'24'"><close-icon /></svg-icon>

        </div>

        <div class="customer-auth-wrapper">

            <div class="auth-container">

                <div class="icon auth-icon">

                    <svg-icon :iconStrokeColor="'#471BB5'" width="24" height="24" :viewbox="'0 0 17 18'"><profile-auth-icon /></svg-icon>

                </div>

                <div>

                    <p class="auth-name">{{ fullName ? fullName : userEmailAddress }}</p>

                    <p class="auth-description"> {{ authProfileDescription }} </p>

                </div>

            </div>

            <div class="account-wrapper">

                <p class="dashboard-info">{{ $t('dashboard') }}</p>

                <spinner-loader v-if="loading" />

                <div class="account-container" v-if="!loading && authAccount.length">

                    <div class="account" v-for="(account, key) in authAccount" :key="key" @click.stop="redirectAccount(account)">

                        <div>

                            <p class="account-name">{{ getProfileFullName(account) }}</p>

                            <p class="account-description">{{ accountDescription(account) }}</p>

                        </div>

                        <svg-icon class="next-account" :iconColor="'#FFFFFF'" :iconStrokeColor="'#FFFFFF'" width="16" height="16" :viewbox="'0 0 10 16'"><arrow-icon /></svg-icon>

                    </div>

                </div>

            </div>

            <div class="customer-options">

                <div class="customer-option" v-for="(option, optionKey) in userOptions" :key="optionKey" @click="redirectTo(option.value)">

                    <svg-icon class="option-icon" :iconStrokeColor="'#FFFFFF'" width="20" height="20" viewBox="0 0 24 24" v-if="option.icon === 'settings'"><settings-icon /></svg-icon>

                    <svg-icon class="option-icon" :iconColor="'#FFFFFF'" width="20" height="20" v-else-if="option.icon === 'exchange'"><exchange-icon /></svg-icon>

                    <svg-icon class="option-icon" :iconStrokeColor="'#FFFFFF'" width="20" height="20" v-else-if="option.icon === 'logout'"><log-out-icon /></svg-icon>

                    <p class="option-text"> {{ option.text }}</p>

                </div>

            </div>

        </div>

    </div>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import CloseIcon from '~/components/icons/button-icons/close-icon'
    import FullNameMixin from '~/components/mixins/full-name'
    import ProfileAuthIcon from '~/components/icons/profile/profile-auth-icon'
    import SettingsIcon from '~/components/icons/navigation-icons/settings-icon'
    import LogOutIcon from '~/components/icons/navigation-icons/log-out-icon'
    import ArrowIcon from '~/components/icons/button-icons/arrow-icon'
    import SpinnerLoader from '~/components/spinner-loader'
    import ExchangeIcon from '~/components/icons/navigation-icons/exchange-icon'
    import { mapGetters } from 'vuex'

    export default {
        mixins:[FullNameMixin],

        components: {
            SvgIcon,
            CloseIcon,
            ProfileAuthIcon,
            SettingsIcon,
            LogOutIcon,
            SpinnerLoader,
            ExchangeIcon,
            ArrowIcon
        },

        data() {
            return {
                loading: false,
                menuIsOpen: false,
                authAccount: []
            }
        },

        async fetch() {
            const { store } = this.$nuxt.context

            this.loading = true

            const landlords = await store.dispatch('profile/fetchLandlords')

            const tenants = await store.dispatch('profile/fetchTenants')

            if (this.profileRole === 'landlords') {
                landlords = landlords.response.filter(landlord => landlord['_jv'].id !== this.profileId)
                tenants = tenants.response
            }

            else if (this.profileRole === 'tenants') {
                landlords =  landlords.response,
                tenants = tenants.response.filter(tenant => tenant['_jv'].id !== this.profileId)
            }

            this.authAccount = landlords.response.concat(tenants.response)

            this.loading = false

        },

        methods: {
            redirectTo(path) {
                this.$router.push(path)
            },

            openMenu() {
                this.menuIsOpen = true
            },

            closeMenu() {
                this.menuIsOpen = false
            },

            async redirectAccount(account) {
                this.$store.commit('setAuthProfile', account)

                if (account['_jv'].type === 'landlords') {
                    this.$router.push('/dashboard/landlord/' + account['_jv'].id)
                }

                if (account['_jv'].type === 'tenants') {
                    this.$router.push('/dashboard/tenant/' + account['_jv'].id)
                }

                this.closeMenu()

            },

            accountDescription(account) {
                if (account['_jv'].type === 'landlords') {
                    return `${this.$t(account['_jv'].type)} - ` // ${Object.values(account.properties).length} ${this.$t('properties')}`
                }

                if (account['_jv'].type === 'tenants') {
                    return `${this.$t(account['_jv'].type)} -` // ${Object.values(account.rentals).length} ${this.$t('properties')}`
                }
            }
        },

        computed: {

            authProfileDescription() {
                if (this.profileRole === 'landlords') {

                    return `${this.$t('landlords')} - ${this.landlordProperties.length} ${this.$t('properties')}`

                } else if (this.profileRole === 'tenants') {

                    return `${this.$t('tenants')} - ${this.tenantProperties.length} ${this.$t('properties')}`
                }
            },

            userOptions() {
                return [
                    {
                        value: this.profileRole === 'landlords' ? `/landlord/${this.profileId}/update`: `/tenant/${this.profileId}/update`,
                        text: this.$t('personal_settings'),
                        icon: 'settings'
                    },
                    {
                        value: '/auth/switch-account',
                        text: this.$t('switch_account'),
                        icon: 'exchange'
                    },
                    {
                        value: '/auth/logout',
                        text: this.$t('signout'),
                        icon: 'logout'
                    }
                ]
            },

            ...mapGetters({
                fullName: 'profile/getFullName',
                userEmailAddress: 'user/getEmailAddress',
                profileId: 'profile/getId',
                profileRole: 'profile/getRole',
                landlordProperties: 'profile/getRelatedProperties',
                tenantProperties: 'profile/getRelatedRentals'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "landlords": "Propriétaire",
            "tenants": "Locataire",
            "properties": "lots",
            "dashboard": "Tableau de bord",
            "signout": "Se déconnecter",
            "personal_settings": "Paramètres personnel",
            "switch_account": "Changer de compte"
        }
    }
</i18n>

<style lang="scss" scoped>
    .customer-auth-menu {
        background-color: #471BB5;
        transform: translate(100%);
        transition: .2s ease-in-out;
        z-index: 10;
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        cursor: pointer;

        &.active {
            transform: translate(0px);
        }

        .close-icon {
            width: fit-content;
            margin: 25px 0px 0px 25px;
        }

        .customer-auth-wrapper {
            margin: 26px 28px 0px 28px;
            display: flex;
            flex-direction: column;

            .auth-container {
                display: flex;
                align-items: center;
                border-bottom: 1px solid #FFFFFF25;
                padding-bottom: 24px;

                .auth-icon {
                    width: 64px;
                    height: 64px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 16px;
                    border-radius: 100%;
                    background-color: #FFFFFF;
                }

                .auth-name {
                    font-weight: 400;
                    font-size: 13pt;
                    color: #FFFFFF;
                }

                .auth-description {
                    color: #FFFFFF60;
                }
            }
            .account-wrapper {
                padding: 36px 12px;
                border-bottom: 1px solid #FFFFFF25;

                .dashboard-info {
                    background-color: #FFFFFF;
                    padding: 18px;
                    font-weight: 600;
                    font-size: 13pt;
                    border-radius: 10px;
                    color: #471BB5;
                }

                .account-container {
                    .account {
                        display: flex;
                        justify-content: space-between;
                        margin-top: 24px;
                        align-items: center;
                        cursor: pointer;

                        .next-account {
                            transform: rotate(180deg);
                        }

                        .account-name {
                            color: #FFFFFF;
                            font-weight: 400;
                        }

                        .account-description {
                            color: #FFFFFF60;
                        }
                    }
                }
            }

            .customer-options {
                margin-top: 24px;

                .customer-option {
                    cursor: pointer;
                    white-space: nowrap;
                    margin-bottom: 24px;
                    display: flex;
                    align-items: center;
                    font-size: inherit;
                    color: inherit;

                    .option-icon {
                        flex-grow: 0;
                        margin-right: 20px;
                    }

                    .option-text {
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }

</style>
