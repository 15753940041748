<template>

    <div class="status-tag">

        <span :class="['tag', type]" v-if="type">

            <svg-icon class="icon" width="16" height="16" viewBox="0 0 24 24" iconColor="#FFFFFF" strokeWidth="0.5" v-if="icon === 'sign'"><signed-badge-icon /></svg-icon>

            <svg-icon class="icon" width="16" height="16" viewBox="0 0 24 24" :iconColor="iconColor" strokeWidth="0.5" v-else-if="['waiting', 'ongoing', 'reserved', 'provisioned'].includes(icon)"><waiting-icon /></svg-icon>

            <svg-icon class="icon" width="16" height="16" viewBox="0 0 24 24" :iconColor="iconColor" strokeWidth="0.1" v-else-if="['clock', 'pending'].includes(icon)"><clock-icon /></svg-icon>

            <svg-icon class="icon" width="16" height="16" viewBox="0 0 24 24" :iconColor="iconColor" strokeWidth="0.1" v-else-if="icon === 'complete'"><validate-icon /></svg-icon>

            <svg-icon class="icon" width="16" height="16" viewBox="0 0 24 24" iconColor="#FFFFFF" v-else-if="icon === 'profile'"><profile-icon /></svg-icon>

            <svg-icon class="icon" width="12" height="12" viewBox="0 0 24 24" iconColor="#FFFFFF" v-else-if="icon === 'missing'"><missing-icon /></svg-icon>

            <svg-icon class="icon" width="12" height="12" viewBox="0 0 24 24" iconColor="#FFFFFF" v-else-if="icon === 'phone'"><phone-icon /></svg-icon>

            <svg-icon class="icon" width="16" height="16" viewBox="0 0 24 24" iconColor="#FFFFFF" strokeWidth="0.5" v-else-if="icon === 'paid' || icon === 'refund' || icon === 'partial_refund'"><euro-icon /></svg-icon>

            <svg-icon class="icon" width="16" height="16" viewBox="0 0 24 24" iconColor="#FFFFFF" strokeWidth="0.5" v-else-if="icon === 'unpaid'"><euro-icon /></svg-icon>

            <slot></slot>

        </span>

    </div>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import { SignedBadgeIcon, WaitingIcon } from '~/components/icons/document-type-icons'
    import ProfileIcon from '~/components/icons/profile/profile-icon'
    import MissingIcon from '~/components/icons/status-icons/missing-icon'
    import ClockIcon from '~/components/icons/status-icons/clock-icon'
    import ValidateIcon from '~/components/icons/status-icons/validate-icon'
    import { PhoneIcon } from '~/components/icons/button-icons'
    import { EuroIcon } from '~/components/icons/bank-icons'


    export default {
        components: {
            SvgIcon,
            SignedBadgeIcon,
            WaitingIcon,
            ProfileIcon,
            MissingIcon,
            PhoneIcon,
            EuroIcon,
            ClockIcon,
            ValidateIcon
        },

        props: {
            type: {
                type: [String, Array],
                required: true
            },
            icon: {
                type: String,
                required: false
            }
        },

        computed: {
            iconColor() {
                switch (this.type) {
                    case 'complete':
                    case 'paid':
                    case 'excellent':
                    case 'refund':
                    case 'success':
                        return '#FFFFFF'; // Blanc
                    case 'ongoing':
                    case 'reserved':
                    case 'provisioned':
                        return '#F5A623'; // Orange
                    case 'not_started':
                    case 'fair':
                    case 'pending':
                    case 'draft':
                    case 'open':
                    case 'partial_refund':
                    case 'upcoming':
                        return '#F5A623'; // Orange
                    case 'notification':
                    case 'pending_signatories':
                        return '#BED000'; // Jaune
                    case 'warning':
                    case 'bad':
                    case 'unpaid':
                        return '#FFFFFF'; // Blanc
                    case 'good':
                        return '#BED000'; // Jaune
                    default:
                        return '#FFFFFF'; // Blanc
                }
            }
        },
    }
</script>

<style lang="scss">
    $tag-default-color: #EEF0F4;
    $tag-pending-color: #FFEFE2;
    $tag-active-color: #22C55E;
    $tag-warning-color: #E43F3F;

    .status-tag {
        flex: 1;
        display: flex;
        white-space: nowrap;


        .tag {
            display: flex;
            font-size: 8pt;
            text-transform: uppercase;
            background: $tag-default-color;
            display: flex;
            height: 20px;
            padding: 1px 6px;
            justify-content: flex-end;
            align-items: center;
            gap: 4px;
            border-radius: 4px;
            font-weight: 700;
            font-style: normal;

            &.complete,
            &.paid,
            &.excellent,
            &.success,
            &.refund,
            &.paid {
                background: $tag-active-color;
                color: white;
            }

            &.ongoing,
            &.provisioned,
            &.reserved {
                overflow: visible;
                background: rgba(245, 166, 35, 0.20);
                color: #F5A623;
                stroke: #F5A623;
            }

            &.not_started,
            &.fair,
            &.pending,
            &.draft,
            &.open,
            &.partial_refund,
            &.upcoming {
                background: $tag-pending-color;
                color: #F5A623;
                stroke: #F5A623;
            }

            &.pending-full-background {
                background: #F5A623;
                color: white;
                stroke: white;
            }

            &.notification,
            &.pending_signatories {
                background: #BED000;
                color: white;
                stroke: white;


                &.not_active {
                    background: #8D939F;
                }
            }

            &.warning,
            &.bad,
            &.unpaid {
                background: $tag-warning-color;
                color: white;
                stroke: white;
            }

            &.good {
                background: #22C55E;
                color: white;
                stroke: white;
            }

            .icon {
                background: transparent;
                stroke-width: 2px;
                margin-right: 4px;
            }
        }
    }
</style>
