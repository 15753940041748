import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5328a6f8 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _b8600702 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _59a94624 = () => interopDefault(import('../pages/nouveautes.vue' /* webpackChunkName: "pages/nouveautes" */))
const _5fccdc3b = () => interopDefault(import('../pages/nouveautes/_year.vue' /* webpackChunkName: "pages/nouveautes/_year" */))
const _3f0cf202 = () => interopDefault(import('../pages/nouveautes/_year/evolution-des-honoraires.vue' /* webpackChunkName: "pages/nouveautes/_year/evolution-des-honoraires" */))
const _525cb481 = () => interopDefault(import('../pages/nouveautes/_year/interface-des-honoraires.vue' /* webpackChunkName: "pages/nouveautes/_year/interface-des-honoraires" */))
const _5101ee41 = () => interopDefault(import('../pages/onboarding/index.vue' /* webpackChunkName: "pages/onboarding/index" */))
const _05493d13 = () => interopDefault(import('../pages/oskar-courses/index.vue' /* webpackChunkName: "pages/oskar-courses/index" */))
const _15247dee = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _3e532f98 = () => interopDefault(import('../pages/unauthenticated-signing-page/index.vue' /* webpackChunkName: "pages/unauthenticated-signing-page/index" */))
const _f662be36 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _6216843e = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _5b5a77e2 = () => interopDefault(import('../pages/auth/renew-password.vue' /* webpackChunkName: "pages/auth/renew-password" */))
const _d6da86e6 = () => interopDefault(import('../pages/auth/reset-password.vue' /* webpackChunkName: "pages/auth/reset-password" */))
const _4476a370 = () => interopDefault(import('../pages/auth/switch-account.vue' /* webpackChunkName: "pages/auth/switch-account" */))
const _07befb89 = () => interopDefault(import('../pages/dashboard/bank-accounts.vue' /* webpackChunkName: "pages/dashboard/bank-accounts" */))
const _2d4a4176 = () => interopDefault(import('../pages/dashboard/landlords.vue' /* webpackChunkName: "pages/dashboard/landlords" */))
const _1eb0639a = () => interopDefault(import('../pages/dashboard/notifications.vue' /* webpackChunkName: "pages/dashboard/notifications" */))
const _756a2471 = () => interopDefault(import('../pages/dashboard/properties.vue' /* webpackChunkName: "pages/dashboard/properties" */))
const _c211174e = () => interopDefault(import('../pages/dashboard/suppliers.vue' /* webpackChunkName: "pages/dashboard/suppliers" */))
const _4ac39ebb = () => interopDefault(import('../pages/dashboard/tenants.vue' /* webpackChunkName: "pages/dashboard/tenants" */))
const _1b6118c8 = () => interopDefault(import('../pages/dashboard/unpaid-costs.vue' /* webpackChunkName: "pages/dashboard/unpaid-costs" */))
const _67768fd3 = () => interopDefault(import('../pages/dashboard/unpaid-rent.vue' /* webpackChunkName: "pages/dashboard/unpaid-rent" */))
const _1dc92344 = () => interopDefault(import('../pages/error/404.vue' /* webpackChunkName: "pages/error/404" */))
const _a5cd62ce = () => interopDefault(import('../pages/onboarding/confirm_invitation.vue' /* webpackChunkName: "pages/onboarding/confirm_invitation" */))
const _26a4e444 = () => interopDefault(import('../pages/onboarding/demo.vue' /* webpackChunkName: "pages/onboarding/demo" */))
const _7eaf5b2e = () => interopDefault(import('../pages/settings/company-details.vue' /* webpackChunkName: "pages/settings/company-details" */))
const _c95fdda0 = () => interopDefault(import('../pages/swan/callback.vue' /* webpackChunkName: "pages/swan/callback" */))
const _681f6d03 = () => interopDefault(import('../pages/swan/consent-callback.vue' /* webpackChunkName: "pages/swan/consent-callback" */))
const _634a22c8 = () => interopDefault(import('../pages/dashboard/accounting/listing.vue' /* webpackChunkName: "pages/dashboard/accounting/listing" */))
const _08538a52 = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-accounts.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-accounts" */))
const _60b670af = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-entries.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-entries" */))
const _8388eb06 = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-entry-sets.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-entry-sets" */))
const _dd76a7e6 = () => interopDefault(import('../pages/dashboard/accounting/listing/accounting-journals.vue' /* webpackChunkName: "pages/dashboard/accounting/listing/accounting-journals" */))
const _f7bd17ce = () => interopDefault(import('../pages/viewer/CGU/cgu.vue' /* webpackChunkName: "pages/viewer/CGU/cgu" */))
const _6b8bfeb9 = () => interopDefault(import('../pages/workflow/account-statement/create.vue' /* webpackChunkName: "pages/workflow/account-statement/create" */))
const _2f52591e = () => interopDefault(import('../pages/workflow/landlord/create.vue' /* webpackChunkName: "pages/workflow/landlord/create" */))
const _e91f06de = () => interopDefault(import('../pages/workflow/property/form.vue' /* webpackChunkName: "pages/workflow/property/form" */))
const _6267cf32 = () => interopDefault(import('../pages/workflow/supplier/create.vue' /* webpackChunkName: "pages/workflow/supplier/create" */))
const _cb6ca758 = () => interopDefault(import('../pages/workflow/tenant/create.vue' /* webpackChunkName: "pages/workflow/tenant/create" */))
const _9ec94ae4 = () => interopDefault(import('../pages/dashboard/accounting/accounting-entry-set/_accountingEntrySetId/index.vue' /* webpackChunkName: "pages/dashboard/accounting/accounting-entry-set/_accountingEntrySetId/index" */))
const _b69c2ce8 = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/index.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/index" */))
const _b52349c0 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/index.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/index" */))
const _fa58b7e6 = () => interopDefault(import('../pages/viewer/file/_fileId.vue' /* webpackChunkName: "pages/viewer/file/_fileId" */))
const _72e0fdf5 = () => interopDefault(import('../pages/viewer/inventory/_inventoryId.vue' /* webpackChunkName: "pages/viewer/inventory/_inventoryId" */))
const _2ad72f06 = () => interopDefault(import('../pages/viewer/management-report/_managementReportId.vue' /* webpackChunkName: "pages/viewer/management-report/_managementReportId" */))
const _136d7d75 = () => interopDefault(import('../pages/viewer/mandate/_mandateId.vue' /* webpackChunkName: "pages/viewer/mandate/_mandateId" */))
const _01fae996 = () => interopDefault(import('../pages/viewer/payment/_paymentId.vue' /* webpackChunkName: "pages/viewer/payment/_paymentId" */))
const _7744ba47 = () => interopDefault(import('../pages/viewer/rent/_rentId.vue' /* webpackChunkName: "pages/viewer/rent/_rentId" */))
const _a24d1946 = () => interopDefault(import('../pages/viewer/rental/_rentalId.vue' /* webpackChunkName: "pages/viewer/rental/_rentalId" */))
const _18844f72 = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/documents.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/documents" */))
const _3d5f1306 = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/informations.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/informations" */))
const _074aab2a = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/management-reports.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/management-reports" */))
const _6fae672e = () => interopDefault(import('../pages/dashboard/landlord/_landlordId/properties.vue' /* webpackChunkName: "pages/dashboard/landlord/_landlordId/properties" */))
const _c87451f4 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/documents.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/documents" */))
const _4810d669 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/informations.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/informations" */))
const _3b798d39 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/payment-methods.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/payment-methods" */))
const _326a3e1d = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/rentals.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/rentals" */))
const _5f3c3aa8 = () => interopDefault(import('../pages/dashboard/tenant/_tenantId/rents.vue' /* webpackChunkName: "pages/dashboard/tenant/_tenantId/rents" */))
const _237a0121 = () => interopDefault(import('../pages/workflow/property/_propertyId/cost/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/cost/create" */))
const _4c45c3c6 = () => interopDefault(import('../pages/workflow/property/_propertyId/fee-note/form.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/fee-note/form" */))
const _33426efc = () => interopDefault(import('../pages/workflow/property/_propertyId/inventory/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/inventory/create" */))
const _6b5d14c9 = () => interopDefault(import('../pages/workflow/property/_propertyId/mandate/create.old.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/mandate/create.old" */))
const _a3cf1650 = () => interopDefault(import('../pages/workflow/property/_propertyId/mandate/form.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/mandate/form" */))
const _27c78b96 = () => interopDefault(import('../pages/workflow/property/_propertyId/rent/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/rent/create" */))
const _94f91eac = () => interopDefault(import('../pages/workflow/property/_propertyId/rental/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/rental/create" */))
const _73390b88 = () => interopDefault(import('../pages/workflow/property/_propertyId/tenant-cost/create.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/tenant-cost/create" */))
const _f993e236 = () => interopDefault(import('../pages/viewer/property/_propertyId/cost/_costId.vue' /* webpackChunkName: "pages/viewer/property/_propertyId/cost/_costId" */))
const _5b3e2d40 = () => interopDefault(import('../pages/viewer/property/_propertyId/fee-note/_fileId.vue' /* webpackChunkName: "pages/viewer/property/_propertyId/fee-note/_fileId" */))
const _1c3b76f6 = () => interopDefault(import('../pages/workflow/property/_propertyId/rental/_rentalId/replace-tenant/index.vue' /* webpackChunkName: "pages/workflow/property/_propertyId/rental/_rentalId/replace-tenant/index" */))
const _0782f471 = () => interopDefault(import('../pages/landlord/_landlordId/index.vue' /* webpackChunkName: "pages/landlord/_landlordId/index" */))
const _01465c1b = () => interopDefault(import('../pages/property/_propertyId/index.vue' /* webpackChunkName: "pages/property/_propertyId/index" */))
const _3101f649 = () => interopDefault(import('../pages/supplier/_supplierId/index.vue' /* webpackChunkName: "pages/supplier/_supplierId/index" */))
const _e74abef6 = () => interopDefault(import('../pages/tenant/_tenantId/index.vue' /* webpackChunkName: "pages/tenant/_tenantId/index" */))
const _445b5fd7 = () => interopDefault(import('../pages/landlord/_landlordId/documents.vue' /* webpackChunkName: "pages/landlord/_landlordId/documents" */))
const _cce56010 = () => interopDefault(import('../pages/landlord/_landlordId/informations.vue' /* webpackChunkName: "pages/landlord/_landlordId/informations" */))
const _2a6f3ba6 = () => interopDefault(import('../pages/landlord/_landlordId/management-reports.vue' /* webpackChunkName: "pages/landlord/_landlordId/management-reports" */))
const _24063028 = () => interopDefault(import('../pages/landlord/_landlordId/payouts.vue' /* webpackChunkName: "pages/landlord/_landlordId/payouts" */))
const _a560648c = () => interopDefault(import('../pages/landlord/_landlordId/update.vue' /* webpackChunkName: "pages/landlord/_landlordId/update" */))
const _4739f62c = () => interopDefault(import('../pages/property/_propertyId/contracts.vue' /* webpackChunkName: "pages/property/_propertyId/contracts" */))
const _225ca38f = () => interopDefault(import('../pages/property/_propertyId/costs.vue' /* webpackChunkName: "pages/property/_propertyId/costs" */))
const _596ee281 = () => interopDefault(import('../pages/property/_propertyId/documents.vue' /* webpackChunkName: "pages/property/_propertyId/documents" */))
const _89d788ba = () => interopDefault(import('../pages/property/_propertyId/rents.vue' /* webpackChunkName: "pages/property/_propertyId/rents" */))
const _1a590b20 = () => interopDefault(import('../pages/supplier/_supplierId/informations.vue' /* webpackChunkName: "pages/supplier/_supplierId/informations" */))
const _989df23c = () => interopDefault(import('../pages/supplier/_supplierId/update.vue' /* webpackChunkName: "pages/supplier/_supplierId/update" */))
const _02d23c2a = () => interopDefault(import('../pages/tenant/_tenantId/documents.vue' /* webpackChunkName: "pages/tenant/_tenantId/documents" */))
const _a6d8b538 = () => interopDefault(import('../pages/tenant/_tenantId/informations.vue' /* webpackChunkName: "pages/tenant/_tenantId/informations" */))
const _6463cf44 = () => interopDefault(import('../pages/tenant/_tenantId/payment-methods.vue' /* webpackChunkName: "pages/tenant/_tenantId/payment-methods" */))
const _7924b7b4 = () => interopDefault(import('../pages/tenant/_tenantId/update.vue' /* webpackChunkName: "pages/tenant/_tenantId/update" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dashboard",
    component: _5328a6f8,
    name: "dashboard___fr"
  }, {
    path: "/en",
    component: _b8600702,
    name: "index___en"
  }, {
    path: "/nouveautes",
    component: _59a94624,
    name: "nouveautes___fr",
    children: [{
      path: ":year?",
      component: _5fccdc3b,
      name: "nouveautes-year___fr",
      children: [{
        path: "evolution-des-honoraires",
        component: _3f0cf202,
        name: "nouveautes-year-evolution-des-honoraires___fr"
      }, {
        path: "interface-des-honoraires",
        component: _525cb481,
        name: "nouveautes-year-interface-des-honoraires___fr"
      }]
    }]
  }, {
    path: "/onboarding",
    component: _5101ee41,
    name: "onboarding___fr"
  }, {
    path: "/oskar-courses",
    component: _05493d13,
    name: "oskar-courses___fr"
  }, {
    path: "/settings",
    component: _15247dee,
    name: "settings___fr"
  }, {
    path: "/unauthenticated-signing-page",
    component: _3e532f98,
    name: "unauthenticated-signing-page___fr"
  }, {
    path: "/auth/login",
    component: _f662be36,
    name: "auth-login___fr"
  }, {
    path: "/auth/logout",
    component: _6216843e,
    name: "auth-logout___fr"
  }, {
    path: "/auth/renew-password",
    component: _5b5a77e2,
    name: "auth-renew-password___fr"
  }, {
    path: "/auth/reset-password",
    component: _d6da86e6,
    name: "auth-reset-password___fr"
  }, {
    path: "/auth/switch-account",
    component: _4476a370,
    name: "auth-switch-account___fr"
  }, {
    path: "/dashboard/bank-accounts",
    component: _07befb89,
    name: "dashboard-bank-accounts___fr"
  }, {
    path: "/dashboard/landlords",
    component: _2d4a4176,
    name: "dashboard-landlords___fr"
  }, {
    path: "/dashboard/notifications",
    component: _1eb0639a,
    name: "dashboard-notifications___fr"
  }, {
    path: "/dashboard/properties",
    component: _756a2471,
    name: "dashboard-properties___fr"
  }, {
    path: "/dashboard/suppliers",
    component: _c211174e,
    name: "dashboard-suppliers___fr"
  }, {
    path: "/dashboard/tenants",
    component: _4ac39ebb,
    name: "dashboard-tenants___fr"
  }, {
    path: "/dashboard/unpaid-costs",
    component: _1b6118c8,
    name: "dashboard-unpaid-costs___fr"
  }, {
    path: "/dashboard/unpaid-rent",
    component: _67768fd3,
    name: "dashboard-unpaid-rent___fr"
  }, {
    path: "/en/dashboard",
    component: _5328a6f8,
    name: "dashboard___en"
  }, {
    path: "/en/nouveautes",
    component: _59a94624,
    name: "nouveautes___en",
    children: [{
      path: ":year?",
      component: _5fccdc3b,
      name: "nouveautes-year___en",
      children: [{
        path: "evolution-des-honoraires",
        component: _3f0cf202,
        name: "nouveautes-year-evolution-des-honoraires___en"
      }, {
        path: "interface-des-honoraires",
        component: _525cb481,
        name: "nouveautes-year-interface-des-honoraires___en"
      }]
    }]
  }, {
    path: "/en/onboarding",
    component: _5101ee41,
    name: "onboarding___en"
  }, {
    path: "/en/oskar-courses",
    component: _05493d13,
    name: "oskar-courses___en"
  }, {
    path: "/en/settings",
    component: _15247dee,
    name: "settings___en"
  }, {
    path: "/en/unauthenticated-signing-page",
    component: _3e532f98,
    name: "unauthenticated-signing-page___en"
  }, {
    path: "/error/404",
    component: _1dc92344,
    name: "error-404___fr"
  }, {
    path: "/onboarding/confirm_invitation",
    component: _a5cd62ce,
    name: "onboarding-confirm_invitation___fr"
  }, {
    path: "/onboarding/demo",
    component: _26a4e444,
    name: "onboarding-demo___fr"
  }, {
    path: "/settings/company-details",
    component: _7eaf5b2e,
    name: "settings-company-details___fr"
  }, {
    path: "/swan/callback",
    component: _c95fdda0,
    name: "swan-callback___fr"
  }, {
    path: "/swan/consent-callback",
    component: _681f6d03,
    name: "swan-consent-callback___fr"
  }, {
    path: "/dashboard/accounting/listing",
    component: _634a22c8,
    name: "dashboard-accounting-listing___fr",
    children: [{
      path: "accounting-accounts",
      component: _08538a52,
      name: "dashboard-accounting-listing-accounting-accounts___fr"
    }, {
      path: "accounting-entries",
      component: _60b670af,
      name: "dashboard-accounting-listing-accounting-entries___fr"
    }, {
      path: "accounting-entry-sets",
      component: _8388eb06,
      name: "dashboard-accounting-listing-accounting-entry-sets___fr"
    }, {
      path: "accounting-journals",
      component: _dd76a7e6,
      name: "dashboard-accounting-listing-accounting-journals___fr"
    }]
  }, {
    path: "/en/auth/login",
    component: _f662be36,
    name: "auth-login___en"
  }, {
    path: "/en/auth/logout",
    component: _6216843e,
    name: "auth-logout___en"
  }, {
    path: "/en/auth/renew-password",
    component: _5b5a77e2,
    name: "auth-renew-password___en"
  }, {
    path: "/en/auth/reset-password",
    component: _d6da86e6,
    name: "auth-reset-password___en"
  }, {
    path: "/en/auth/switch-account",
    component: _4476a370,
    name: "auth-switch-account___en"
  }, {
    path: "/en/dashboard/bank-accounts",
    component: _07befb89,
    name: "dashboard-bank-accounts___en"
  }, {
    path: "/en/dashboard/landlords",
    component: _2d4a4176,
    name: "dashboard-landlords___en"
  }, {
    path: "/en/dashboard/notifications",
    component: _1eb0639a,
    name: "dashboard-notifications___en"
  }, {
    path: "/en/dashboard/properties",
    component: _756a2471,
    name: "dashboard-properties___en"
  }, {
    path: "/en/dashboard/suppliers",
    component: _c211174e,
    name: "dashboard-suppliers___en"
  }, {
    path: "/en/dashboard/tenants",
    component: _4ac39ebb,
    name: "dashboard-tenants___en"
  }, {
    path: "/en/dashboard/unpaid-costs",
    component: _1b6118c8,
    name: "dashboard-unpaid-costs___en"
  }, {
    path: "/en/dashboard/unpaid-rent",
    component: _67768fd3,
    name: "dashboard-unpaid-rent___en"
  }, {
    path: "/en/error/404",
    component: _1dc92344,
    name: "error-404___en"
  }, {
    path: "/en/onboarding/confirm_invitation",
    component: _a5cd62ce,
    name: "onboarding-confirm_invitation___en"
  }, {
    path: "/en/onboarding/demo",
    component: _26a4e444,
    name: "onboarding-demo___en"
  }, {
    path: "/en/settings/company-details",
    component: _7eaf5b2e,
    name: "settings-company-details___en"
  }, {
    path: "/en/swan/callback",
    component: _c95fdda0,
    name: "swan-callback___en"
  }, {
    path: "/en/swan/consent-callback",
    component: _681f6d03,
    name: "swan-consent-callback___en"
  }, {
    path: "/viewer/CGU/cgu",
    component: _f7bd17ce,
    name: "viewer-CGU-cgu___fr"
  }, {
    path: "/workflow/account-statement/create",
    component: _6b8bfeb9,
    name: "workflow-account-statement-create___fr"
  }, {
    path: "/workflow/landlord/create",
    component: _2f52591e,
    name: "workflow-landlord-create___fr"
  }, {
    path: "/workflow/property/form",
    component: _e91f06de,
    name: "workflow-property-form___fr"
  }, {
    path: "/workflow/supplier/create",
    component: _6267cf32,
    name: "workflow-supplier-create___fr"
  }, {
    path: "/workflow/tenant/create",
    component: _cb6ca758,
    name: "workflow-tenant-create___fr"
  }, {
    path: "/en/dashboard/accounting/listing",
    component: _634a22c8,
    name: "dashboard-accounting-listing___en",
    children: [{
      path: "accounting-accounts",
      component: _08538a52,
      name: "dashboard-accounting-listing-accounting-accounts___en"
    }, {
      path: "accounting-entries",
      component: _60b670af,
      name: "dashboard-accounting-listing-accounting-entries___en"
    }, {
      path: "accounting-entry-sets",
      component: _8388eb06,
      name: "dashboard-accounting-listing-accounting-entry-sets___en"
    }, {
      path: "accounting-journals",
      component: _dd76a7e6,
      name: "dashboard-accounting-listing-accounting-journals___en"
    }]
  }, {
    path: "/en/viewer/CGU/cgu",
    component: _f7bd17ce,
    name: "viewer-CGU-cgu___en"
  }, {
    path: "/en/workflow/account-statement/create",
    component: _6b8bfeb9,
    name: "workflow-account-statement-create___en"
  }, {
    path: "/en/workflow/landlord/create",
    component: _2f52591e,
    name: "workflow-landlord-create___en"
  }, {
    path: "/en/workflow/property/form",
    component: _e91f06de,
    name: "workflow-property-form___en"
  }, {
    path: "/en/workflow/supplier/create",
    component: _6267cf32,
    name: "workflow-supplier-create___en"
  }, {
    path: "/en/workflow/tenant/create",
    component: _cb6ca758,
    name: "workflow-tenant-create___en"
  }, {
    path: "/en/dashboard/accounting/accounting-entry-set/:accountingEntrySetId",
    component: _9ec94ae4,
    name: "dashboard-accounting-accounting-entry-set-accountingEntrySetId___en"
  }, {
    path: "/dashboard/accounting/accounting-entry-set/:accountingEntrySetId",
    component: _9ec94ae4,
    name: "dashboard-accounting-accounting-entry-set-accountingEntrySetId___fr"
  }, {
    path: "/en/dashboard/landlord/:landlordId",
    component: _b69c2ce8,
    name: "dashboard-landlord-landlordId___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId",
    component: _b52349c0,
    name: "dashboard-tenant-tenantId___en"
  }, {
    path: "/en/viewer/file/:fileId?",
    component: _fa58b7e6,
    name: "viewer-file-fileId___en"
  }, {
    path: "/en/viewer/inventory/:inventoryId?",
    component: _72e0fdf5,
    name: "viewer-inventory-inventoryId___en"
  }, {
    path: "/en/viewer/management-report/:managementReportId?",
    component: _2ad72f06,
    name: "viewer-management-report-managementReportId___en"
  }, {
    path: "/en/viewer/mandate/:mandateId?",
    component: _136d7d75,
    name: "viewer-mandate-mandateId___en"
  }, {
    path: "/en/viewer/payment/:paymentId?",
    component: _01fae996,
    name: "viewer-payment-paymentId___en"
  }, {
    path: "/en/viewer/rent/:rentId?",
    component: _7744ba47,
    name: "viewer-rent-rentId___en"
  }, {
    path: "/en/viewer/rental/:rentalId?",
    component: _a24d1946,
    name: "viewer-rental-rentalId___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/documents",
    component: _18844f72,
    name: "dashboard-landlord-landlordId-documents___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/informations",
    component: _3d5f1306,
    name: "dashboard-landlord-landlordId-informations___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/management-reports",
    component: _074aab2a,
    name: "dashboard-landlord-landlordId-management-reports___en"
  }, {
    path: "/en/dashboard/landlord/:landlordId?/properties",
    component: _6fae672e,
    name: "dashboard-landlord-landlordId-properties___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/documents",
    component: _c87451f4,
    name: "dashboard-tenant-tenantId-documents___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/informations",
    component: _4810d669,
    name: "dashboard-tenant-tenantId-informations___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/payment-methods",
    component: _3b798d39,
    name: "dashboard-tenant-tenantId-payment-methods___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/rentals",
    component: _326a3e1d,
    name: "dashboard-tenant-tenantId-rentals___en"
  }, {
    path: "/en/dashboard/tenant/:tenantId?/rents",
    component: _5f3c3aa8,
    name: "dashboard-tenant-tenantId-rents___en"
  }, {
    path: "/en/workflow/property/:propertyId?/cost/create",
    component: _237a0121,
    name: "workflow-property-propertyId-cost-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/fee-note/form",
    component: _4c45c3c6,
    name: "workflow-property-propertyId-fee-note-form___en"
  }, {
    path: "/en/workflow/property/:propertyId?/inventory/create",
    component: _33426efc,
    name: "workflow-property-propertyId-inventory-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/mandate/create.old",
    component: _6b5d14c9,
    name: "workflow-property-propertyId-mandate-create.old___en"
  }, {
    path: "/en/workflow/property/:propertyId?/mandate/form",
    component: _a3cf1650,
    name: "workflow-property-propertyId-mandate-form___en"
  }, {
    path: "/en/workflow/property/:propertyId?/rent/create",
    component: _27c78b96,
    name: "workflow-property-propertyId-rent-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/rental/create",
    component: _94f91eac,
    name: "workflow-property-propertyId-rental-create___en"
  }, {
    path: "/en/workflow/property/:propertyId?/tenant-cost/create",
    component: _73390b88,
    name: "workflow-property-propertyId-tenant-cost-create___en"
  }, {
    path: "/en/viewer/property/:propertyId?/cost/:costId?",
    component: _f993e236,
    name: "viewer-property-propertyId-cost-costId___en"
  }, {
    path: "/en/viewer/property/:propertyId?/fee-note/:fileId?",
    component: _5b3e2d40,
    name: "viewer-property-propertyId-fee-note-fileId___en"
  }, {
    path: "/en/workflow/property/:propertyId?/rental/:rentalId?/replace-tenant",
    component: _1c3b76f6,
    name: "workflow-property-propertyId-rental-rentalId-replace-tenant___en"
  }, {
    path: "/dashboard/landlord/:landlordId",
    component: _b69c2ce8,
    name: "dashboard-landlord-landlordId___fr"
  }, {
    path: "/dashboard/tenant/:tenantId",
    component: _b52349c0,
    name: "dashboard-tenant-tenantId___fr"
  }, {
    path: "/en/landlord/:landlordId",
    component: _0782f471,
    name: "landlord-landlordId___en"
  }, {
    path: "/en/property/:propertyId",
    component: _01465c1b,
    name: "property-propertyId___en"
  }, {
    path: "/en/supplier/:supplierId",
    component: _3101f649,
    name: "supplier-supplierId___en"
  }, {
    path: "/en/tenant/:tenantId",
    component: _e74abef6,
    name: "tenant-tenantId___en"
  }, {
    path: "/viewer/file/:fileId?",
    component: _fa58b7e6,
    name: "viewer-file-fileId___fr"
  }, {
    path: "/viewer/inventory/:inventoryId?",
    component: _72e0fdf5,
    name: "viewer-inventory-inventoryId___fr"
  }, {
    path: "/viewer/management-report/:managementReportId?",
    component: _2ad72f06,
    name: "viewer-management-report-managementReportId___fr"
  }, {
    path: "/viewer/mandate/:mandateId?",
    component: _136d7d75,
    name: "viewer-mandate-mandateId___fr"
  }, {
    path: "/viewer/payment/:paymentId?",
    component: _01fae996,
    name: "viewer-payment-paymentId___fr"
  }, {
    path: "/viewer/rent/:rentId?",
    component: _7744ba47,
    name: "viewer-rent-rentId___fr"
  }, {
    path: "/viewer/rental/:rentalId?",
    component: _a24d1946,
    name: "viewer-rental-rentalId___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/documents",
    component: _18844f72,
    name: "dashboard-landlord-landlordId-documents___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/informations",
    component: _3d5f1306,
    name: "dashboard-landlord-landlordId-informations___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/management-reports",
    component: _074aab2a,
    name: "dashboard-landlord-landlordId-management-reports___fr"
  }, {
    path: "/dashboard/landlord/:landlordId?/properties",
    component: _6fae672e,
    name: "dashboard-landlord-landlordId-properties___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/documents",
    component: _c87451f4,
    name: "dashboard-tenant-tenantId-documents___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/informations",
    component: _4810d669,
    name: "dashboard-tenant-tenantId-informations___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/payment-methods",
    component: _3b798d39,
    name: "dashboard-tenant-tenantId-payment-methods___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/rentals",
    component: _326a3e1d,
    name: "dashboard-tenant-tenantId-rentals___fr"
  }, {
    path: "/dashboard/tenant/:tenantId?/rents",
    component: _5f3c3aa8,
    name: "dashboard-tenant-tenantId-rents___fr"
  }, {
    path: "/en/landlord/:landlordId?/documents",
    component: _445b5fd7,
    name: "landlord-landlordId-documents___en"
  }, {
    path: "/en/landlord/:landlordId?/informations",
    component: _cce56010,
    name: "landlord-landlordId-informations___en"
  }, {
    path: "/en/landlord/:landlordId?/management-reports",
    component: _2a6f3ba6,
    name: "landlord-landlordId-management-reports___en"
  }, {
    path: "/en/landlord/:landlordId?/payouts",
    component: _24063028,
    name: "landlord-landlordId-payouts___en"
  }, {
    path: "/en/landlord/:landlordId?/update",
    component: _a560648c,
    name: "landlord-landlordId-update___en"
  }, {
    path: "/en/property/:propertyId?/contracts",
    component: _4739f62c,
    name: "property-propertyId-contracts___en"
  }, {
    path: "/en/property/:propertyId?/costs",
    component: _225ca38f,
    name: "property-propertyId-costs___en"
  }, {
    path: "/en/property/:propertyId?/documents",
    component: _596ee281,
    name: "property-propertyId-documents___en"
  }, {
    path: "/en/property/:propertyId?/rents",
    component: _89d788ba,
    name: "property-propertyId-rents___en"
  }, {
    path: "/en/supplier/:supplierId?/informations",
    component: _1a590b20,
    name: "supplier-supplierId-informations___en"
  }, {
    path: "/en/supplier/:supplierId?/update",
    component: _989df23c,
    name: "supplier-supplierId-update___en"
  }, {
    path: "/en/tenant/:tenantId?/documents",
    component: _02d23c2a,
    name: "tenant-tenantId-documents___en"
  }, {
    path: "/en/tenant/:tenantId?/informations",
    component: _a6d8b538,
    name: "tenant-tenantId-informations___en"
  }, {
    path: "/en/tenant/:tenantId?/payment-methods",
    component: _6463cf44,
    name: "tenant-tenantId-payment-methods___en"
  }, {
    path: "/en/tenant/:tenantId?/update",
    component: _7924b7b4,
    name: "tenant-tenantId-update___en"
  }, {
    path: "/workflow/property/:propertyId?/cost/create",
    component: _237a0121,
    name: "workflow-property-propertyId-cost-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/fee-note/form",
    component: _4c45c3c6,
    name: "workflow-property-propertyId-fee-note-form___fr"
  }, {
    path: "/workflow/property/:propertyId?/inventory/create",
    component: _33426efc,
    name: "workflow-property-propertyId-inventory-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/mandate/create.old",
    component: _6b5d14c9,
    name: "workflow-property-propertyId-mandate-create.old___fr"
  }, {
    path: "/workflow/property/:propertyId?/mandate/form",
    component: _a3cf1650,
    name: "workflow-property-propertyId-mandate-form___fr"
  }, {
    path: "/workflow/property/:propertyId?/rent/create",
    component: _27c78b96,
    name: "workflow-property-propertyId-rent-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/rental/create",
    component: _94f91eac,
    name: "workflow-property-propertyId-rental-create___fr"
  }, {
    path: "/workflow/property/:propertyId?/tenant-cost/create",
    component: _73390b88,
    name: "workflow-property-propertyId-tenant-cost-create___fr"
  }, {
    path: "/viewer/property/:propertyId?/cost/:costId?",
    component: _f993e236,
    name: "viewer-property-propertyId-cost-costId___fr"
  }, {
    path: "/viewer/property/:propertyId?/fee-note/:fileId?",
    component: _5b3e2d40,
    name: "viewer-property-propertyId-fee-note-fileId___fr"
  }, {
    path: "/workflow/property/:propertyId?/rental/:rentalId?/replace-tenant",
    component: _1c3b76f6,
    name: "workflow-property-propertyId-rental-rentalId-replace-tenant___fr"
  }, {
    path: "/landlord/:landlordId",
    component: _0782f471,
    name: "landlord-landlordId___fr"
  }, {
    path: "/property/:propertyId",
    component: _01465c1b,
    name: "property-propertyId___fr"
  }, {
    path: "/supplier/:supplierId",
    component: _3101f649,
    name: "supplier-supplierId___fr"
  }, {
    path: "/tenant/:tenantId",
    component: _e74abef6,
    name: "tenant-tenantId___fr"
  }, {
    path: "/landlord/:landlordId?/documents",
    component: _445b5fd7,
    name: "landlord-landlordId-documents___fr"
  }, {
    path: "/landlord/:landlordId?/informations",
    component: _cce56010,
    name: "landlord-landlordId-informations___fr"
  }, {
    path: "/landlord/:landlordId?/management-reports",
    component: _2a6f3ba6,
    name: "landlord-landlordId-management-reports___fr"
  }, {
    path: "/landlord/:landlordId?/payouts",
    component: _24063028,
    name: "landlord-landlordId-payouts___fr"
  }, {
    path: "/landlord/:landlordId?/update",
    component: _a560648c,
    name: "landlord-landlordId-update___fr"
  }, {
    path: "/property/:propertyId?/contracts",
    component: _4739f62c,
    name: "property-propertyId-contracts___fr"
  }, {
    path: "/property/:propertyId?/costs",
    component: _225ca38f,
    name: "property-propertyId-costs___fr"
  }, {
    path: "/property/:propertyId?/documents",
    component: _596ee281,
    name: "property-propertyId-documents___fr"
  }, {
    path: "/property/:propertyId?/rents",
    component: _89d788ba,
    name: "property-propertyId-rents___fr"
  }, {
    path: "/supplier/:supplierId?/informations",
    component: _1a590b20,
    name: "supplier-supplierId-informations___fr"
  }, {
    path: "/supplier/:supplierId?/update",
    component: _989df23c,
    name: "supplier-supplierId-update___fr"
  }, {
    path: "/tenant/:tenantId?/documents",
    component: _02d23c2a,
    name: "tenant-tenantId-documents___fr"
  }, {
    path: "/tenant/:tenantId?/informations",
    component: _a6d8b538,
    name: "tenant-tenantId-informations___fr"
  }, {
    path: "/tenant/:tenantId?/payment-methods",
    component: _6463cf44,
    name: "tenant-tenantId-payment-methods___fr"
  }, {
    path: "/tenant/:tenantId?/update",
    component: _7924b7b4,
    name: "tenant-tenantId-update___fr"
  }, {
    path: "/",
    component: _b8600702,
    name: "index___fr"
  }],

  parseQuery: function(queryString) {
            return require('qs').parse(queryString)
        },
  stringifyQuery: function(object) {
            var queryString = require('qs').stringify(object)
            return queryString ? '?' + queryString : ''
        },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
