<template>

    <div class="layout-col col-3 sidebar">

        <aside class="workflow-sidebar">

            <ul class="step-list">

                <li
                    v-for="(step, key) in workflow.steps"
                    :key="key"
                    :class="['step', { 'active': isStepOpened(key) }]"
                    @click="toggleStepOpen(key)"
                >{{ step.text }}

                    <ul :class="['sub-section', {'collapsed': !isStepOpened(key) }]">
                        <li
                            v-for="(subStep, subKey) in step['subSection']"
                            :key="subKey"
                            :class="['sub-section-title', {'active': subStep.isValid }]"
                        >
                            <span :class="['completion-icon', {'active': subStep.isValid }]"></span>
                            {{ subStep.text }}
                        </li>

                    </ul>

                </li>
            </ul>
        </aside>

    </div>
</template>

<script>
import Workflow from "@/shared/Workflow";

export default {
    name: "workflow-sidebar",


    props: {
        workflow: {
            type: Workflow,
            required: true,
        }
    },

    methods: {
        toggleStepOpen(key) {
            this.workflow.toggleStepOpen(key);
        },
        isStepOpened(index) {
            return this.workflow.isStepOpened(index);
        }
    },
}
</script>


<style scoped lang="scss">
.workflow-sidebar {
    margin-top: 60px;
    position: sticky;
    top: 130px;
}
</style>
