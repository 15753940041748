<template>
    <header class="workflow-navigation-bar">
        <ul class="list">
            <li class="nav-item logo">
                <logo/>
            </li>
            <li class="nav-item title">{{ title }}</li>
            <li class="nav-item sub-title">{{ subTitle }}</li>
            <li class="nav-item exit" @click="$emit('redirect')"></li>
        </ul>

    </header>
</template>

<script>
import Logo from '@/components/logo.vue'

export default {
    components: {
        Logo,
    },

    props: {
        title: {
            type: String,
            required: true
        },
        subTitle: {
            type: String,
            required: false
        },
    },
}
</script>

<i18n>
{
    "fr": {
        "step_status": "Étape {position} sur {total}",
        "back": "Retour"
    }
}
</i18n>

<style lang="scss">
$white: #ffffff;
$white-bar: #EFEFEF;
$progress-bar-background-color: rgba(#6c6c6c, 0.25);
$progress-bar-color: #BED000;
$header-height: 68px;

.workflow-navigation-bar {
    min-height: $header-height;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;

    .list {
        display: flex;
        flex-direction: row;
        flex: 1;
        background: $white;
        color: white;

        .nav-item {
            display: flex;
            align-items: center;
            padding: 15px 30px;

            &.logo {
                border-right: 1px solid $white-bar;
            }

            &.title {
                font-size: 13.5pt;
                color: #393939;
            }

            &.sub-title {
                margin-top: 3px;
                font-size: 11pt;
                color: #9B9B9B;
            }

            &.exit {
                $icon-width: 16px;

                margin-left: auto;
                border-left: 1px solid $white-bar;
                position: relative;
                flex-basis: $icon-width;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &:before, &:after {
                    position: absolute;
                    content: '';
                    display: flex;
                    background: #525254;
                    border-radius: 2px;
                    width: 2px;
                    height: $icon-width;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }

        }
    }
}
</style>
