<template>

    <div class="dashboard-layout">

        <smart-navigation-bar />

        <property-management-company-header class="company-header" />

        <error-banner :title="$t('error_message')" :details="$t('contact_support')" v-if="errorMessage" />

        <nuxt-child class="dashboard-content" />

    </div>

</template>

<script>
    import SmartNavigationBar from '~/components/navigation/header/smart-navigation-bar'
    import PropertyManagementCompanyHeader from '~/components/navigation/header/property-management-company-header'
    import { mapGetters } from 'vuex'

    export default {
        middleware: 'manager',

        components: {
            PropertyManagementCompanyHeader,
            SmartNavigationBar,
        },

        async fetch() {

            const { store } = this.$nuxt.context

            await store.dispatch('property-manager/fetchLogoRelationship', this.propertyManagerId)

            await store.dispatch('property-manager/fetchBankAccounts')
        },

        data() {
            return {
                errorMessage: ''
            }
        },

        computed: {
            ...mapGetters({
                paymentMethod: 'property-manager/getRelatedPaymentMethod',
                propertyManagerId: 'property-manager/getId'
            })
        },

        errorCaptured(error) {

            if (!!error.response && !!error.response.status && parseInt(error.response.status) >= 500) {

                this.errorMessage = error
            }
        },

        watch: {
            $route() {
                this.$fetch()
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "error_message": "Une erreur est survenue",
            "contact_support": "Contactez le support en envoyant un e-mail à support@oskar.immo si le problème persiste."
        }
    }
</i18n>

<style lang="scss" scoped>
    $width: 1180px;
    $background: #EFEFEF;

    .dashboard-layout {
        min-height: 100vh;
        background: $background;

        .dashboard-content {
            max-width: $width;
            margin: 0 auto;
            padding: 45px 0;
        }
    }
</style>
