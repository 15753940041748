<template>

    <aside class="update-sidebar">

        <ul class="step-list">

            <li :class="['step', { 'active': activeStep === step.name }]" v-for="(step, stepKey) in stepList" :key="stepKey" @click="switchStep(step.name)">

                <div :class="['label', { 'active': activeStep === step.name }]">

                    {{ step.label }}

                    <status-tag class="notif" :type="['notification', {'not_active': activeStep !== step.name }]" v-if="filtertag(step.name) !== 0"> {{ filtertag(step.name) }} </status-tag>

                </div>

                <ul :class="['sub-section', { 'collapsed': activeStep !== step.name }]" v-if="getStepFieldsFilteredByStep(step.name).length">

                    <li :class="['sub-section-title' , {'active' :field.valid}]" v-for="(field, sectionKey) in getStepFieldsFilteredByStep(step.name)" :key="sectionKey" v-show="field.label">

                        <span :class="['completion-icon' , {'active' :field.valid}]"></span>

                        {{ field.label }}

                    </li>

                </ul>

            </li>

        </ul>

    </aside>

</template>

<script>
import { mapGetters } from 'vuex'
import StatusTag from '~/components/status-tag'
import {validate} from 'vee-validate'

export default {
    components: {
        StatusTag
    },

    data() {
        return {
            stepFieldsWithValidator: []
        }
    },

    watch:{
        stepFields: {
            immediate:true,
            async handler(stepFields) {
                let stepFieldsWithValidator = []

                for (const field of stepFields) {

                    let { valid } = await validate(field.value, field.rules)

                    if (typeof field.value === 'array' && field.value.length === 0) valid = false

                    if (typeof field.value === 'object' && Object.keys(field.value).length === 0) valid = false

                    if (typeof field.value === 'string' && field.value === '') valid = false

                    if (typeof field.value === 'boolean' && field.value === false) valid = false

                    if (['disable','password'].includes(field.type)) valid = true

                    stepFieldsWithValidator.push(Object.assign(field, {
                      valid: valid
                    }))
                }

                this.stepFieldsWithValidator = stepFieldsWithValidator;
            }
        }
    },

    methods: {
        switchStep(code) {
            if (code !== this.activeStep) this.$store.dispatch('workflow/switchStep', code)
        },

        getStepFieldsFilteredByStep(code) {
            return this.stepFieldsWithValidator.filter(field => field.id === code)
        },

        filtertag(code) {
            return this.stepFieldsWithValidator.filter(field => field.id === code && !field.valid).length
        }
    },

    computed: {
        ...mapGetters({
            activeStep: 'workflow/getActiveStep',
            stepList: 'workflow/getSteps',
            stepFields: 'workflow/getFields'
        })
    }
}
</script>

<i18n>
    {
        "fr": {
            "my_account": "Mon compte",
            "my_agency": "Mon agence"
        }
    }
</i18n>

<style lang="scss">
    $title-color: #8D939F;
    $step-color: #8F94A0;
    $color-active: #BED000;
    $separator-color: #DEE3EF;

    .update-sidebar {
        margin-top: 60px;

        
        .step-list {
            position: sticky;
            top: 130px;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .label {
            position: relative;
            color: $step-color;
            font-size: 12pt;
            cursor: pointer;
            line-height: 18px;

            &.active {
                color: $color-active;
                font-weight: 400;
            }

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .notif {
                position: absolute;
                max-height: 20px;
                right: -7%;
                top: 50%;
                transform: translateY(-50%);
                line-height: normal;
            }
        }

        .title {
            color: $title-color;
            font-weight: 800;
            line-height: 32px;
            text-transform: uppercase;
            font-size: 8pt;
            margin-bottom: 6px;
        }

        .separator {
            border-top: 1px solid;
            color: $separator-color;
            margin: 24px 0;
        }

        $size: 12px;
        $grey: #8D939F;
        $color-black: #0E0A07;

        .sub-section {
            margin-left: 24px;
            line-height: 32px;

            &.collapsed {
                display: none;
            }

            .sub-section-title {
                color: $grey;
                display: flex;
                flex-direction: row;
                align-items: center;
                font-family: 'Montserrat';
                font-size: 11pt;

                &.active{
                    color: $color-active;
                }

                .completion-icon {
                    display: flex;
                    position: relative;
                    width: $size;
                    height: $size;
                    border: 2px solid $grey;
                    border-radius: 100%;
                    align-items: center;
                    justify-content: center;
                    margin-right: 5px;
                    flex-shrink: 0;

                    &.active{
                        border: 2px solid $color-active;

                        &:after {
                            border-radius: 2px;
                            position: absolute;
                            border-bottom: 2px solid $color-active;
                            border-left: 2px solid $color-active;
                            transform: rotate(-45deg);
                            content: '';
                            width: calc(#{$size} / 3);
                            height: calc(#{$size} / 6);
                        }

                    }
                }
            }
        }
    }

</style>
