<template>

    <widget class="tenant-list-widget" :title="$t('tenants')">

        <hr class="separator" />
        
        <ul class="list" v-if="tenants.length">
            
            <li class="tenant" v-for="(tenant, tenantId) in tenants" :key="tenantId">
                
                <div class="tenant-icon">

                    <svg-icon viewbox="0 0 42 42" class="icon" width="24" height="24" ><profile-icon /></svg-icon>

                </div>

                <div class="tenant-details">
                    
                    <span :class="['item', 'full-name', { 'active': getProfileFullName(tenant) }]">
                        
                        {{ getProfileFullName(tenant) ? getProfileFullName(tenant) : $t('no_name') }}
                        
                    </span>
                    
                    <!-- <span :class="['item', 'adress', { 'active': tenantAddress(tenant) }]">
                        
                        {{ tenantAddress(tenant) ? tenantAddress(tenant): $t('no_address')  }}
                        
                    </span> -->
                    
                    <span :class="['item', { 'active': tenant.email_address }]">
                        
                        {{ tenant.email_address ? tenant.email_address : $t('no_email_address') }}
                        
                    </span>
                    
                    <span :class="['item', { 'active': tenant.phone_number }]">
                        
                        {{ tenant.phone_number ? tenant.phone_number : $t('no_phone_number') }}
                        
                    </span>
                    
                </div>

                <nuxt-link class="tenant-icon-link" :to="`/tenant/${tenant['_jv'].id}`" no-prefetch v-if="isManager">

                    <svg-icon :iconColor="'#333333'" :iconStrokeColor="'#333333'" width="16" height="16" viewBox="0 0 10 16"><next-icon /></svg-icon>
               
                </nuxt-link>
                 
            </li>
        
        </ul>
        
        <div class="tenants-empty" v-else>
            
            <svg-icon viewbox="0 0 42 42" class="icon" width="42" height="42" ><profile-icon /></svg-icon>
                
            <p class="text" v-html="$t('empty_data')"></p>
            
        </div>
        
    </widget>
    
</template>

<script>
    import FullNameMixin from '~/components/mixins/full-name'
    import SvgIcon from '~/components/svg-icon'
    import NextIcon from '~/components/icons/button-icons/next-icon'
    import ProfileIcon from '~/components/icons/empty-resource-icons/profile-icon'
    import Widget from '~/components/widget'
    import { mapGetters } from 'vuex'
    
    export default {
        mixins: [FullNameMixin],
        
        components: {
            Widget,
            SvgIcon,
            NextIcon,
            ProfileIcon
        },
        
        props: {
            tenants: {
                type: Array,
                required: false
            }
        },

        methods: {
            tenantAddress(tenant) {
                if (typeof tenant.legal_persons !== 'undefined' && tenant.legal_persons.length) {
                    if (typeof tenant.legal_persons[0].address !== 'undefined') 
                        return tenant.legal_persons[0].address.formatted
                }
            }
        },

        computed: {
            ...mapGetters({
                isManager: 'isManager'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "modify": "Modifier",
            "tenants": "Locataire(s)",
            "empty_data": "Aucun locataire n'occupe le lot actuellement.",
            "no_address": "Adresse inconnue",
            "no_name": "Nom complet inconnu",
            "no_email_address": "Adresse e-mail inconnue",
            "no_phone_number": "Numéro de téléphone inconnu",
            "see": "Voir"
        }
    }
</i18n>

<style lang="scss">
    $tenant-color: #767676;
    $border-color: #FFF;
    $item-color: #8F94A0;

    .tenant-list-widget {
        position: relative;
        box-shadow: 0px 4px 13px 0px #8D8D8D14;
        box-shadow: 0px 7px 9px 0px #8D8D8D1A;

        .separator {
            margin: 0 0 24px;
            border: none;
            border-bottom: 1px solid #F2F2F2;
        }


        .tenant {
            display: flex;
            flex-direction: row;
            gap: 12px;
            align-items: center;
            padding-bottom: 17px;
            margin-bottom: 22px;
            border-bottom: 1px solid $border-color;
            

            &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
                border: none;
            }

            .tenant-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border-radius: 6px;
                background:#EFEFEF;
            }

            .tenant-details {
                display: flex;
                flex-direction: column;

                .item {
                    display: flex;
                    flex-direction: column;
                    @include body;

                    &.active.full-name {
                        font-weight: 600;
                    }

                    &.active:not(.full-name) {
                        color: $tenant-color;
                    }

                    &.active.adress {
                        color: black;
                    }

                    &:not(.active) {
                        text-decoration: line-through;
                        color: $item-color;
                        opacity: .5;
                    }
                }
            }

            .tenant-icon-link{
                margin-left: 16px;
            }

            .tenants-modify {
                position: absolute;
                font-weight: 400;
                top: 28px;
                right: 25px;
                transform: translateY(25%);
                
                .item {
                    color: $tenant-color;
                }
            }
        }

        .tenants-empty {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 50px 0;

            .icon {
                margin-bottom: 25px;
                border: 5px solid #EFEFEF;
                border-radius: 100%;
                background: #EFEFEF;
            }
            
            .text {
                color: #8F94A0;
            }
        }
    }
</style>