<template>
    
    <widget class="landlord-property-list" :title="$t('current_property_rental')" ref="widget">
        
        <ul class="property-list-container" v-if="Object.values(properties).length">

            <li class="property-list-content" v-for="(property, index) in Object.values(properties)" :key="index" @click="redirectToProperty(property)">

                <property-item :property="property">

                    <status-tag class="tenant" :type="property['_jv']['relationships']['tenants']['data'].length > 0 ? 'good' : 'bad'" :icon="'profile'">{{ tenantFormat(property['_jv']['relationships']['tenants']['data']) }}</status-tag>

                </property-item>

            </li>
            
        </ul>

        <p class="empty" v-else>{{ $t('no_rental_property') }}</p>

    </widget>

</template>

<script>
    import Widget from '~/components/widget'
    import FullNameMixin from '~/components/mixins/full-name'
    import PropertyItem from '~/components/property-item'
    import TenantIcon from '~/components/icons/table-cell-icons/tenant-icon'
    import Arrow from '~/components/arrow'
    import SvgIcon from '~/components/svg-icon'
    import StatusTag from '~/components/status-tag'
    
    export default {
        
        name: "LandlordPropertyListWidget",
        
        mixins: [FullNameMixin],

        components: { 
            Widget,
            SvgIcon,
            TenantIcon,
            Arrow,
            PropertyItem,
            StatusTag
        },

        data() {
            return {
                properties: []
            }
        },

        async fetch() {
            
            const { store } = this.$nuxt.context

            this.properties = await store.dispatch('profile/fetchLandlordPropertiesRelationship')
        },

        methods: {
            redirectToProperty(property) {
                
                this.$router.push(`/property/${property['_jv'].id}`)
            },
            
            tenantFormat(tenants) {
                const tenantCount = tenants.length

                if (tenantCount === 0) {
                    return this.$t('no_tenant')
                }

                const translationKey = tenantCount === 1 ? 'nb_tenant' : 'nb_tenants'

                return this.$t(translationKey, { nb: tenantCount })
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "current_property_rental": "Lot(s) en gestion",
            "no_rental_property": "Aucun lot en gestion en cours.",
            "no_tenant": "Vacant",
            "nb_tenants": "{nb} locataires",
            "nb_tenant": "{nb} locataire"
        }
    }
</i18n>

<style lang="scss" scoped>

    $tenant: #BED000;

    .landlord-property-list {
        .property-list-container {
            width: 100%;
            padding: 0;
            border-radius: 6px;
        
            .property-list-content {
                padding: 0px 0px 10px 0px;
                margin-bottom: 10px;
                border-bottom: 1px solid #F2F2F2;

                &::v-deep .tag {
                    margin-top: 4px;
                }

                &:last-child {
                    margin-bottom: 0;
                    border-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
</style>
