export default class Workflow {

    userId = null;
    hasPreview = false;
    model = null;
    isInsert = true;
    title = '';
    subTitle = '';
    dumpName = '';
    urlBackDump = '';
    steps = [];
    indexOpened = 0;
    canUploadExternalFile = false;

    changeSteps(steps) {
        const actualStep = structuredClone(this.steps);
        this.steps = structuredClone(steps);

        this.steps.forEach((step, index) => {
            const actualStepExist = actualStep.find((_step) => _step.ref === step.ref);

            if (!actualStepExist) {
                return;
            }

            step.subSection.forEach((subSection) => {
                const actualSubSectionExist = actualStepExist.subSection.find((_subSection) => _subSection.name === subSection.name);
                if (!actualSubSectionExist) {
                    return;
                }
                this.setValidStep(index, actualSubSectionExist.name, actualSubSectionExist.isValid);
            });
            this.setFinishedStep(index, actualStepExist.isFinished);

        });
    }

    setValidStep(index, name, isValid) {
        const subSection = this.steps[index].subSection.find((subSection) => subSection.name === name);
        if (!subSection) {
            return;
        }
        subSection.isValid = isValid;
    }

    setFinishedStep(index, isFinished) {

        this.steps[index].isFinished = isFinished;
        if (isFinished) {
            const nextStep = this.getFirstStepNotFinished();

            this.setStepClose(index);
            if (nextStep > -1) {
                this.setStepOpen(nextStep);
            }
        }
    }

    getFinishedStep(index) {
        return this.steps[index]?.isFinished;
    }

    isStepOpened(index) {
        return this.steps[index]?.isOpened;
    }

    setStepOpen(index) {
        if (!this.steps[index]) {
            return;
        }
        this.steps[index].isOpened = true;
        this.indexOpened = index;
    }

    setStepClose(index) {
        this.steps[index].isOpened = false;
    }

    toggleStepOpen(index) {
        const isOpened = !this.steps[index].isOpened;
        if (isOpened) {
            this.setStepOpen(index);
            return;
        }
        this.setStepClose(index);
    }

    setFinished() {
        this.steps.forEach((step) => {
            step.isFinished = true;
            step.subSection.forEach((subSection) => {
                subSection.isValid = true;
            })
        });
    }

    setStepWithError(index, field) {
        this.setFinishedStep(index, false);
        this.setValidStep(index, field, false);
        this.setStepOpen(index);

    }

    getFirstStepNotFinished() {
        return this.steps.findIndex((step) => !step.isFinished)
    }

    setIsStepHidden(index, isHidden) {
        this.steps[index].isHidden = isHidden;
    }

    getIsStepHidden(index) {
        return this.steps[index].isHidden;
    }
}
