<template>

    <div class="property-layout">

        <smart-navigation-bar v-if="isManager" />

        <landlord-navigation-bar v-else />

        <property-header />

        <error-banner :title="$t('error_message')" :details="$t('contact_support')" v-if="errorMessage" />

        <div class="property-container">

            <div class="property-content">

                <nuxt-child />

            </div>

        </div>

    </div>

</template>

<script>
    import LandlordNavigationBar from '~/components/navigation/header/landlord-navigation-bar'
    import PropertyHeader from '~/components/navigation/header/property-header'
    import SmartNavigationBar from '~/components/navigation/header/smart-navigation-bar'
    import PropertyContractsWidget from '~/components/widget/property-contracts-widget'
    import PropertyActionButtonsWidget from '~/components/widget/property-action-buttons-widget'
    import TenantList from '~/components/widget/tenant-list'
    import ErrorBanner from '~/components/error-banner'
    import { mapGetters } from 'vuex'

    export default {
        name: 'PropertyLayout',

        components: {
            PropertyHeader,
            SmartNavigationBar,
            LandlordNavigationBar,
            PropertyContractsWidget,
            PropertyActionButtonsWidget,
            TenantList,
            ErrorBanner
        },

        data() {
            return {
                errorMessage: ''
            }
        },

        computed: {
            ...mapGetters({
                isManager: 'isManager'
            })
        },

        errorCaptured(error) {

            if (!!error.response && !!error.response.status && parseInt(error.response.status) >= 500) {

                this.errorMessage = error
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "error_message": "Une erreur est survenue",
            "contact_support": "Contactez le support en envoyant un e-mail à support@oskar.immo si le problème persiste."
        }
    }
</i18n>

<style lang="scss" scoped>
    $width: 1180px;
    $background: #EFEFEF;

    .property-layout {
        min-height: 100vh;
        background: $background;

        .property-container {
            max-width: $width;
            margin-left: auto;
            margin-right: auto;

            .property-content {
                padding: 45px 0;
            }
        }
    }
</style>
