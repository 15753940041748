import Vue from 'vue'

let captureErrorOnSentry = ({ store, $sentry }, error) => {
    $sentry.configureScope(function(scope) {
        let user = store.getters['getAuthUser']

        if (Object.keys(user).length) scope.setUser({
            id: user['_jv'].id,
            username: typeof user.full_name !== 'undefined' ? user.full_name : '',
            email: user.email_address
        })
    })

    return $sentry.captureException(error)
}

let showSentryReportDialog = ({ $sentry }, eventId) => {

    if (process.browser) $sentry.showReportDialog({ eventId: eventId }) // Show sentry bug report dialog if in client-side
}

export default (ctx) => {

    Vue.config.errorHandler = (err, vm, info) => {

        console.error("Logged in Vue global error handler", err, vm, info)

        captureErrorOnSentry(ctx, err)

        let errorResponse = err.response

        if (errorResponse && errorResponse.status === 401) {
            ctx.redirect('/auth/logout')
        }
    }

    window.onunhandledrejection = (event) => {

        console.error("Logged in window.onunhandledrejection", event)

        captureErrorOnSentry(ctx, event.reason)
    }
}
