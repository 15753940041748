<template>

    <div :class="['supplier-list-table-cell', { 'empty': !Object.keys(supplier).length }]" >

        <svg-icon class="icon" iconColor="#578DFF" width="18" height="18"><supplier-icon /></svg-icon>

        <div class="supplier" @click.stop="showSupplierSidebar">

            {{ getProfileFullName(supplier.attributes) }}

            <supplier-sidebar ref="SupplierSidebar" :supplierId="supplier.id" :supplierType="supplier.type"/>

        </div>

    </div>

</template>

<script>
    import FullNameMixin from '@/components/mixins/full-name'
    import SvgIcon from '@/components/svg-icon.vue'
    import SupplierIcon from '../../icons/table-cell-icons/supplier-icon.vue'
    import SpinnerLoader from '@/components/spinner-loader.vue'
    import SupplierSidebar from '@/components/sidebar/supplier-sidebar.vue'

    export default {
        mixins: [FullNameMixin],

        components: {
            SpinnerLoader,
            SupplierSidebar,
            SvgIcon,
            SupplierIcon
        },

        props: {
            supplier: {
                type: Object,
            },
        },

        methods: {
            showSupplierSidebar() {

                this.$nextTick(() => {

                    if (typeof this.$refs.SupplierSidebar !== 'undefined' && typeof this.$refs.SupplierSidebar.show !== 'undefined') {

                        this.$refs.SupplierSidebar.show()
                    }
                })
            }
        }
    }
</script>

<style lang="scss">
    $supplier: #578DFF;

    .supplier-list-table-cell {
        display: flex;

        &.empty {
            opacity: .5;
        }

        .supplier {
            display: flex;
            color: $supplier;
            text-decoration-color: $supplier;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .icon {
            margin-right: 7px;
            flex-grow: 0;
            flex-shrink: 0;
        }
    }
</style>
