<template>

    <div class="select-costs-related-to-payout">

        <h3 class="group-title">{{ $t('wire_profile', {profile: getLegalPersonsFullName(profileLegalPersons)}) }}</h3>

        <form-info class="error" :text="$t('no_account')" type="error" v-if="profilePayoutBankAccount.length === 0"/>

        <step-group>

            <amount-input name="choose_amount"
                          :label="$t('choose_amount')"
                          v-model="payoutAmount"
                          :options="{ metric: '€' }"
                          :rules="`required|min_value:0.01`"
                          @input="$emit('handlerPayoutData', $event, getSelectedRows())"
            />

            <div class="balance-group">

                <div class="balance">

                    {{ $t('balance') }}

                    <status-label class="amount" :label="$n(balance / 100, 'currency')"
                                  :type="balance >= 0 ? 'success' : 'error'"/>

                </div>

                <div class="balance" v-if="reserveAmount">

                    {{ $t('balance_after') }}

                    <status-label class="amount" :label="$n(balanceAfter / 100, 'currency')"
                                  :type="balanceAfter >= 0 ? 'success' : 'error'"/>

                </div>

            </div>

        </step-group>

        <table-cost-payout
            class="table"
            :tHead="headers"
            :tBodies="rows"
            :loading="loading"
            :options="recentTransactionsOptions"
            @handlerChangeSelectedCost="handlerChangeSelectedCost"
        />

    </div>

</template>

<script>
import AmountInput from '~/components/inputs/amount-input'
import TableList from '~/components/table-list'
import StatusLabel from '~/components/table-list/table-cell/status-label'
import FullNameMixin from '~/components/mixins/full-name'
import FormInfo from '~/components/form-info'
import {mapGetters} from 'vuex'
import TableCostPayout from "@/components/table-list/table-cost-payout.vue";

export default {

    name: 'SelectCostsToReserveFromPayout',

    mixins: [FullNameMixin],

    components: {
        TableCostPayout,
        AmountInput,
        TableList,
        StatusLabel,
        FormInfo
    },

    props: {
        costs        : {
            type    : Array,
            required: true
        },
        loading      : {
            type   : Boolean,
            default: false
        },
        reserveAmount: {
            type   : Boolean,
            default: false
        }
    },

    data() {
        return {
            rows        : [],
            payoutAmount: 0
        }
    },

    fetch() {
        this.rows = this.costs.map(cost => {
            if (cost['type'] &&
                cost['type'] === "fee-notes") {
                return this.mapFeeNote(cost);
            }
            return this.mapCost(cost);
        });
        this.payoutAmount = this.getPayoutAmount();
        this.$emit('handlerPayoutData', this.payoutAmount, this.getSelectedRows());
    },


    methods: {
        handlerChangeSelectedCost(event) {
            const payoutAmount = this.getPayoutAmount();
            if (payoutAmount < 0) {
                this.$toast.error(this.$t('payout.error.amount_sup_balance'), {icon: 'warning'});
                event.is_selected = false;
            }

            this.payoutAmount = this.getPayoutAmount();
            this.$emit('handlerPayoutData', this.payoutAmount, this.getSelectedRows());
        },
        statusCostType(cost) {
            if (cost.payout_date && cost.payout_reserved_at) return 'paid'

            else if (!cost.payout_date && cost.payout_reserved_at) return 'reserved'

            return 'unpaid'
        },


        mapFeeNote(feeNote) {
            const attributes                = feeNote['attributes'],
                  propertyManagementCompany = feeNote.included.find(include => include['type'] === 'property-management-companies');

            return {
                item           : {
                    label      : this.$t('fee_note.text'),
                    description: attributes['title'],
                    icon       : 'diagnose',
                    status     : {
                        type : attributes['status'],
                        label: attributes['status_text']
                    }
                },
                supplier       : {
                    id  : propertyManagementCompany.id,
                    type: propertyManagementCompany.type,
                },
                id             : feeNote.id,
                is_selected    : attributes['provisioned_at'] !== undefined,
                is_disabled    : attributes['provisioned_at'] !== undefined,
                amount_with_tax: attributes.amount_with_tax,
                isFeeNote      : true,
                period         : this.$moment(attributes['released_at']).format('MMMM YYYY'),
                total_amount   : {
                    label: this.$n(attributes.amount_with_tax / 100, 'currency'),
                    type : attributes.amount_with_tax >= 0 ? 'success' : 'error'
                }
            }
        },

        mapCost(cost) {
            return {
                item           : {
                    label      : this.$t('invoice_number', {number: cost.invoice_number}),
                    description: this.$moment(cost.date).format('DD/MM/YYYY'),
                    icon       : 'diagnose',
                    status     : {
                        type : this.statusCostType(cost),
                        label: this.$t(this.statusCostType(cost))
                    }
                },
                supplier       : {
                    id  : cost['_jv']['relationships']['supplier']['data'].id,
                    type: cost['_jv']['relationships']['supplier']['data'].type,
                },
                id             : cost['_jv']['id'],
                is_selected    : false,
                is_disabled    : false,
                isFeeNote      : false,
                amount_with_tax: cost['total_amount'],
                period         : this.$moment(cost.date).format('MMMM YYYY'),
                total_amount   : {
                    label: this.$n(cost.total_amount / 100, 'currency'),
                    type : cost.total_amount >= 0 ? 'success' : 'error'
                }
            }
        },
        getSelectedRows() {
            return this.rows.filter((row) => row.is_selected);
        },

        getPayoutAmount() {
            return this.balance - this.getSelectedRows().map(entry => entry.amount_with_tax).reduce((a, b) => a + b, 0);

        }
    },

    computed: {

        balanceAfter() {
            return this.balance -
                this.payoutAmount -
                this.getSelectedRows().map(entry => entry.amount_with_tax).reduce((a, b) => a + b, 0)
        },

        headers() {
            return [
                {
                    field   : "item",
                    sortable: true,
                    type    : "document-item",
                    width   : 1.5
                },
                {
                    field   : "supplier",
                    sortable: true,
                    type    : "supplier",
                },
                {
                    field   : "total_amount",
                    type    : "status",
                    position: 'right'
                }
            ]
        },

        recentTransactionsOptions() {
            return {
                groupBy               : 'period',
                selectEntries         : true,
                tableHeaderCustomStyle: {
                    border: 0
                },
                tableCellCustomStyle  : {
                    'height'    : '80px',
                    'padding'   : '8px',
                    'border-top': 0
                }
            }
        },


        ...mapGetters({
                          balance                 : 'accounting-account/getBalance',
                          profilePayoutBankAccount: 'profile/getRelatedDefaultPayoutBankAccount',
                          profileLegalPersons     : 'profile/getLegalPersons'
                      })
    }
}
</script>

<i18n>
{
    "fr": {
        "wire_profile": "Virement à {profile}",
        "choose_amount": "Choisir le montant du virement",
        "balance": "Solde actuel",
        "balance_after": "Solde après virement",
        "label": "Dépenses récentes",
        "date": "Date",
        "amount": "Montant",
        "invoice_number": "Fact n°{number}",
        "paid": "Réglé",
        "unpaid": "À régler",
        "no_account": "Enregistrez les coordonnées bancaires du fournisseur avant de valider le virement sortant."
    }
}
</i18n>

<style lang="scss" scoped>
.balance-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 28px;

    .balance {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include body;
        font-size: 9.5pt;
        text-transform: uppercase;
        font-weight: 600;
        color: #8D939F;

        .amount {
            margin-left: 12px;
            font-size: 12pt;
            font-weight: 600;
        }
    }
}

.group-title {
    font-size: 24px;
    color: #333333;
    margin-bottom: 24px;
}

.error {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
</style>
