<template>

    <form-group :class="['number-input', { 'disabled': disabled }]" :label="label" :name="name" :rules="!disabled ? rules : ''" v-slot="{ id, errors, reset }" ref="formGroup">

        <div :class="['input', { 'with-metric': options.metric }]">
            
            <input :class="[{ 'errors': errors.length }]" type="number" :step="step" :id="id" :name="name" v-model.number="numberValue" :disabled="disabled" :min="min" inputmode="decimal" lang="fr" ref="input" @wheel.prevent/>
            
            <div class="trailing" v-if="options.metric || nullable">

                <span class="metric" v-if="options.metric">{{ options.metric }}</span>

                <button class="clear-button" type="button" v-if="nullable" @click="clearInput">
                    <svg-icon class="icon" width="20" height="20" viewbox="0 0 32 32"><close-icon /></svg-icon>
                </button>


            </div>

        </div>
                        
    </form-group>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'
    import CloseIcon from '~/components/icons/button-icons/close-icon'
    import SvgIcon from '~/components/svg-icon'

    export default {
        components: { 
            FormGroup,
            CloseIcon,
            SvgIcon
        },
        
        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            min: {
                type: Number,
                required: false
            },
            value: {
                type: Number,
                required: true
            },
            rules: {
                type: [String, Object],
                default: ''
            },
            options: {
                type: Object,
                required: false,
                default() {
                    return new Object
                }
            },
            disabled: {
                type: Boolean,
                default: false
            },
            nullable: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                numberValue: null
            }
        },

        methods: {
            round(x) {
                const decimal = 1 / this.step

                return Math.round((x + Number.EPSILON) * decimal) / decimal
            },

            trucateDecimal(x) {
                return parseFloat(x.toFixed(this.decimal))
            },

            clearInput() {
                this.numberValue = ''
            },

            handleValueChange(value) {
                if (this.nullable && [null, undefined, ''].includes(value)) {
                    this.numberValue = ''
                    return
                }

                this.numberValue = this.round(value * this.step * (10 ** this.decimal))
            }
        },
        
        computed: {
            step() {
                return typeof this.options.step !== 'undefined' ? this.options.step : 1
            },

            decimal() {
                return typeof this.options.decimal !== 'undefined' ? this.options.decimal : 0
            }
        },

        watch: {
            numberValue: {
                immediate: true,
                handler(newValue) {  
                    if (!isNaN(newValue)) {

                        if (this.nullable && newValue === '') {
                            this.$emit('input', undefined)
                            return
                        }

                        let res = newValue / this.step / (10 ** this.decimal)

                        res = this.trucateDecimal(res)

                        res = this.round(res)

                        this.$emit('input', res)

                    }
                }
            },
            
            value: {
                immediate: true,
                handler(newValue, oldValue) {
                    if (newValue === oldValue) return

                    this.handleValueChange(newValue)
                }
            },

            options: {
                handler() {
                    this.handleValueChange(this.value)
                }
            }
        }
    }
</script>

<style lang="scss">
    $input-min-width: 300px;
    $input-height: 50px;
    $border-color: #E4E4E4;

    .number-input {
        .input {
            position: relative;

            input[type='number'] {
                width: 100%;
                height: $input-height;
                border: 1px solid $border-color;
                border-radius: 4px;
                padding-left: 12px;
                font-size: 11pt;
                box-sizing: border-box;

                &.errors {
                    background-color: #FBE8E8;
                    border-color: #F5A623;
                }
            }

            .trailing{
                position: absolute;
                right: 25px;
                top: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                gap: 8px;
                .metric {
                    color: #000;
                    font-size: 11pt;
                }

                .clear-button{
                    border: none;
                    background: transparent;
                    padding: 0;
                    height: 20px;
                    .icon {
                        cursor: pointer;

                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                }
            }

        }

        &.disabled {
            opacity: 0.5;
        }
    }
</style>
