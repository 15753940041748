export default () => {
    return {
        address         : {},
        reference       : '',
        area            : 0,
        area_tension    : 0,
        case_number     : '',
        description     : '',
        dwelling_type   : '',
        id              : '',
        count_main_rooms: 0,
        fiscal_reference_number: '',
        user_privileges : [],
        dpe             : {
            consumption    : null,
            emission       : null,
            letter         : '',
            delivery_date  : null,
            expiration_date: null
        },
        relationships   : {
            applied_fees      : [],
            accounting_account: {},
            diagnoses         : [],
            fees              : [],
            landlord          : {},
            past_rentals      : [],
            rentals           : [],
            mandates          : [],
            tenants           : []
        }
    }
}
