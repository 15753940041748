import { mapGetters } from 'vuex'

export default {
    methods: {
        /**
         * [nbPiecesPrincipales description]
         * @return {[type]} [description]
         */
        getMainLivingAreasCount(property) {
            return property.count_main_rooms;
        },

        /**
         * @return {String}
         * @param property
         */
        getPropertyNatureShorthand(property) {
            const dwellingType = this.$t(`mixins.property-shorthand.${property.dwelling_type}`),
                mainLivingAreasCount = this.getMainLivingAreasCount(property),
                roomInfo = property.dwelling_type === 'flat' || property.dwelling_type === 'house' ?
                mainLivingAreasCount > 1 ? this.$t('mixins.property-shorthand.rooms', { nb: mainLivingAreasCount }) : this.$t('mixins.property-shorthand.studio')
                : '',
                area = this.$t('mixins.property-shorthand.area', { area: (property.area / 100).toLocaleString() }),
                reference = this.$t('mixins.property-shorthand.reference', { reference: property.reference }),
                caseNumber = typeof property.case_number !== 'undefined' ? this.$t('mixins.property-shorthand.case_number', { caseNumber: property.case_number }) : '';


            return `${dwellingType} ${roomInfo ? `  •  ${roomInfo}` : ''}  •  ${area}  •  ${reference} ${typeof property.case_number !== 'undefined' ? `  • ${caseNumber}` : ''}`;
        }
    },
}
