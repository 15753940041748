<template>

    <div class="viewer-layout">

        <viewer-navigation-bar-header
            @redirect="redirect"
            :title="title"
            :subTitle="subTitle"
        />

        <section class="content">

            <nuxt-child
                ref="viewer"
                @setTitle="getTitle"
                @setSubTitle="getSubTitle"
            />

        </section>

    </div>

</template>

<script>
import DocumentViewer from '~/components/document-viewer'
import ViewerNavigationBarHeader from '@/components/v2/navigation/header/viewer-navigation-bar-header.vue'
import ButtonGroupFooter from "@/components/navigation/footer/button-group-footer.vue";
import OnboardingUserAgent from "@/components/onboarding-user-agent.vue";
import ButtonInput from "@/components/inputs/button-input.vue";
import ShareToSignModal from "@/components/modal/share-to-sign.vue";

export default {

    components: {
        ShareToSignModal,
        ButtonInput,
        OnboardingUserAgent,
        ButtonGroupFooter,
        DocumentViewer,
        ViewerNavigationBarHeader,
    },
    data() {
        return {
            title   : '',
            subTitle: ''
        }
    },
    methods: {
        getTitle(value) {
            this.title = value;
        },
        getSubTitle(value) {
            this.subTitle = value;
        },
        redirect() {
            console.log(this.$refs.viewer.getUrlBack());
            return this.$router.push(this.$refs.viewer.getUrlBack());
        },
    },


}
</script>

<style lang="scss" scoped>
$background-color: #393939;
$button-group-margin: 60px;
$button-group-height: 68px;
$viewer-width: 983px;
$viewer-margin-top: 44px;
$header-height: 68px;

.viewer-layout {
    background: $background-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    margin-bottom: $button-group-margin;

    .content {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-top: $viewer-margin-top;

        .reading-warning {
            color: white;
            justify-content: center;
            text-align: center;
            display: flex;
            font-size: 18px;
            margin-bottom: 36px;
        }

        #hellosign-viewer {
            width: 983px;
            height: calc(100vh - #{$viewer-margin-top} - #{$header-height});
            margin: 0 auto;
            border-radius: 4px;
            overflow: hidden;
            flex-grow: 1;
        }
    }
}
</style>
