<template>

    <svg-icon :class="['arrow-icon', { 'active': active }]" :iconColor="color" :width="`${height}px`" :height="`${width}px`" viewbox="0 0 24 24">
        
        <arrow-icon />
        
    </svg-icon>

</template>

<script>
    import SvgIcon from '~/components/svg-icon'
    import ArrowIcon from '~/components/v2/icons/arrow-icon'
    
    export default {
        components: {
            SvgIcon,
            ArrowIcon
        },

        props: {
            active: {
                type: Boolean,
                default: false
            },
            width: {
                type: Number,
                default: 18,
            },
            height: {
                type: Number,
                default: 12
            },
            color: {
                type: String,
                default: '#000000'
            }
        }
    }
</script>

<style lang="scss" scoped>
    .arrow-icon {
        transition: transform .2s ease-in-out;

        &.active {
            transform: rotate(180deg);
        }
    }
</style>