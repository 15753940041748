import Vue from 'vue'
import ButtonInput from '~/components/inputs/button-input'
import TextInput from '~/components/inputs/text-input'
import RadioInput from '~/components/inputs/radio-input'
import SelectBoxInput from '~/components/inputs/select-box-input'
import NumberInput from '~/components/inputs/number-input'
import TextAreaInput from '~/components/inputs/text-area-input'
import CounterInput from '~/components/inputs/counter-input'
import CheckBoxInput from '~/components/inputs/check-box-input'
import SearchInput from '~/components/inputs/search-input'
import DateInput from '~/components/inputs/date-input'
import DatePeriodInput from '~/components/inputs/date-period-input'
import FileUploadInput from '~/components/inputs/file-upload-input'
import AmountInput from '~/components/inputs/amount-input'
import PhoneNumberInput from '~/components/inputs/phone-number-input'
import ReadonlyInput from '~/components/inputs/readonly-input'
import PasswordInput from '~/components/inputs/password-input'
import IbanInput from '~/components/inputs/iban-input'
import FormInput from '~/components/inputs/form-input'
import RangeInput from '~/components/inputs/range-input'

const components = {
    ButtonInput,
    TextInput,
    RadioInput,
    DateInput,
    DatePeriodInput,
    SelectBoxInput,
    NumberInput,
    TextAreaInput,
    CounterInput,
    CheckBoxInput,
    SearchInput,
    FileUploadInput,
    AmountInput,
    PhoneNumberInput,
    ReadonlyInput,
    PasswordInput,
    IbanInput,
    FormInput,
    RangeInput
}

Object.entries(components).forEach(([name, component]) => {

    Vue.component(name, component)
})
