<template>

    <div class="update-layout">

        <new-functionality-navigation-bar-header :title="$t('oskar_new_functionalities')" :subtitle="userName" />

        <section class="layout-row content">

            <div class="layout-col steps">

                <nuxt class="container" ref="page" />

            </div>

        </section>

        <div class="footer">
            <div class="legal-mention">
                <p>© 2024 I Tous droits réservés | Contacter le support</p>
                <logo/>
            </div>
        </div>

    </div>

</template>

<script>
    import NewFunctionalityNavigationBarHeader from '~/components/navigation/header/new-functionality-navigation-bar-header.vue'
    import Logo from '~/components/logo'
    import { mapGetters } from 'vuex'

    export default {

        components: {
            NewFunctionalityNavigationBarHeader,
            Logo,
        },

        computed: {
            ...mapGetters({
                userName: 'user/getFullName',
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "oskar_new_functionalities": "Notes de mise à jour d’Oskar"
        }
    }
</i18n>

<style lang="scss" scoped>
    $background: #EFEFEF;
    $button-group-height: 68px;
    $button-group-margin: 60px;
    $color-black: #0E0A07;

    .update-layout {
        background: $background;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
        align-items: center;

        .content {
            display: flex;
            flex-direction: row;
            flex: 1;
            margin-top: 40px;
            margin-bottom: 40px;
            padding-left: 30px;
            padding-right: 30px;

            .sidebar {
                max-width: 240px;
                flex-shrink: 1;
            }

            .steps {
                align-items: center;

                &::v-deep .step-form {
                    display: block;
                    box-shadow: 0 3px 18px 0 #E0E6F6;
                    border-radius: 10px;
                    background: white;
                    color: $color-black;
                    margin-bottom: 10px;

                    &.active {
                        margin-bottom: 35px;
                        margin-top: 25px;

                        .step-content {
                            padding: 80px;
                            padding-top: 56px;

                            .form-active {
                                transform-origin: top;
                                display: flex;
                                flex-direction: column;
                            }

                            .header {
                                margin-bottom: 34px;
                            }
                        }
                    }

                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:not(.active) {
                        box-shadow: 0 3px 18px 0 #E0E6F6, 0 3px 0 0 #DADCF0;
                    }

                    .step-content {
                        display: flex;
                        flex-direction: column;
                        padding: 40px 80px;

                        .form-active {
                            transform-origin: top;
                        }

                        .header {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .title-group {
                                display: flex;
                                align-items: center;
                                flex: 1;

                                .title {
                                    font-size: 24px;
                                    cursor: pointer;
                                }

                                .status-tag {
                                    margin-left: 13px;
                                }
                            }
                        }
                    }

                    .button-group {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        border-top: 1px solid #F2F2F2;
                        padding: 40px 80px;
                        margin-top: -32px;

                        .button.next,
                        .button.save {
                            padding-left: 60px;
                            padding-right: 60px;
                        }
                    }
                }
            }
        }

        .footer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 32px 0;
            border-top: 1px solid #E4E4E4;
            width: 100%;

            .legal-mention {
                display: flex;
                width: 1180px;
                max-width: 1180px;
                justify-content: space-between;
                align-items: center;

                p {
                    color: #767676;

                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                }

                .logo-section {
                    display: flex;
                    width: 96px;
                    height: 40px;
                    padding: 8px 1px 7.797px 1px;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;

                    &::v-deep .logo {
                        width: 94px !important;
                        height: 24.203px !important;
                        flex-shrink: 0;
                    }
                }
            }
        }
    }
</style>
