<template>

    <modal class="settle-landlord-account-modal preview" :title="titleFormat" :options="modalOptions" @canceled="hide"
           @close="hide" ref="modal">

        <spinner-loader v-if="loading"/>

        <template v-else>

            <select-costs-related-to-payout
                :loading="loading"
                :reserveAmount="true"
                :costs="profileCosts"
                @handlerPayoutData="handlerPayoutData"
                v-if="step === 0"
            />

            <select-property-management-company-bank-account v-else-if="step === 1"/>

            <select-beneficiary-payout-bank-account v-else-if="step === 2"/>

            <payout-consent-result :payouts="[newPayout]" :isConfirmedWire="isConfirmedWire"
                                   :sendNotificationToReceiver="landlord" v-else-if="step === 3"/>

        </template>

    </modal>

</template>

<script>
import Modal from '~/components/modal'
import SelectCostsRelatedToPayout from '~/components/modal/steps/select-costs-related-to-payout'
import SelectPropertyManagementCompanyBankAccount
    from '~/components/modal/steps/select-property-management-company-bank-account'
import SelectBeneficiaryPayoutBankAccount from '~/components/modal/steps/select-beneficiary-payout-bank-account'
import PayoutConsentResult from '~/components/modal/steps/payout-consent-result'
import SpinnerLoader from '~/components/spinner-loader'
import FullNameMixin from '~/components/mixins/full-name'
import {mapGetters} from 'vuex'
import FeeNoteApi from "@/api/FeeNote";

export default {

    mixins: [FullNameMixin],

    components: {
        Modal,
        SelectPropertyManagementCompanyBankAccount,
        SelectBeneficiaryPayoutBankAccount,
        PayoutConsentResult,
        SelectCostsRelatedToPayout,
        SpinnerLoader
    },

    data() {
        return {
            ExpenseSelected: [],
            payoutAmount   : 0,
            fullscreen     : false,
            loading        : false,
            step           : 0,
            newPayout      : {},
            isConfirmedWire: false,
            profileCosts   : [],
            landlord       : {}
        }
    },

    async fetch() {
        try {

            if (typeof this.$route.query.payout_id !== 'undefined') {

                this.newPayout = await this.$store.dispatch('payout/fetchPayout', this.$route.query.payout_id)

                await this.$store.dispatch('payout/fetchBankAccountRelationship')

                if(this.newPayout['_jv']['relationships']['management_reports']?.['data']?.id) {

                    await this.$store.dispatch('management-report/fetchManagementReport', this.newPayout['_jv']['relationships']['management_reports']['data'].id)
                }

                this.isConfirmedWire = true

                this.step = 3

                this.show()
            }

            this.landlord = await this.$store.dispatch('profile/fetchLandlord', this.landlordId)

        } catch (error) {

            throw error
        }
    },

    methods: {

        handlerPayoutData(payoutAmount, ExpenseSelected) {
            this.payoutAmount = payoutAmount;
            this.ExpenseSelected = ExpenseSelected;

            this.$store.commit('payout/setRelatedCosts', this.ExpenseSelected);
            this.$store.commit('payout/setAmount', this.payoutAmount);
        },

        async createPayout() {


            this.$store.commit('payout/setInitiatedAt', this.$moment().format('YYYY-MM-DD HH:mm:ss'))

            this.$store.commit('payout/setRedirectUrl', `${window.location.origin}/landlord/${this.landlordId}?payout_id=`)

            this.newPayout = await this.$store.dispatch('payout/createPayout')

            if(this.newPayout['_jv']['relationships']['management_reports']?.['data']?.id) {

                await this.$store.dispatch('management-report/fetchManagementReport', this.newPayout['_jv']['relationships']['management_reports']['data'].id)
            }

            if (this.newPayout['_jv']['meta']['consent_url']) {

                window.location.replace(this.newPayout['_jv']['meta']['consent_url'])
            }


        },

        async show() {
            try {

                this.loading = true

                this.$refs.modal.show()

                await this.$store.dispatch('accounting-account/fetchAndFillAccountingAccount', this.landlordAccountingAccount['_jv'].id)

                const payoutBankAccounts = await this.$store.dispatch('bank-account/fetchLandlordPayoutBankAccounts', this.landlordId)

                this.$store.commit('profile/setRelatedPayoutBankAccounts', payoutBankAccounts.response)

                const {response} = await this.$store.dispatch('cost/fetchLandlordCosts', {
                    filters: {
                        property_id       : `is_empty:false`,
                        payout_date       : `is_empty:true`,
                        payout_reserved_at: `is_empty:true`
                    },
                    sort   : '-date'
                })

                const params = {
                          filter: {
                              paid_at: 'is_empty:true'
                          },
                          sort  : 'provisioned_at'
                      },
                      {data} = await FeeNoteApi.getListByLandlord(this.landlordId, params)

                this.profileCosts = response.concat(data['data']);

            } catch (error) {

                throw error

            } finally {

                this.loading = false
            }
        },

        async nextStep() {
            if (this.step === 2) await this.createPayout()

            if (this.step < 3) this.step += 1

            else this.hide()
        },

        hide() {
            this.$store.commit('payout/resetDefaultState')

            this.$store.commit('accounting-account/resetDefaultState')

            this.$store.commit('bank-account/resetDefaultState')

            this.$store.commit('notification/resetDefaultState')

            this.step = 0

            this.payoutRelatedCosts = []

            this.newPayout = {}

            this.$refs.modal.hide()

            this.$emit('closed')
        }
    },

    computed: {
        titleFormat() {
            let amount = this.$n(this.payoutAmount / 100, 'currency')

            if (this.step === 0) return this.$t('settle_landlord', {landlord: this.getLegalPersonsFullName(this.landlordLegalPersons)})

            else return this.$t('transfer_landlord', {
                landlord: this.getLegalPersonsFullName(this.landlordLegalPersons),
                amount  : amount
            })
        },

        disableButtonFormat() {
            switch (this.step) {

                case 1 :
                    return this.isBankAccountNotAllowed

                case 2 :
                    return !Object.keys(this.payoutBeneficiary).length

                case 3 :
                    return false

                default:
                    return this.payoutAmount > this.balance
            }
        },

        buttonTextFormat() {
            switch (this.step) {

                case 1:
                    return this.$t('confirm_bank_account')

                case 2:
                    return this.$t('confirm_payout_bank_account')

                case 3:
                    return this.$t('back_to_landlord')

                default:
                    return this.$t('validate')
            }
        },

        modalOptions() {
            return {
                largeOption: {
                    mode    : 'expanded',
                    subtitle: this.$t('settle_account_subtitle'),
                    icon    : 'settle-account'
                },
                submit     : {
                    disableButton: this.disableButtonFormat,
                    buttonText   : this.buttonTextFormat,
                    action       : this.nextStep
                }
            }
        },

        isBankAccountNotAllowed() {
                if (!Object.keys(this.payoutRelatedBankAccount).length) return true

            else if (this.payoutRelatedBankAccount.bank_name === 'Swan' && this.payoutRelatedBankAccount.balance < this.payoutAmount) return true

            return false
        },

        ...mapGetters({
                          payoutRelatedBankAccount : 'payout/getRelatedBankAccount',
                          payoutBeneficiary        : 'payout/getRelatedBeneficiary',
                          balance                  : 'accounting-account/getBalance',
                          landlordId               : 'profile/getId',
                          landlordLegalPersons     : 'profile/getLegalPersons',
                          landlordAccountingAccount: 'profile/getRelatedAccountingAccount',
                      })
    }
}
</script>

<i18n>
{
    "fr": {
        "amount": "Montant",
        "back_to_landlord": "Retour à la fiche du propriétaire",
        "confirm_payout_bank_account": "Confirmer le compte bancaire bénéficiaire",
        "settle_landlord": "Solder le compte de {landlord}",
        "transfer_landlord": "Faire un virement de {amount} à {landlord}",
        "confirm_bank_account": "Confirmer le compte bancaire émetteur",
        "validate": "Confirmer le montant",
        "settle_account_subtitle": "Choisir le montant pour effectuer un virement au propriétaire",
        "balance": "Solde actuel"
    }
}
</i18n>

<style lang="scss" scoped>
.preview {
    z-index: 19 !important;
}
</style>
