import { queryBuilder, queryResponse } from '~/utils/query'
import { utils } from 'jsonapi-vuex';

export default {
    async createMandate({ dispatch, rootGetters }) {
        try {

            let request = await dispatch('formatMandateRequest')

            const mandate = await dispatch('tyltoApi/post', [request, { url: 'properties/' + rootGetters['property/getId'] + '/mandates' }], { root: true })

            await dispatch('fillMandate', mandate)

            return mandate

        } catch(error) {

            throw error
        }
    },

    async createDocumentRelationship({ getters, commit, dispatch, rootGetters }, documentFields) {
        try {

            let request = {
                ...documentFields,
                _jv: {
                    type: 'files'
                }
            }

            const document = await dispatch('tyltoApi/post', [request, { url: 'mandates/' + getters['getId'] + '/document' }], { root: true })

            commit('setRelatedDocument', document)

            return document

        } catch(error) {

            throw error

        } finally {

            commit('file/resetDefaultState', null, { root: true })
        }
    },

    async deleteMandate({ dispatch, getters }, id) {
        try {

            let mandateId = typeof id !== 'undefined' ? id : getters['getId']

            return await dispatch('tyltoApi/delete', 'mandates/' + mandateId, { root: true })

        } catch(error) {

            throw error
        }
    },

    async fetchMandate({ dispatch, rootGetters }, mandateId) {
        try {
            return  await dispatch('tyltoApi/get', 'mandates/' + mandateId, { root: true });

        } catch(error) {

            throw error
        }
    },

    async fetchAndFillMandate({ dispatch }, mandateId) {
        try {

            let mandate = await dispatch('fetchMandate', mandateId)

            await dispatch('fillMandate', mandate)

            return mandate

        } catch(error) {

            throw error
        }
    },

    async fetchDocumentRelationship({ dispatch, getters, commit }, mandateId) {
        try {

            let mandateDocument = {}

            const id = typeof mandateId !== 'undefined' ? mandateId : getters['getId']

            const { data } = await this.$axios({ method: 'get', url: `mandates/${id}/relationships/document` })

            if (data['data'] !== null) mandateDocument = await dispatch('file/fetchFile', data['data']['id'], { root: true })


            commit('setRelatedDocument', mandateDocument)

            if (typeof mandateDocument.fields !== 'undefined') {

                await dispatch('fillDocumentFields', mandateDocument.fields)
            }

            return mandateDocument

        } catch(error) {

            throw error
        }
    },

    async fetchPropertyRelationship({ dispatch, getters, commit }, mandateId) {
        try {

            let mandateProperty = {}

            if (Object.keys(getters['getRelatedProperty']).length) mandateProperty = getters['getRelatedProperty']

            else {

                const id = getters['getId'] !== '' ? getters['getId'] : mandateId

                const { data } = await this.$axios({ method: 'get', url: `mandates/${id}/relationships/property` })

                if (data['data'] !== null) mandateProperty = await dispatch('property/fetchProperty', data['data']['id'], { root: true })
            }

            commit('setRelatedProperty', mandateProperty)

            return mandateProperty

        } catch(error) {

            throw error
        }
    },

    async fetchAnnexesRelationship({ dispatch, getters, commit }, mandateId) {
        try {

            let mandateAnnexes = []

            if (getters['getRelatedAnnexes'].length) mandateAnnexes = getters['getRelatedAnnexes']

            else {

                const id = typeof mandateId !== undefined ? mandateId : getters['getId']

                const { data } = await this.$axios({ method: 'get', url: `mandates/${id}/relationships/annexes` })

                mandateAnnexes = await Promise.all(data['data'].map(annex => dispatch('file/fetchFile', annex.id, { root: true })))
            }

            commit('setRelatedAnnexes', mandateAnnexes)

            return mandateAnnexes

        } catch(error) {

            throw error
        }
    },

    async fetchPropertyMandates({ dispatch }, { params, page, filters }) {
        try {

            const response = await dispatch('tyltoApi/get', ['properties/' + params.propertyId + '/mandates', { params: queryBuilder({ page, filters }) }], { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fillMandate({ commit, dispatch }, mandate) {
        commit('resetDefaultState')

        if (typeof mandate['end_date'] !== 'undefined')
            commit('setEndDate', mandate['end_date'])

        if (typeof mandate['number'] !== 'undefined')
            commit('setNumber', mandate['number'])

        if (typeof mandate['_jv']['id'] !== 'undefined')
            commit('setId', mandate['_jv']['id'])

        if (typeof mandate['start_date'] !== 'undefined')
            commit('setStartDate', mandate['start_date'])

        if (typeof mandate['term'] !== 'undefined')
            commit('setTerm', mandate['term'])

        if (typeof mandate['document'] !== 'undefined' && Object.keys(mandate['document']).length) {

            commit('setRelatedDocument', mandate['document'])

            if (typeof mandate['document']['fields'] !== 'undefined' && Object.keys(mandate['document']['fields']).length) {
                await dispatch('fillDocumentFields', mandate['document']['fields'])
            }
        }

        if (typeof mandate['property'] !== 'undefined' && Object.keys(mandate['property']).length) {
            commit('setRelatedProperty', mandate['property'])
        }
    },

    async fillDocumentFields({ commit }, fields) {
        commit('resetDefaultFieldsState')

        if (typeof fields['other_specific_clauses'] !== 'undefined')
            commit('setDocumentFieldsOtherSpecificClauses', fields['other_specific_clauses'])

        if (typeof fields['duties'] !== 'undefined')
            commit('setDocumentFieldsDuties', fields['duties'])
    },

    async formatDocumentFields({ getters }) {
        let documentFields = {}

        if (getters['getDocumentFieldsOtherSpecificClauses'].length)
            documentFields['other_specific_clauses'] = getters['getDocumentFieldsOtherSpecificClauses']

        if (getters['getDocumentFieldsDuties'].length)
            documentFields['duties'] = getters['getDocumentFieldsDuties']

        return documentFields
    },

    async formatMandateRequest({ getters }) {
        let mandateRequest = {
            number: getters['getNumber'],
            start_date: getters['getStartDate'],
            term: getters['getTerm'],
            _jv: {
                type: 'mandates',
                relationships: {
                    annexes: []
                }
            }
        }

        if (getters['getEndDate'] !== '') {
            mandateRequest['end_date'] = getters['getEndDate']
        }

        if (getters['getId'] !== '') {
            mandateRequest['_jv'].id = getters['getId']
        }

        if (Object.keys(getters['getRelatedDocument']).length) {
            mandateRequest['_jv']['relationships'].document = {
                data: {
                    type: 'files',
                    id: getters['getRelatedDocument']['_jv'].id
                }
            }
        }

        if (Object.keys(getters['getRelatedProperty']).length) {
            mandateRequest['_jv']['relationships'].property = {
                data: {
                    type: 'properties',
                    id: getters['getRelatedProperty']['_jv'].id
                }
            }
        }

        if (getters['getRelatedAnnexes'].length) {
            mandateRequest['_jv']['relationships'].annexes = {
                data: getters['getRelatedAnnexes'].map(annex => {
                    return {
                        type: 'files',
                        id: annex['_jv'].id
                    }
                })
            }
        }

        return mandateRequest
    },

    async updateDocumentRelationship({ getters, commit }) {
        try {

            let document = {
                data: null
            }

            if (Object.keys(getters['getRelatedDocument']).length) {
                document['data'] = {
                    type: 'files',
                    id: getters['getRelatedDocument']['_jv'].id
                }
            }

            const response = await this.$axios({ method: 'patch', url: 'mandates/' + getters['getId'] + '/relationships/document', data: document })

            commit('tyltoApi/deleteRecord', { _jv: { type: 'mandates', id: getters['getId'] } }, { root: true })

            return response

        } catch(error) {

            throw error
        }
    },

    async updateMandate({ dispatch, getters }) {
        try {

            let request = await dispatch('formatMandateRequest')

            if (!Object.keys(getters['getRelatedDocument']).length || getters['getRelatedDocument'].is_auto_generated) await dispatch('createDocumentRelationship')

            else await dispatch('updateDocumentRelationship')

            const mandate = await this.$axios({ method: 'patch', url: 'mandates/' + getters['getId'], data: utils.normToJsonapi(request) })

            return utils.jsonapiToNorm(mandate.data.data)

        } catch(error) {

            throw error
        }
    }
}
