<template>

    <div :class="['accounting-entries-table-cell', { 'empty': !accountingEntriesIds.length }]">

        <spinner-loader v-if="loading" />

        <span class="accounting-entrie" v-else-if="!accountingEntriesIds.length">{{ $t('no_accounting_entries') }}</span>

        <div class="accounting-entrie" @click.stop="expandAccountingEntries" v-else>

            {{ $t('nb_accounting_entries', { nb: accountingEntriesIds.length }) }}

            <arrow class="arrow" :active="!collapsed" :width="16" :height="16" color="#1F1F1F" />

        </div>

    </div>

</template>

<script>
    import SpinnerLoader from '~/components/spinner-loader'
    import Arrow from '~/components/arrow'

    export default {
        components: {
            SpinnerLoader,
            Arrow
        },

        data() {
            return {
                accountingEntries: [],
                loading: false
            }
        },

        props: {
            accountingEntriesIds: {
                type: Array,
                required: true
            },
            collapsed: {
                type: Boolean,
                default: true
            }
        },

        methods: {
            async expandAccountingEntries() {
                try {

                    this.loading = true

                    this.accountingEntries = []

                    for (let accountingEntryId of this.accountingEntriesIds) {

                        let accountingEntry = await this.$store.dispatch('accounting-entry/fetchAndFillAccountingEntry', accountingEntryId)
                        let bankAccount = await this.$store.dispatch('accounting-account/fetchAccountingAccount', accountingEntry['_jv']['relationships']['accounting_account']['data'].id)
                        this.accountingEntries.push(
                            {
                                ...accountingEntry,
                                bank_account: bankAccount
                            }
                        )
                    }

                    let expandedLines = this.accountingEntries.map(accountingEntry => {
                        return [
                            {
                                type: 'bank-account-transaction',
                                data: {
                                    line1: accountingEntry.description,
                                    line2: this.$t('account_number', { number: accountingEntry.bank_account.number }),
                                    icon: accountingEntry.debit !== undefined ? 'negative' : 'positive',
                                },
                                width: 4
                            },
                            {
                                type: 'string',
                                data: ' ',
                                width: 1.5
                            },
                            {
                                type: 'string',
                                data: ' ',
                                width: 2
                            },
                            {
                                type: 'string',
                                data: ' ',
                                width: 2
                            },
                            {
                                type: 'string',
                                data: ' ',
                                width: 3
                            },
                            {
                                type: 'string',
                                data: this.$moment(accountingEntry.date).format('DD/MM/YYYY'),
                                width: 2,
                                highlight: true
                            },
                            {
                                type: 'status',
                                data: accountingEntry.debit !== undefined ? {
                                    label: this.$n(accountingEntry.debit / 100, 'currency'),
                                    type: 'error'
                                } : {
                                    label: this.$n(accountingEntry.credit / 100, 'currency'),
                                    type: 'success'
                                },
                                width: 1.5,
                                position: 'right',
                            }
                        ]
                    })

                    this.$emit('expandAccountingEntries', expandedLines)

                } catch(error) {

                    throw error

                } finally {

                    this.loading = false
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "nb_accounting_entries": "{nb} écritures",
            "account_number": "#{number}",
            "no_accounting_entries": "Aucune écriture"
        }
    }
</i18n>


<style lang="scss">
    .accounting-entries-table-cell {
        display: flex;

        &.empty {
            opacity: .5;
        }

        .accounting-entrie {
            display: flex;
            align-items: center;
            font-size: 10.5pt;
            font-weight: 400;

            .arrow {
                margin-left: 9px;
            }

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
</style>
