import axios from "axios";

export default class FeeNoteApi {

    static getList(propertyId, params) {
        return axios.get(`/proxy/properties/${propertyId}/fee-notes?relationships=false`, params);
    }


    static getListByLandlord(landlordId, params) {
        return axios.get(`/proxy/landlords/${landlordId}/fee-notes?relationships=false`, {
            params : params
        });
    }

    static getFeeNote(id, params) {
        return axios.get(`/proxy/fee-notes/${id}`, params);
    }

    static postFeeNote(data) {
        return axios.post(`/proxy/fee-notes`, data);
    }

    static patchFeeNote(id, data) {
        return axios.patch(`/proxy/fee-notes/${id}`, data);
    }

    static setProvisionFeeNote(id, data) {
        return axios.post(`/proxy/fee-notes/${id}/provision`, data);
    }


    static deleteFeeNote(id) {
        return axios.delete(`/proxy/fee-notes/${id}`);
    }

}
