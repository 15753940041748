import { queryBuilder, queryResponse } from '~/utils/query'

export default {
    async fetchAccountingEntries({ dispatch, getters }, params) {
        try {

            const response = await dispatch('tyltoApi/get', [`property-management-companies/${getters['getId']}/accounting-entries`, { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch(error) {

            throw error
        }
    },

    async fetchAccountingEntrySets({ dispatch, getters }, params) {
        try {

            const response = await dispatch('tyltoApi/get', [`property-management-companies/${getters['getId']}/accounting-entry-sets`, { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchAndFillPaymentMethodRelationship({ dispatch, getters, commit }) {
        try {

            const response = await dispatch('tyltoApi/get', `property-management-companies/${getters['getId']}/relationships/payment_method`, { root: true })

            let paymentMethod = {}

            if (response['_jv'].id) {

                paymentMethod = await dispatch('payment-method/fetchAndFillPaymentMethod', response['_jv'].id, { root: true })

                let array = []

                array.push(paymentMethod)

                commit('setRelatedPaymentMethod', array)

            } else {

                commit('setRelatedPaymentMethod', {})
            }

            return paymentMethod

        } catch (error) {

            throw error
        }
    },

    async fetchMandates({ dispatch , getters, commit }, params) {
        try {

            const data = await dispatch('tyltoApi/get', ['property-management-companies/' + getters['getId'] + '/mandates', { params: queryBuilder(params) }], { root: true })

            const { response } = queryResponse(data)

            commit('setRelatedMandates', response)

            return queryResponse(data)

        } catch (error) {

            throw error
        }
    },

    async fetchFees({ dispatch , getters, commit }) {
        try {

            const data = await dispatch('tyltoApi/get', 'property-management-companies/' + getters['getId'] + '/fees', { root: true })

            const { response } = queryResponse(data)

            commit('setRelatedFees', response)

            return queryResponse(data)

        } catch (error) {

            throw error
        }
    },

    async fetchPropertyManagementCompany({ rootGetters, dispatch }, propertyManagerId) {
        return  await dispatch('tyltoApi/get', 'property-management-companies/' + propertyManagerId, { root: true });
    },

    async fetchAndFillPropertyManagementCompany({ dispatch }, propertyManagerId) {
        try {

            let propertyManagementCompany = await dispatch('fetchPropertyManagementCompany', propertyManagerId)

            await dispatch('fillPropertyManagementCompany', propertyManagementCompany)

            return propertyManagementCompany

        } catch (error) {

            throw error
        }
    },

    async createPropertyManagementCompany({ dispatch, commit }) {
        try {

            let formatRequest = await dispatch('formatPropertyManagementCompanyRequest')

            const propertyManagementCompany = await dispatch('tyltoApi/post', formatRequest, { root: true })

            await dispatch('fillPropertyManagementCompany', propertyManagementCompany)

            return propertyManagementCompany

        } catch (error) {

            throw error

        } finally {

            commit('file/resetDefaultState', null, { root: true })
        }
    },

    async fetchAccountingAccounts({ dispatch, getters }, params) {
        try {

            const response = await dispatch('tyltoApi/get', [`property-management-companies/${getters['getId']}/accounting-accounts`, { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchAccountingJournals({ dispatch }, params) {
        try {

            const response = await dispatch('tyltoApi/get', [`accounting-journals`, { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchBankAccounts({ dispatch, getters, commit }, params) {
        try {

            const id = params?.propertyManagerId ? params.propertyManagerId : getters['getId']

            const data = await dispatch('tyltoApi/get', ['property-management-companies/' + id + '/bank-accounts', { params: queryBuilder(params) }], { root: true })

            const { response } = queryResponse(data)

            commit('setRelatedBankAccounts', response)

            return queryResponse(data)

        } catch (error) {

            throw error
        }
    },

    async fetchBankAccountTransactions({ dispatch }, { params, filters, page, aggregates, sort }) {
        try {

            const response = await dispatch('tyltoApi/get', [`property-management-companies/${params.propertyManagerId}/bank-account-transactions`, { params: queryBuilder({ filters, page, aggregates, sort }) }], { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchInvoices({ dispatch, getters }, params) {
        try {

            const response = await dispatch('tyltoApi/get', ['property-management-companies/' + getters['getId'] + '/invoices', { params: queryBuilder(params) }], { root: true })

            return queryResponse(response)

        } catch (error) {

            throw error
        }
    },

    async fetchGuaranteeFundRelationship({ dispatch, getters, commit }, propertyManagerId) {
        try {

            let guaranteeFund = {}

            if (Object.keys(getters['getRelatedGuaranteeFund']).length) guaranteeFund = getters['getRelatedGuaranteeFund']

            else {

                const id = getters['getId'] !== '' ? getters['getId'] : propertyManagerId

                const { data } = await this.$axios({ method: 'get', url: `property-management-companies/${id}/relationships/guarantee_fund` })

                if (data['data'] !== null) guaranteeFund = await dispatch('guarantee-fund/fetchGuaranteeFund', data['data']['id'], { root: true })
            }

            commit('setRelatedGuaranteeFund', guaranteeFund)

            return guaranteeFund

        } catch (error) {

            throw error
        }
    },

    async fetchLogoRelationship({ dispatch, getters, commit }, propertyManagerId) {
        try {

            let logoFile = {}

            const id = typeof propertyManagerId !== 'undefined' ? propertyManagerId : getters['getId']

            const { data } = await this.$axios({ method: 'get', url: `property-management-companies/${id}/relationships/logo` })

            if (data['data'] !== null) logoFile = await dispatch('file/fetchFile', data['data']['id'], { root: true })

            commit('setRelatedLogo', logoFile)

            return logoFile

        } catch (error) {

            throw error
        }
    },

    async fetchMediatorRelationship({ dispatch, getters, commit }, propertyManagerId) {
        try {

            let mediatorObject = {}

            if (Object.keys(getters['getRelatedMediator']).length) mediatorObject = getters['getRelatedMediator']

            else {

                const id = getters['getId'] !== '' ? getters['getId'] : propertyManagerId

                const { data } = await this.$axios({ method: 'get', url: `property-management-companies/${id}/relationships/mediator` })

                if (data['data'] !== null) mediatorObject = await dispatch('mediator/fetchMediator', data['data']['id'], { root: true })
            }

            commit('setRelatedMediator', mediatorObject)

            return mediatorObject

        } catch (error) {

            throw error
        }
    },

    async fetchProperties({ dispatch, getters}, params) {
        try {

            const data = await dispatch('tyltoApi/get', ['property-management-companies/' + getters['getId'] + '/properties', { params: queryBuilder(params) }], { root: true })

            return queryResponse(data)

        } catch (error) {

            throw error
        }
    },

    async fetchTenants({ dispatch, getters}, params) {
        try {

            const data = await dispatch('tyltoApi/get', ['property-management-companies/' + getters['getId'] + '/tenants', { params: queryBuilder(params) }], { root: true })

            return queryResponse(data)

        } catch (error) {

            throw error
        }
    },

    async fetchLandlords({ dispatch, getters}, params) {
        try {

            const data = await dispatch('tyltoApi/get', ['property-management-companies/' + getters['getId'] + '/landlords', { params: queryBuilder(params) }], { root: true })

            return queryResponse(data)

        } catch (error) {

            throw error
        }
    },

    async fetchSuppliers({ dispatch, getters}, params) {
        try {

            const data = await dispatch('tyltoApi/get', ['property-management-companies/' + getters['getId'] + '/suppliers', { params: queryBuilder(params) }], { root: true })

            return queryResponse(data)

        } catch (error) {

            throw error
        }
    },

    async fillPropertyManagementCompany({ commit }, propertyManagementCompany) {
        commit('resetDefaultState')

        if (typeof propertyManagementCompany['amount_of_security'] !== 'undefined')
            commit('setAmountOfSecurity', propertyManagementCompany['amount_of_security'])

        if (typeof propertyManagementCompany['business_card_number'] !== 'undefined')
            commit('setBusinessCardNumber', propertyManagementCompany['business_card_number'])

        if (typeof propertyManagementCompany['business_card_place_of_issue'] !== 'undefined')
            commit('setBusinessCardPlaceOfIssue', propertyManagementCompany['business_card_place_of_issue'])

        if (typeof propertyManagementCompany['legal_person'] !== 'undefined') {

            if (typeof propertyManagementCompany['legal_person']['address'] !== 'undefined' && Object.keys(propertyManagementCompany['legal_person']['address']).length)
                commit('setAddress', propertyManagementCompany['legal_person']['address'])

            if (typeof propertyManagementCompany['legal_person']['business_name'] !== 'undefined')
                commit('setBusinessName', propertyManagementCompany['legal_person']['business_name'])

            if (typeof propertyManagementCompany['legal_person']['incorporation_date'] !== 'undefined')
                commit('setIncorporationDate', propertyManagementCompany['legal_person']['incorporation_date'])

            if (typeof propertyManagementCompany['legal_person']['management_and_ownership'] !== 'undefined' && propertyManagementCompany['legal_person']['management_and_ownership'].length)
                commit('setManagementAndOwnership', propertyManagementCompany['legal_person']['management_and_ownership'])

            if (typeof propertyManagementCompany['legal_person']['identification_number'] !== 'undefined')
                commit('setIdentificationNumber', propertyManagementCompany['legal_person']['identification_number'])

            if (typeof propertyManagementCompany['legal_person']['trade_name'] !== 'undefined')
                commit('setTradeName', propertyManagementCompany['legal_person']['trade_name'])

            if (typeof propertyManagementCompany['legal_person']['share_capital'] !== 'undefined')
                commit('setShareCapital', propertyManagementCompany['legal_person']['share_capital'])

        }

        if (typeof propertyManagementCompany['created_at'] !== 'undefined')
            commit('setCreatedAt', propertyManagementCompany['created_at'])

        if (typeof propertyManagementCompany['email_address'] !== 'undefined')
            commit('setEmailAddress', propertyManagementCompany['email_address'])

        if (typeof propertyManagementCompany['phone_number'] !== 'undefined')
            commit('setPhoneNumber', propertyManagementCompany['phone_number'])

        if (typeof propertyManagementCompany['is_email_notification_enabled'] !== 'undefined')
            commit('setIsEmailNotificationEnabled', propertyManagementCompany['is_email_notification_enabled'])

        if (typeof propertyManagementCompany['accounting_code'] !== 'undefined')
            commit('setAccountingCode', propertyManagementCompany['accounting_code'])

        if (typeof propertyManagementCompany['default_fee_vat_rate'] !== 'undefined')
            commit('setDefaultFeeVatRate', propertyManagementCompany['default_fee_vat_rate'])

        if (typeof propertyManagementCompany['_jv'] !== 'undefined') {
            commit('setId', propertyManagementCompany['_jv'].id)

            if (typeof propertyManagementCompany['_jv']['meta']['swan_onboarding'] !== 'undefined')
                commit('setSwanOnboarding', propertyManagementCompany['_jv']['meta']['swan_onboarding'])

            if (typeof propertyManagementCompany['_jv']['meta']['swan_account_holder'] !== 'undefined')
                commit('setSwanAccountHolder', propertyManagementCompany['_jv']['meta']['swan_account_holder'])

            if (typeof propertyManagementCompany['_jv']['meta']['web_banking'] !== 'undefined')
                commit('setWebBanking', propertyManagementCompany['_jv']['meta']['web_banking'] )

            if (typeof propertyManagementCompany['bank_accounts'] !== 'undefined' && Object.values(propertyManagementCompany['bank_accounts']).filter(item => Object.keys(item).length).length === propertyManagementCompany['_jv']['relationships']['bank_accounts']['data'].length)
                commit('setRelatedBankAccounts', Object.values(propertyManagementCompany['bank_accounts']))

            if (typeof propertyManagementCompany['guarantee_fund'] !== 'undefined' && Object.keys(propertyManagementCompany['guarantee_fund']).length)
                commit('setRelatedGuaranteeFund', propertyManagementCompany['guarantee_fund'])

            if (typeof propertyManagementCompany['logo'] !== 'undefined' && Object.keys(propertyManagementCompany['logo']).length)
                commit('setRelatedLogo', propertyManagementCompany['logo'])

            if (typeof propertyManagementCompany['mediator'] !== 'undefined' && Object.keys(propertyManagementCompany['mediator']).length)
                commit('setRelatedMediator', propertyManagementCompany['mediator'])

            if (typeof propertyManagementCompany['payment_method'] !== 'undefined' && Object.keys(propertyManagementCompany['payment_method']).length)
                commit('setRelatedPaymentMethod', propertyManagementCompany['payment_method'])

            if (typeof propertyManagementCompany['_jv']['meta']['features'] !== 'undefined')
                commit('setFeatures', propertyManagementCompany['_jv']['meta']['features'])


        }
    },

    async formatPropertyManagementCompanyRequest({ getters, rootGetters }) {
        let propertyManager = {
            redirect_url: `${window.location.origin}/swan/callback`,
            legal_person: {
                business_name: getters['getBusinessName'],
            },
            _jv: {
                type: 'property-management-companies'
            }
        }

        if (Object.keys(getters['getAddress']).length) {
            propertyManager['legal_person']['address'] = getters['getAddress']
        }

        if (getters['getIncorporationDate'] !== '') {
            propertyManager['legal_person']['incorporation_date'] = getters['getIncorporationDate']
        }

        if (getters['getManagementAndOwnership'].length) {
            propertyManager['legal_person']['management_and_ownership'] = getters['getManagementAndOwnership']
        }

        if (getters['getIdentificationNumber'] !== '') {
            propertyManager['legal_person']['identification_number'] = getters['getIdentificationNumber']
        }

        if (getters['getTradeName'] !== '')
            propertyManager['legal_person']['trade_name'] = getters['getTradeName']

        if (getters['getShareCapital'] >= 0)
            propertyManager['legal_person']['share_capital'] = getters['getShareCapital']

        if (getters['getAmountOfSecurity'] >= 0) {
            propertyManager['amount_of_security'] = getters['getAmountOfSecurity']
        }

        if (Object.keys(getters['getRelatedPaymentMethod']).length) {

            if (rootGetters['user/getIpAddress'] !== '') {
                propertyManager['ip_address'] = rootGetters['getIpAddress']
            }

            if (rootGetters['user/getUserAgent'] !== '') {
                propertyManager['user_agent'] = rootGetters['getUserAgent']
            }
        }

        if (getters['getBusinessCardNumber'] !== '')
            propertyManager['business_card_number'] = getters['getBusinessCardNumber']

        if (getters['getBusinessCardPlaceOfIssue'] !== '')
            propertyManager['business_card_place_of_issue'] = getters['getBusinessCardPlaceOfIssue']

        if (getters['getIsEmailNotificationEnabled'] !== '')
            propertyManager['is_email_notification_enabled'] = getters['getIsEmailNotificationEnabled']

        if (getters['user/getEmailAddress'] !== '')
            propertyManager['email_address'] = getters['user/getEmailAddress']

        if (getters['getEmailAddress'] !== '')
            propertyManager['email_address'] = getters['getEmailAddress']

        if (getters['getPhoneNumber'] !== '')
            propertyManager['phone_number'] = getters['getPhoneNumber']

        if (getters['getAccountingCode'].length)
            propertyManager['accounting_code'] = getters['getAccountingCode']

        if (getters['getDefaultFeeVatRate'] >= 0)
            propertyManager['default_fee_vat_rate'] = getters['getDefaultFeeVatRate']

        if (getters['getRelatedBankAccounts'].length || Object.keys(getters['getRelatedGuaranteeFund']).length || Object.keys(getters['getRelatedLogo']).length || Object.keys(getters['getRelatedMediator']).length) {
            propertyManager['_jv']['relationships'] = {}

            if (getters['getRelatedBankAccounts'].length) {
                propertyManager['_jv']['relationships']['bank_accounts'] = {
                    data: getters['getRelatedBankAccounts'].map(bankAccount => {
                        return {
                            type: 'bank-accounts',
                            id: bankAccount['_jv'].id
                        }
                    })
                }
            }

            if (Object.keys(getters['getRelatedGuaranteeFund']).length) {
                propertyManager['_jv']['relationships']['guarantee_fund'] = {
                    data: {
                        type: 'guarantee-funds',
                        id: getters['getRelatedGuaranteeFund']['_jv'].id
                    }
                }
            }

            if (Object.keys(getters['getRelatedLogo']).length) {
                propertyManager['_jv']['relationships']['logo'] = {
                    data: {
                        type: 'files',
                        id: getters['getRelatedLogo']['_jv'].id
                    }
                }
            }

            if (Object.keys(getters['getRelatedMediator']).length) {
                propertyManager['_jv']['relationships']['mediator'] = {
                    data: {
                        type: 'mediators',
                        id: getters['getRelatedMediator']['_jv'].id
                    }
                }
            }
        }

        if (getters['getId'] !== '') {
            propertyManager['_jv']['id'] = getters['getId']
        }

        return propertyManager
    },

    async updateGuaranteeFundRelationship({ commit, getters }) {
        try {

            let guaranteeFund = {
                data: null
            }

            if (Object.keys(getters['getRelatedGuaranteeFund']).length) {
                guaranteeFund['data'] = {
                    type: 'guarantee-funds',
                    id: getters['getRelatedGuaranteeFund']['_jv'].id
                }
            }

            const response = await this.$axios({ method: 'patch', url: 'property-management-companies/' + getters['getId'] + '/relationships/guarantee_fund', data: guaranteeFund })

            commit('tyltoApi/deleteRecord', { _jv: { type: 'property-management-companies', id: getters['getId'] } }, { root: true })

            return response

        } catch (error) {

            throw error
        }
    },

    async updateLogoRelationship({ commit, getters }) {
        try {

            let logo = {
                data: null
            }

            if (Object.keys(getters['getRelatedLogo']).length) {
                logo['data'] = {
                    type: 'files',
                    id: getters['getRelatedLogo']['_jv'].id
                }
            }

            const response = await this.$axios({ method: 'patch', url: 'property-management-companies/' + getters['getId'] + '/relationships/logo', data: logo })

            commit('tyltoApi/deleteRecord', { _jv: { type: 'property-management-companies', id: getters['getId'] } }, { root: true })

            return response

        } catch (error) {

            throw error
        }
    },

    async updateMediatorRelationship({ commit, getters }) {
        try {

            let mediator = {
                data: null
            }

            if (Object.keys(getters['getRelatedMediator']).length) {
                mediator['data'] = {
                    type: 'mediators',
                    id: getters['getRelatedMediator']['_jv'].id
                }
            }

            const response = await this.$axios({ method: 'patch', url: 'property-management-companies/' + getters['getId'] + '/relationships/mediator', data: mediator })

            commit('tyltoApi/deleteRecord', { _jv: { type: 'property-management-companies', id: getters['getId'] } }, { root: true })

            return response

        } catch (error) {

            throw error
        }
    },

    async formatPropertyManagementCompanyArrayData({ dispatch, getters, rootGetters }) {
        const propertyManagementCompanyObject = [
            'getAddress',
            'getAmountOfSecurity',
            'getBusinessCardNumber',
            'getBusinessCardPlaceOfIssue',
            'getBusinessName',
            'getIncorporationDate',
            'getEmailAddress',
            'getPhoneNumber',
            'getIdentificationNumber',
            'getTradeName',
            'getRelatedBankAccounts',
            'getRelatedFees',
            'getRelatedGuaranteeFund',
            'getRelatedLogo',
            'getRelatedMediator'
        ].reduce((acc, key) => {
            acc[key] = getters[key]

            return acc
        }, {})

        const propertyManagementCompanyArray = Object.values(propertyManagementCompanyObject)

        const propertyManagerProgress = await dispatch('user/progressFilter', propertyManagementCompanyArray, { root: true })

        return propertyManagerProgress
    },

    async updatePropertyManagementCompany({ dispatch, rootGetters, getters }) {
        try {

            let formatRequest = await dispatch('formatPropertyManagementCompanyRequest')

            const propertyManagementCompany = await dispatch('tyltoApi/patch', formatRequest, { root: true })

            const propertyManagerProgress = await dispatch('formatPropertyManagementCompanyArrayData')

            await dispatch('fillPropertyManagementCompany', propertyManagementCompany)

            const onboardingData = {
                progression: propertyManagerProgress,
            }

            await this.$axios({ method: 'put', url: `/fapi/onboardings/${rootGetters['getAuthUser']['_jv'].id}`, data: onboardingData })

            return propertyManagementCompany

        } catch (error) {

            throw error
        }
    }
}
