export default {
    "error"                                      : {
        "401"               : "Il semble qu'un problème soit survenu : Vérifiez que vous êtes connecté à Internet et que vos identifiants de connexion sont corrects.",
        "default"           : "Il semble qu'un problème soit survenu : Notre équipe a été prévenue. Si vous voulez apporter votre aide, indiquez ce qui s'est passé ci-dessous.",
        "file_upload_failed": "L'upload du fichier à échoué",
        "fee_already_exists": "Un honoraire du même type existe déjà"
    },
    "validate"                                   : {
        "object"                        : {
            "not_empty": "Le champ {_field_} n'est pas valide"
        },
        "array"                         : {
            "length": "La liste doit contenir exactement {length} élements"
        },
        "date"                          : {
            "valid" : "Le champ {_field_} n'est pas valide",
            "after" : "Le champ {_field_} doit être postérieur au {limit}",
            "before": "Le champ {_field_} doit être antérieur au {limit}"
        },
        "phone_number"                  : "Le champs {_field_} n'est pas valide",
        "password_doesnt_match"         : "Les mots de passe ne correspondent pas",
        "step_nested_form_must_be_close": "Le formulaire doit être rempli ou fermé pour continuer",
        "max_number"                    : "Le champ {_field_} doit être inférieur ou égal à {max}",
        "exact_length"                  : "Le champ {_field_} doit comporter {length} caractères"
    },
    property                                     : {
        mixed                              : "Mixte",
        dwelling                           : "Habitation",
        property_details                   : "Description du lot",
        dwelling_type                      : "Type de lot",
        area                               : "Surface habitable",
        surface                            : "Surface",
        construction_date                  : "Période de construction",
        building_ownership_type            : "Régime légal",
        description                        : "Description",
        settlement_type                    : "Type d'habitat",
        description_placeholder            : "À proximité d'espaces verts",
        property_dwelling_type_resume      : "<b>{dwelling_type}</b>",
        property_dwelling_type_details     : " n°{reference} de <b>{area} m²</b> construit {construction_date} ({building_ownership_type})",
        reference                          : "N° de lot",
        reference_placeholder              : "PRS-07",
        reference_tooltip                  : "Numéro du lot tel qu'indiqué dans le règlement de copropriété",
        case_number                        : "N° de dossier",
        fiscal_reference_number            : "Identifiant fiscal du logement",
        fiscal_reference_number_placeholder: "753948271035",
        case_number_placeholder            : "N°05",
        localization                       : "Localisation",
        address                            : "Adresse du lot",
        rooms                              : "Pièces",
        step_resume_rooms                  : "<b>{nbPieces}</b> pièces principales",
        pieces_sejour_et_services          : "Pièces principales",
        local_a_usage_prive                : "Local à usage privé",
        local_a_usage_commun               : "Local à usage commun",
        utilities                          : "Équipements",
        heating                            : "production de chauffage",
        hotwater                           : "production d'eau chaude",
        available_connexion                : "raccordements disponibles",
        type_de_production_chauffage       : "Type de chauffage",
        mode_de_production_chauffage       : "Mode de chauffage",
        type_de_production_eau_chaude      : "Type de production",
        mode_de_production_eau_chaude      : "Mode de production",
        raccordement_internet              : "Internet",
        raccordement_television            : "Télévision",

        property_energy_diagnoses   : "Diagnostic énergétique",
        has_dpe                     : "Le lot est-il soumis à un diagnostic énergétique ?",
        is_energy_diagnoses         : "Le lot est-il soumis à un diagnostic énergétique ?",
        energy_consumption          : "Consommation énergétique",
        gas_emission                : "Émission de gaz à effet de serre (GES)",
        dpe_file                    : "Fichier DPE (optionnel)",
        step_resume_energy_diagnoses: "{isEnergydiagnoses}",
        presence_dpe                : "DPE ajouté",
        non_presence_dpe            : "Non soumis au DPE",
        delivery_date               : "Date de réalisation",
        expiration_date             : "Date d'expiration",
        landlord                    : 'Propriétaire',
        address_placeholder         : "3 rue de la Mairie",
        step_resume_utilities       : "Chauffage <b>{typeHeater}</b> <b>{chauffage}</b> et production eau chaude <b>{typeWaterHeater}</b> <b>{eauChaude}</b>",
        other_equipements           : "Autres équipements",
        search_landlord             : "Rechercher un propriétaire",
        new_landlord                : "Ajouter un nouveau propriétaire",
        editLandlord                : "Edition d'un propriétaire",


    },
    "contract"                                   : {
        "unknown" : "Aucun contract",
        "mandate" : "Mandat de gestion {numeroRegistre}",
        "rental"  : "Contrat de location",
        "from"    : "Prenant effet le {startDate}",
        "to"      : "au {endDate}",
        "draft"   : "Brouillon",
        "upcoming": "À venir",
        "ongoing" : "En cours",
        "ended"   : "Terminé",
        "ending"  : "Bientôt terminé"
    },
    "documents"                                  : {
        "asplc"                   : "Surface privative ou loi Carrez",
        "audit_energetique"       : "Audit énergétique",
        "crep"                    : "Risque d'exposition au plomb",
        "diagnostic_termites"     : "Termites dans le bâtiment",
        "diagnostic_bruit"        : "Bruit des aéroports",
        "dpe"                     : "Performance énergétique",
        "dpe_collectif"           : "DPE Collectif",
        "dtg"                     : "Diagnostic technique global",
        "eianc"                   : "Assainissement non collectif",
        "eiie"                    : "Installation intérieure d'électricité",
        "eiig"                    : "Installation intérieure de gaz",
        "empampca"                : "Présence d'amiante",
        "erp"                     : "État des risques et pollutions",
        "iprm"                    : "Présence d'un risque de mérule",
        "guaranty_agreement"      : "Acte de caution solidaire",
        "checkin_inventory"       : "État des lieux d'entrée",
        "annex_to_rental"         : "Annexe du contrat de location",
        "cni"                     : "Carte d'identité",
        "proof_of_address"        : "Justificatif de domicile",
        "proof_of_work"           : "Justificatif de situation professionnelle",
        "proof_of_income"         : "Justificatif de revenus",
        "bank_details"            : "RIB",
        "cost_invoice"            : "Facture de dépenses",
        "lease_agreement"         : "Contrat de location",
        "management_report"       : "Compte rendu de gestion",
        "mandate"                 : "Mandat de gestion",
        "rent_notice"             : "Appel de loyer",
        "rent_receipt"            : "Quittance de loyer",
        "shared_ownership_rules"  : "Règlement de copropriété",
        "tax_notice"              : "Avis d'imposition",
        "signed_guarantee_deed"   : "Acte de caution signé",
        "security_deposit_notice" : "Appel de dépôt de garantie",
        "security_deposit_receipt": "Quittance de dépôt de garantie",
        "cost_notice"             : "Appel exceptionnel",
        "cost_receipt"            : "Quittance d'appel exceptionnel",
        "title_deed"              : "Titre de propriété",
        "last_general_meeting"    : "Dernier procès-verbal d'assemblée générale",
        "last_fund_request"       : "Dernier appel de fonds"
    },
    "fees"                                       : {
        "representation_in_general_meeting_of_co_owners"                       : "Représentation aux assemblées générales des co-propriétaires",
        "checkin_inventory_landlord_share"                                     : "État des lieux d’entrée (part propriétaire)",
        "checkin_inventory_tenant_share"                                       : "État des lieux d’entrée (part locataire)",
        "checkout_inventory"                                                   : "État des lieux de sortie",
        "everyday_management"                                                  : "Gestion courante",
        "maintenance_and_repairs"                                              : "Réparations et travaux de maintenance",
        "matchmaking_and_negociation"                                          : "Entremise et négociation",
        "rent_guarantee_insurance"                                             : "Garantie loyers impayés",
        "tax_return_filling_support"                                           : "Aide à la déclaration des revenus fonciers",
        "visits_management_file_making_lease_agreement_drafting_landlord_share": "Organisation des visites, constitution du dossier et rédaction du bail  (part propriétaire)",
        "visits_management_file_making_lease_agreement_drafting_tenant_share"  : "Organisation des visites, constitution du dossier et rédaction du bail  (part locataire) ",
        "additional_services"                                                  : "Prestations supplémentaires",
        "rental_insurance"                                                     : "Assurance locataire",
        "guarantor_insurance"                                                  : "Garant location",
        "home_insurance"                                                       : "Assurance habitation pour le compte des locataires",
        "administrative_fees"                                                  : "Frais administratifs",
        "bailiff_dossier_submission"                                           : "Remise de dossier à l’huissier",
        "closure_and_clearing_of_accounts_at_end_of_management"                : "Clôture et apurement des comptes pour fin de gestion",
        "fees_for_unpaid_checks_or_direct_debits"                              : "Frais pour chèque ou prélèvement impayés",
        "fees_on_unpaid_rent_guarantee"                                        : "Honoraires sur la garantie des loyers impayés",
        "insurance_claim_management"                                           : "Gestion des sinistres assurance/syndic/locataire « constat amiable dégâts des eaux",
        "lawyer_dossier_submission"                                            : "Remise de dossier à l’avocat",
        "lease_amendment_drafting_landlord_request"                            : "Rédaction avenant au bail habitation à la demande du propriétaire",
        "lease_amendment_drafting_tenant_request"                              : 'Rédaction avenant au bail habitation à la demande du locataire',
        "monthly_advance_supplement"                                           : "Supplément pour acompte mensuel",
        "new_housing_reception_and_management_of_reserves"                     : "Réception des logements neufs et gestion des réserves",
        "off_period_transfer_supplement"                                       : "Supplément pour virement hors période",
        "organization_and_management_of_energy_audit"                          : "Organisation et gestion d’un audit énergétique",
        "organization_and_management_of_real_estate_diagnostics"               : "Organisation et gestion des diagnostics immobiliers",
        "pno_fees"                                                             : "Honoraire sur la PNO",
        "police_report_filing"                                                 : "Dépôt de plainte gendarmerie",
        "registered_mail_fee_for_unpaid_rent_reminder"                         : "Frais de recommandé pour relance loyer impayé",
        "reminder_fee_for_unpaid_rent"                                         : "Frais de relance pour loyer impayé",
        "representation_in_general_meeting"                                    : "Représentation aux Assemblées Générales",
        "registered_mail_sending"                                              : "Envoi par recommandé",
        "rent_revaluation_on_lease_renewal"                                    : "Revalorisation du loyer lors du renouvèlement du bail",
        "representation_in_insurance_expertise"                                : "Représentation aux expertises assurances",
        "subsidy_application_for_works"                                        : "Dossier demandes de subventions pour travaux",
        "tenant_payment_plan_drafting"                                         : "Rédaction d’un plan d’apurement locataire",
        "tenant_warning"                                                       : "Mise en demeure du locataire",
        "work_follow_up"                                                       : "Suivi de travaux (devis, appel d’offres, réception des travaux)",
        "work_verification_travel"                                             : "Déplacement vérification de travaux"
    },
    "rental_item_types"                          : {
        "all"                       : "Toutes les sommes perçues",
        "cleaning_charges"          : "Frais de ménage",
        "fixed_service_charges"     : "Forfait de charge",
        "guarantor_insurance"       : "Assurance locataire",
        "occupancy_compensation"    : "Indemnité d'occupation",
        "rent"                      : "Loyer (hors charges)",
        "rent_supplement"           : "Supplément de loyer",
        "security_deposit"          : "Dépôt de garantie",
        "service_charges_adjustment": "Régularisation de charges",
        "variable_service_charges"  : "Provision sur charges",
        "periodic_service_charges"  : "Paiement périodique des charges sans provision",
    },
    "rent"                                       : {
        "paid"          : "Payé",
        "unpaid"        : "Impayé",
        "ready"         : "À venir",
        "upcoming"      : "À venir",
        "draft"         : "Brouillon",
        "open"          : "À payer",
        "canceled"      : "Annulé",
        "refund"        : "Remboursé",
        "partial_refund": "Remboursé partiellement",
        "reserved"      : "Provisionné"
    },
    "bank_account"                               : {
        "oskar"      : "Oskar",
        "bnp_paribas": "BNP Paribas"
    },
    "accounting"                                 : {
        "journal": {
            "operations_diverses": "Opérations diverses"
        },
        "entries": {
            "accounting_entries_totals": "Solde des écritures",
        }
    },
    "payment_method"                             : {
        "sepa_credit_transfer"      : "Virement programmé",
        "virtual_iban"              : "IBAN virtuel",
        "external_sepa_direct_debit": "Prélèvement automatique",
        "cash"                      : "Espèces",
        "check"                     : "Chèque"
    },
    "mixins"                                     : {
        "property-shorthand": {
            "house"            : "Maison",
            "flat"             : "Appartement",
            "parking"          : "Place de parking",
            "business_premises": "Local commercial",
            "loft"             : "Loft",
            "other"            : "Autre",
            "rooms"            : "{nb} pièces",
            "area"             : "{area} m²",
            "studio"           : "Studio",
            "unfurnished"      : "vide",
            "is_furnished"     : "meublé",
            "reference"        : "N° de lot : <span>{reference}</span>",
            "case_number"      : "N° de dossier : <span>{caseNumber}</span>"
        },
        "widgetFilter"      : {
            "this_month"   : "Ce mois-ci",
            "last_3_months": "3 derniers mois",
            "last_6_months": "6 derniers mois",
            "this_year"    : "Cette année"
        }
    },
    "costs"                                      : {
        'adjustment_of_charges'                                                : 'Régularisation de charges',
        "non_resident_caretaker"                                               : "Employé d'immeuble (non logé sur place)",
        "resident_caretaker"                                                   : "Gardien/concierge (habitant sur place)",
        "elevator"                                                             : "Ascenseurs et monte-charge",
        "shared_heating_and_water"                                             : "Eau froide, eau chaude et chauffage collectif",
        "private_heating_and_water"                                            : "Installations individuelles",
        "common_areas_maintenance"                                             : "Entretien des parties communes intérieures",
        "outdoor_space_maintenance"                                            : "Espaces extérieurs",
        "rental_public_taxes"                                                  : "Taxes et redevances",
        "repair_and_maintenance_works"                                         : "Travaux réparation et entretien",
        "improvement_works"                                                    : "Travaux d'amélioration",
        "construction_works"                                                   : "Travaux (re) construction et d'agrandissement",
        "expenses_on_behalf_of_tenants"                                        : "Charges acquittées pour le compte des locataires et restant définitivement à la charge des propriétaires",
        "provisions_for_shared_building_ownership"                             : "Provisions pour charges de copropriété",
        "management_fees"                                                      : "Frais de gestion",
        "insurance_premium"                                                    : "Primes d'assurance",
        "public_taxes"                                                         : "Taxes et contributions déductibles d'impôt",
        "borrowing_interest"                                                   : "Intérêts d'emprunt",
        "other_expenses"                                                       : "Autres charges",
        "checkin_inventory_landlord_share"                                     : "État des lieux d’entrée",
        "visits_management_file_making_lease_agreement_drafting_landlord_share": "Organisation des visites, constitution du dossier et rédaction du bail",
        "matchmaking_and_negociation"                                          : "Entremise et négociation",
        "everyday_management"                                                  : "Gestion courante",
        'garden_maintenance'                                                   : 'Entretien des jardins',
    },
    "notifications"                              : {
        "property"  : {
            "created": {
                "term1": "Création du lot situé au"
            }
        },
        "rental"    : {
            "created": {
                "term1": "Nouveau ",
                "term2": "créé, prenant effet le {data}."
            },
            "signed" : "La signature du <a href='/viewer/rental/{rentalId}'> contrat de location</a>prenant effet le {startDate} a été complétée par tout les signataires."
        },
        "landlord"  : {
            "created"            : {
                "term1": "Le profil propriétaire de",
                "term2": "a été créé."
            },
            "information"        : "Le propriétaire <a href='/landlord/{landlordId}' class='activity-link'>{landlord}</a> a modifié/ajouté les informations suivantes: {fields}.",
            "payout_bank_account": {
                "created": "le propriétaire <a href='/landlord/{landlordId}' class='activity-link'>{landlord}</a> a ajouté des informations bancaires.",
                "updated": "le propriétaire <a href='/landlord/{landlordId}' class='activity-link'>{landlord}</a> a modifié ses informations bancaires.",
                "deleted": "le propriétaire <a href='/landlord/{landlordId}' class='activity-link'>{landlord}</a> a supprimé des informations bancaires."
            },
            "document"           : {
                "created": "Le propriétaire <a href='/landlord/{landlordId}' class='activity-link'>{landlord}</a> a ajouté le document suivant à son dossier: {fields}|Le propriétaire <a href='/landlord/{landlordId}' class='activity-link'>{landlord}</a> a ajouté les documents suivants à son dossier: {fields}",
                "deleted": "Le propriétaire <a href='/landlord/{landlordId}' class='activity-link'>{landlord}</a> a supprimé le document suivant à son dossier: {fields}|Le propriétaire <a href='/landlord/{landlordId}' class='activity-link'>{landlord}</a> a supprimé les documents suivants à son dossier: {fields}"
            }
        },
        "supplier"  : {
            "created": {
                "term1": "Le profil fournisseur de",
                "term2": "a été créé."
            },
        },
        "tenant"    : {
            "created"       : {
                "term1": "Le profil locataire de",
                "term2": "a été créé."
            },
            "information"   : "Le locataire <a href='/tenant/{tenantId}' class='activity-link'>{tenant}</a> a modifié/ajouté les informations suivantes: {fields}.",
            "payment_method": "le locataire <a href='/tenant/{tenantId}' class='activity-link'>{tenant}</a> a modifié/ajouté le moyen de paiement suivant: {fields}.",
            "document"      : {
                "created": "Le locataire <a href='/tenant/{tenantId}' class='activity-link'>{tenant}</a> a ajouté le document suivant à son dossier: {fields} | Le locataire <a href='/tenant/{tenantId}' class='activity-link'>{tenant}</a> a ajouté les documents suivants à son dossier: {fields}",
                "deleted": "Le locataire <a href='/tenant/{tenantId}' class='activity-link'>{tenant}</a> a supprimé le document suivant à son dossier: {fields}|Le locataire <a href='/tenant/{tenantId}' class='activity-link'>{tenant}</a> a supprimé les documents suivants à son dossier: {fields}"
            }
        },
        "mandate"   : {
            "created": {
                "term1": "Création du",
                "term2": "au registre des mandats."
            },
            "signed" : "La signature du <a href='/viewer/mandate/{mandateId}'> mandat de gestion</a> numéro {number} a été complétée par tout les signataires."
        },
        "invitation": {
            "created" : {
                "term1": "Invitation envoyée à",
                "term2": "pour rejoindre la plateforme."
            },
            "accepted": {
                "term1": "Invitation acceptée par",
                "term2": "pour rejoindre la plateforme."
            }
        },
        "signature" : {
            "created"                             : "Une demande de signature a été envoyé par mail à {signer}.",
            "signature_request_has_been_completed": "La signature du <a href='/viewer/file/{fileId}' class='activity-link'>document</a> a été complétée par tous les signataires le {date}.",
            "signed"                              : "{signatoriesTranslation} <a href='{redirectPath}' class='activity-link'>{signer}</a> {verb} signé un document le {date}.",
            "signatory"                           : "Le signataire ",
            "signatories"                         : "Les signataires ",

        }
    },
    "legal_person"                               : {
        "address"              : "Adresse",
        "birth_date"           : "Date de naissance",
        "birth_place"          : "Lieu de naissance",
        "first_name"           : "Prénom",
        "gender"               : "genre",
        "last_name"            : "Nom",
        "nationality"          : "Nationalité",
        "email_address"        : "Adresse email",
        "phone_number"         : "Numéro de téléphone",
        "business_name"        : "Dénomination sociale",
        "identification_number": "Numéro SIREN",
        "incorporation_date"   : "Date de création",
        "share_capital"        : "Capital social",
        "trade_name"           : "Nom commercial",
        "matrimonial_status"   : "Etat matrimonial",
        "payout_delay"         : "Delai de reversement automatique",
    },
    mandate                                      : {
        register                      : "Registre des mandats",
        number                        : "Numéro au registre",
        start_date                    : "Date de début du contrat",
        term                          : "Durée du contrat",
        missions                      : "Missions",
        mandate_other_specific_clauses: "Clauses particulières du mandat",
        other_specific_clauses        : "Clause particulière",
        mandate_annexes               : "Annexes du mandat",
        annexes                       : "Annexes",
        fees                          : {
            step       : "Honoraires mandant",
            text       : "Honoraires appliqués au mandat",
            step_resume: "Aucun honoraire saisi | Un honoraire saisi | {count} Honoraires saisis"
        },
        step_resume_fees_mandate      : "Honoraires appliqués au mandat",
    },
    amount                                       : "Montant",
    amount_before_tax                            : "Montant HT",
    amount_with_tax                              : "Montant TTC",
    vat_rate                                     : "Taux de TVA",
    total_amount_before_tax                      : "Total HT",
    total_amount_with_tax                        : "Total TTC",
    total_tax                                    : "Total TVA",
    vat                                          : "TVA",
    type                                         : "Type",
    applies_to                                   : "S'applique sur",
    property_manager_company_fees                : "Honoraires de l'agence",
    step_resume                                  : "<b>{nb}</b> honoraires",
    set_your_property_manager_compagny_fees      : "Ce taux de TVA s'appliquera à l'ensemble de vos honoraires.",
    property_manager_company_default_fee_vat_rate: "Choix du taux de TVA <span style='font-weight: 400;margin-left: 5px;'> (par défaut)</span>",
    fees_created                                 : "Honoraires créés avec succès",
    fees_edited                                  : "Honoraires modifiés avec succès",
    empty_fees                                   : "Aucun honoraire défini",
    select_an_option                             : "Sélectionnez une option",
    new_fees                                     : "Ajouter un honoraire",
    fees_applies_to                              : "Application du montant de l'honoraire",
    fees_type                                    : "Type d'honoraire",
    fees_category                                : "Catégorie d'honoraire",
    category_services                            : "Catégorie de prestations",
    service_types                                : "Type de prestations",
    select_category_fee                          : "Sélectionnez la catégorie d'honoraire",
    select_fee_type                              : "Sélectionnez un type d'honoraire",
    select_category_service                      : "Sélectionnez la catégorie d'honoraire",
    select_service_type                          : "Sélectionnez un type de prestation",
    select_fee_amount_type                       : "Sélectionnez le mode de calcul",
    fees_amount_type                             : "Mode de calcul",
    currency                                     : "Montant fixe (€)",
    update_fee                                   : "Modifier un honoraire",
    updating_fee_type                            : "Modification de l'honoraire",
    percent                                      : "Pourcentage (%)",
    select_fees_type                             : "Sélectionnez le type d'honoraire et le montant sur lequel il doit s'appliquer.",
    add                                          : "Nouveau",
    modify                                       : "Modifier",
    level                                        : "Niveau",
    status                                       : "Statut",
    validate_form                                : "Valider",
    level_options                                : {
        "property-management-companies": "Agence",
        properties                     : "Lot",
        rentals                        : "Contrat de location",
        landlords                      : "Propriétaire"
    },
    applied_to                                   : {
        all: "Tout séléctionner",
    },

    select_tense_area: "Sélectionnez une zone",
    tense_areas      : "Type de zone",
    all              : "Tous",
    no_fees          : "Aucun honoraire prédéfini.",
    user_update      : "Paramètres de l’agence",
    user_updated     : "Le compte a bien été mis à jour.",
    fees_updated     : "vos honoraires sont enregistrés.",

    dwelling_type          : {
        "flat"             : "appartement",
        "house"            : "maison",
        "loft"             : "loft",
        "business_premises": "local commercial",
        "parking"          : "parking",
        "other"            : "autre"
    },
    construction_date      : {
        "/1948-12-31"          : "Avant le 1 janvier 1949",
        "1949-01-01/1974-12-31": "Entre le 1 janvier 1949 et le 31 décembre 1974",
        "1975-01-01/1989-12-31": "Entre le 1 janvier 1975 et le 31 décembre 1989",
        "1990-01-01/2004-12-31": "Entre le 1 janvier 1990 et le 31 décembre 2004",
        "2005-01-01/"          : "Après le 31 décembre 2004"
    },
    building_ownership_type: {
        "private": "Monopropriété",
        "shared" : "Co-propriété"
    },
    settlement_type        : {
        "shared" : "Collectif",
        "private": "Individuel"
    },

    utilities: {
        "electric_water_heater"             : "Chauffe-eau électrique",
        "gas_boiler_water_heater"           : "Chaudière à gaz",
        "gas_water_heater"                  : "Chauffe-eau à gaz",
        "oil_boiler_water_heater"           : "Chaudière à fuel",
        "solar_water_heater"                : "Chauffe-eau solaire",
        "wood_boiler_water_heater"          : "Chaudière à bois",
        "shared_electric_water_heater"      : "Chauffe-eau électrique",
        "shared_gas_boiler_water_heater"    : "Chaudière à gaz",
        "shared_gas_water_heater"           : "Chauffe-eau à gaz",
        "shared_oil_boiler_water_heater"    : "Chaudière à fuel",
        "shared_solar_water_heater"         : "Chauffe-eau solaire",
        "shared_wood_boiler_water_heater"   : "Chaudière à bois",
        "central_no_water_heater"           : "Sans",
        "central_geothermal_water_heater"   : "Géothermie",
        "central_mixed_water_heater"        : "Mixte",
        "central_aerothermal_water_heater"  : "Aérothermie",
        "central_thermodynamic_water_heater": "Thermodynamique",
        "central_heat_pump_water_heater"    : "Pompe à chaleur",
        "electric_heater"                   : "Chauffage électrique",
        "gas_boiler_heater"                 : "Chauffage à gaz",
        "oil_boiler_heater"                 : "Chauffage à fuel",
        "wood_boiler_heater"                : "Chauffage à bois",
        "shared_electric_boiler_heater"     : "Chaudière électrique",
        "shared_electric_heater"            : "Chauffage électrique",
        "shared_gas_boiler_heater"          : "Chauffage à gaz",
        "shared_oil_boiler_heater"          : "Chauffage à fuel",
        "shared_wood_boiler_heater"         : "Chauffage à bois",
        "central_no_heater"                 : "Sans",
        "central_aerothermal_heater"        : "Aérothermique",
        "central_coal_heater"               : "Charbon",
        "central_air_conditioning_heater"   : "Climatisation",
        "central_city_gas_heater"           : "Gaz de ville",
        "central_geothermal_heater"         : "Géothermique",
        "central_wood_pellet_heater"        : "Granule",
        "central_mixed_heater"              : "Mixte",
        "central_heat_pump_heater"          : "Pompe à chaleur",
        "central_solar_heat_heater"         : "Solaire",
        "private"                           : "privée",
        "common"                            : "collectif",
        "central"                           : "central",
        "cable"                             : "Câble",
        "digital_terrestrial_television"    : "TNT",
        "satellite"                         : "Satellite",
        "asymmetric_digital_subscriber_line": "ADSL",
        "optical_fiber"                     : "Fibre optique",
        "bell"                              : "Sonnerie",
        "cooker"                            : "Cuisinière",
        "cooker_hood"                       : "Hotte de cuisine",
        "cooking_plate"                     : "Plaque de cuisson",
        "dishwasher"                        : "Lave-vaisselle",
        "dryer"                             : "Sèche-linge",
        "electronic_lock"                   : "Serrure électronique",
        "fireplace"                         : "Cheminée",
        "freezer"                           : "Congélateur",
        "intercom"                          : "Interphone",
        "jacuzzi"                           : "Jacuzzi",
        "kitchen_furniture"                 : "Meuble de cuisine",
        "microwave_oven"                    : "Four à micro-ondes",
        "oven"                              : "Four",
        "refrigerator"                      : "Réfrigérateur",
        "swimming_pool"                     : "Piscine",
        "washing_machine"                   : "Lave-linge",
        "attic"                             : "Grenier / Combles aménagés",
        "balcony"                           : "Balcon",
        "cellar"                            : "Cave",
        "loggia"                            : "Loggia",
        "garden"                            : "Jardin",
        "parking_space"                     : "Place de stationnement",
        "terrace"                           : "Terrasse",
        "veranda"                           : "Véranda",
        "empty_heater"                      : "Aucun",
    },
    rooms    : {
        "bedroom"            : "chambre",
        "dining_room"        : "salle à manger",
        "laundry_room"       : "buanderie",
        "living_room"        : "séjour",
        "office"             : "bureau",
        "vestibule"          : "vestibule",
        "bathroom"           : "salle de bain",
        "kitchen"            : "cuisine",
        "restroom"           : "W.C. séparé",
        "shower_room"        : "salle de douche",
        "attic"              : "grenier / combles aménagés",
        "balcony"            : "balcon",
        "cellar"             : "cave",
        "loggia"             : "loggia",
        "garden"             : "jardin",
        "parking_space"      : "place de stationnement",
        "terrace"            : "terrasse",
        "veranda"            : "véranda",
        "bicycle_parking"    : "parking à vélo",
        "caretaker_service"  : "gardien",
        "elevator"           : "ascenseur",
        "green_space"        : "espaces verts",
        "shared_laundry_room": "Buanderie commune",
        "playground"         : "aire et équipements de jeux",
        "shared_shower_room" : "douche sur le palier",
        "shared_restroom"    : "W.C. sur le palier"
    },
    save     : "Enregistrer",
    update   : 'Modifier',
    delete   : 'Supprimer',

    profileFinder: {
        placeholder: "Thierry Dupont",
        no_results : "Aucun résultat pour cette recherche.",
        balance    : "Solde : {balance}"
    },

    supplier_invoice        : "Facture fournisseur",
    create                  : "Créer",
    title                   : "Titre",
    description       : "Description",
    show_pdf                : "Voir le pdf",
    edit                    : "Modifier",
    print                   : "Imprimer",
    label                   : "Libellé",
    emission_date           : "Date émission",
    emission_date_asc       : "Date d'émission croissante",
    emission_date_desc      : "Date d'émission décroissante",
    origin                  : "Provenance",
    invoice                 : "Facture",
    reset                   : "Réinitialiser",
    filters                 : "Filtres",
    period                  : "Par période",
    start_date              : "Date début",
    end_date                : "Date fin",
    supplier_invoices       : "Factures fournisseur",
    landlord                : {
        entity: "Type de profil",
    },
    legalPerson             : {
        entityIndividual: 'Seul',
        entityCompany   : 'Société',
        entityCouple    : 'Couple(marié, pacsé)',

        new_profiles               : "Nouveaux profils",
        new_indivisiary            : "Ajouter un indivisaire",
        landlord                   : "propriétaire",
        tenant                     : "locataire",
        supplier                   : "fournisseur",
        first_name                 : "Prénom",
        new_client                 : "Nouveau client",
        add_new                    : "Ajouter un nouveau",
        last_name                  : "Nom",
        birth_date                 : "Date de naissance",
        phone_number               : "Numéro de téléphone",
        address                    : "Adresse",
        placeholder_first_name     : "Thierry",
        placeholder_last_name      : "Dupont",
        placeholder_address        : "5 rue de la mairie",
        business_name              : "Dénomination sociale",
        business_name_placeholder  : "SCI ABC",
        indivision_name            : "Nom de l'indivision",
        indivision_name_placeholder: "Indivision ABC",
        person                     : "Personne {nb}",
        identification_number      : "Numéro SIREN",
        trade_name                 : "Nom commercial",
        trade_name_placeholder     : "Agence de la Gare",
        incorporation_date         : "Date de création",
        share_capital              : "Capital social",
        birth_place                : "Lieu de naissance",
        nationality                : "Nationalité",
        placeholder_birth_place    : "Paris",
        placeholder_nationality    : "Française",
        gender                     : "Civilité",
        male                       : "Monsieur",
        female                     : "Madame",
        same_address               : "L'adresse est-elle identique ?"
    },
    fee_note                : {
        step_resume    : {
            designation: "{title}",
            details    : "Aucun honoraire saisi | Un honoraire saisi | {count} Honoraires saisis"
        },
        placeholder    : {
            title      : "Honoraire sur représentation AG",
            description: "Saisissez une description",
        },
        errors         : {
            no_details : "Vous devez renseigner au moins un honoraire",
            description: "Saisissez une description",
        },
        create         : "Création d'une note d'honoraire",
        update         : "Modification d'une note d'honoraire",
        text           : "Note d'honoraire",
        designation    : "Dénomination",
        details        : "Détails",
        unit           : "Unité (h)",
        provision      : "Provision",
        provisioned    : "Vous avez provisionné cette note d'honoraire",
        not_provisioned: "Vous n'avez pas provisionné cette note d'honoraire",
        ask_provision  : "Souhaitez-vous provisionner cette note d’honoraires ?",
    },
    fee_notes               : {
        text            : "Notes d'honoraire",
        do_provision    : "Provisionner",
        cancel_provision: "Annuler la provision"
    },
    payout                  : {
        error: {
            amount_sup_balance: "Le montant de la facture est supérieur au montant disponible."
        }
    },
    contact_support         : "Une erreur est survenue pendant la sauvegarde. Veuillez contacter le support Oskar.",
    charges                 : 'charges récupérables',
    cancel                  : "Annuler",
    update_line             : "Modification de l'élément",
    fees_category_infos     : {
        "rental_fees"                : "Les honoraires de location se génèrent automatiquement à la validation du contrat de location. On retrouve :  <br/> <ul><li>la part locataire, dans le premier appel de loyer</li><li>la part propriétaire dans une note d’honoraires de location</li></ul>",
        "everyday_management_fees"   : "Les honoraires de gestion courante se génèrent automatiquement après chaque paiement de loyers.<br>Ils concernent : les envois de quittances/avis d’échéance, les encaissements des loyers et charges, les relances loyers impayés, les révisions des loyers, les régularisations annuelles des charges, les règlements des charges de copropriété, les suivis techniques (petites interventions, travaux courants), les envois des CRG, les documents annuels d’aide à la déclaration des revenus fonciers et les virements propriétaires.",
        "additional_fees"            : "Les honoraires complémentaires peuvent être appelés lors de la création d’une note d’honoraire, s’ils ont été renseignés dans le mandat de gestion.",
        "litigation_management_fees" : "Les honoraires de gestion des contentieux peuvent être appelés lors de la création d’une note d’honoraire, s’ils ont été renseignés dans le mandat de gestion.",
        "incident_management_fees"   : "Les honoraires de gestion des sinistres peuvent être appelés lors de la création d’une note d’honoraire, s’ils ont été renseignés dans le mandat de gestion.",
        "maintenance_management_fees": "Les honoraires de gestion des travaux peuvent être appelés lors de la création d’une note d’honoraire, s’ils ont été renseignés dans le mandat de gestion.",
        "various_landlord_fees"      : "Les honoraires diverses propriétaire peuvent être appelés lors de la création d’une note d’honoraire, s’ils ont été renseignés dans le mandat de gestion.",
        "various_tenant_fees"        : "Les honoraires divers locataire peuvent être appelés lors de la création d’un appel exceptionnel, s’ils ont été renseignés dans les paramètres d’agence."
    },
    new_oskar_functionality : {
        title             : 'Nouveautés Oskar',
        page_title        : 'Explorez les nouvelles fonctionnalités d’Oskar !',
        subtitle          : 'Découvrez les nouveautés qui vous permettront de simplifier le pilotage de votre activité de gestion.',
        fee               : {
            title        : 'La rubrique honoraires évolue !',
            description  : 'Découvrez de nouveaux honoraires depuis vos paramètres d\'agence et réalisez de nouvelles actions essentielles à votre quotidien.',
            fee_evolution: {
                title     : 'Evolution des honoraires',
                updated_at: 'Mise à jour le 18/11/24'
            },
            fee_interface : {
                title     : 'Interface des honoraires',
                updated_at: 'Mise à jour le 03/12/24'
            }
        },
        new               : 'Nouveau !',
        news: 'Nouveautés',
        discover_whats_new: 'Découvrez les nouveautés'
    },
    see_all                 : 'Voir tout',
    go_back_to_dashboard    : 'Retour à l’accueil',
    bank_account_transaction: {
        booked  : 'Validé',
        pending : 'En attente',
        upcoming: 'À venir',
        rejected: 'Rejeté',
        canceled: 'Annulé',
    },
    yes                     : "Oui",
    no                      : "Non",
}
