<template>

    <div class="property-action-buttons-widget margin-widget" v-if="isManager">

        <button-input :iconImg="'edit'" class="action list" iconColor="#FFFFFF" :text="$t('modify_the_property')" :redirectTo=" `/workflow/property/form?propertyId=${propertyId}`" />

        <button-input :iconImg="'terminate'" class="action list" iconColor="#FFFFFF" :text="$t('terminate_a_contract')" @click="$refs.breachOfRentalModal.show()" v-if="isRunningRentals" />

        <button-input v-if="features.new_fee_management !== FeatureNewFeeManagement.enabled"
                      :iconImg="'fees'" class="action list" iconColor="#FFFFFF" :text="$t('edit_fees')" @click="$refs.editPropertyFees.show()" />

        <button-input class="action action-delete" iconColor="#FFFFFF" :iconImg="'delete'" :text="$t('delete_property')" @click="$refs.deletePropertyModal.show()" />

        <edit-property-fees :rentals="propertyContract" @closed="fetchRentalsRelationship" ref="editPropertyFees"  />

        <breach-of-rental-modal :rentals="propertyContract" ref="breachOfRentalModal" />

        <delete-property-modal ref="deletePropertyModal" />

    </div>

</template>

<script>
    import BreachOfRentalModal from '~/components/modal/breach-of-rental'
    import EditPropertyFees from '~/components/modal/edit-property-fees'
    import DeletePropertyModal from '~/components/modal/delete-property'
    import { mapGetters } from 'vuex'
    import FeatureNewFeeManagement from "@/models/features/FeatureNewFeeManagement";

    export default {
        components: {
            BreachOfRentalModal,
            EditPropertyFees,
            DeletePropertyModal
        },

        methods: {
            async fetchRentalsRelationship() {

                this.$store.commit('property/setRelatedRentals', [])

                return await this.$store.dispatch('property/fetchRentalsRelationship')
            }
        },

        computed: {
            FeatureNewFeeManagement() {
                return FeatureNewFeeManagement
            },
            isRunningRentals() {
                return this.propertyContract.filter(rental => typeof rental.end_date === 'undefined').length
            },

            ...mapGetters({
                rentalId: 'rental/getId',
                propertyContract: 'property/getRelatedRentals',
                propertyId: 'property/getId',
                isManager: 'isManager',
                features: 'property-manager/getFeatures'
            })
        }
    }
</script>

<i18n>
    {
        "fr": {
            "modify_the_property": "Modifier le lot",
            "terminate_a_contract": "Rompre un contrat",
            "edit_fees": "Modifier les honoraires",
            "delete_property": "Supprimer le lot"
        }
    }
</i18n>

<style lang="scss">
    .property-action-buttons-widget {
        display: flex;
        flex-direction: column;

        .button-input {
            &.list{
                background: #525254;
                color: white;
            }
            &.action-delete{
                color : white
            }
        }
    }
</style>
