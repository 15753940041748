<template>

    <form-group :class="['select-box-input', { 'disabled': disabled }]" :label="label" :name="name" :rules="rules" :customErrorMessages="customErrorMessages" v-slot="{ id, errors }">

        <div :class="['input', { 'errors': errors.length }, { 'transparent-background': bgTransparent }, {'active': !collapsed}]" @click="toggleDropDown" >

            <input type="text" :value="value" v-show="false" />

            <input type="text" readonly="readonly" class="select" :value="formatted(computedValue)" :disabled="disabled">

            <arrow class="arrow" :active="collapsed" :width="24" :height="24" />

        </div>

        <drop-down class="drop-down" :options="options" :computedValue="computedValue" @selectOption="selectOption" :open="!collapsed" @click-outside="toggleDropDown">

            <template v-slot:option="{ option }">

                <slot name="option" v-bind:option="option"></slot>

            </template>

        </drop-down>

    </form-group>

</template>

<script>
    import FormGroup from '~/components/inputs/form-group'
    import DropDown from '~/components/drop-down'
    import Arrow from '~/components/arrow'

    export default {
        data() {
            return {
                collapsed: true
            }
        },

        components: {
            FormGroup,
            DropDown,
            Arrow
        },

        props: {
            label: {
                type: String,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            options: {
                type: Array,
                required: true
            },
            value: {
                validator(value) {
                    return value === null
                        || typeof value === 'string'
                        || typeof value === 'number'
                        || typeof value === 'boolean'
                        || typeof value === 'object'
                },
                required: true
            },
            rules: {
                type: String,
                default: ''
            },
            customErrorMessages: {
                type: Object,
                required: false
            },
            defaultOption: {
                type: [String, Boolean],
                default() {
                    return this.$t('select_an_option')
                }
            },
            disabled: {
                type: Boolean,
                default: false
            },
            bgTransparent: {
                type: Boolean,
                required: false
            }
        },

        methods: {
            toggleDropDown() {

                this.collapsed = !this.collapsed
            },

            selectOption(option) {

                this.toggleDropDown()

                this.$emit('input', option, this.rules, this.name)
            },

            formatted(option) {

                return typeof option === 'string' ? option.charAt(0).toUpperCase() + option.slice(1) : option
            },

        },

        computed: {
            computedValue() {
                if (typeof this.value === 'string' || typeof this.value === 'number' || typeof this.value === 'boolean' || this.value === null) {

                    if (this.value === '') return this.defaultOption

                    let index = this.options.findIndex(option => option.value === this.value)

                    if (index >= 0) return this.options[index]['text']
                }

                else if (typeof this.value === 'object') {

                    const stringifiedValue = JSON.stringify(this.value);

                    if (!Object.keys(this.value).length) {

                        if (this.options.some(option => JSON.stringify(option.value) === stringifiedValue)) {

                            const index = this.options.findIndex(option => JSON.stringify(option.value) === stringifiedValue)

                            return this.options[index]['text']
                        }

                        return this.defaultOption
                    }

                    if (typeof this.value['_jv'] !== 'undefined') {
                        const optionsGuaranteeFund = this.options.filter(option => option.value['_jv'] !== undefined)

                        const index = optionsGuaranteeFund.findIndex(option => option.value['_jv'].id === this.value['_jv'].id && option.value['_jv'].type === this.value['_jv'].type)

                        if (index >= 0) return this.options[index]['text']
                    }

                    if (this.options.some(option => JSON.stringify(option.value) === stringifiedValue)) {

                        let index = this.options.findIndex(option => JSON.stringify(option.value) === stringifiedValue)

                        return this.options[index]['text']
                    }
                }

                return ''
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "select_an_option": "Sélectionnez une option"
        }
    }
</i18n>

<style lang="scss" scoped>
    $input-height: 50px;
    $input-background: #EFEFEF;
    $green-oskar: #BED000;

    .select-box-input {
        position: relative;

        .input {
            position: relative;
            display: flex;
            align-items: center;
            height: $input-height;
            background: $input-background;
            border: 1px solid #E4E4E4;
            border-radius: 4px;
            padding-left: 16px;
            padding-right: 24px;
            box-sizing: border-box;

            &.active {
                border: 1px solid $green-oskar;
                background:  #FFF;
                box-shadow: 0px 0px 0px 2px rgba(190, 208, 0, 0.2);
            }

            &.errors {
                background-color: #FBE8E8;
                border-color: #F5A623;
            }

            &.transparent-background {
                background: transparent;
            }

            .select {
                user-select: none;
                background: transparent;
                border: 0;
                font-size: 11pt;
                cursor: pointer;
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                box-sizing: border-box;
                width: 100%;
            }

            .arrow {
                cursor: pointer;
                margin-left: 15px;
            }
        }

        &.disabled {
            opacity: 0.5;
        }


        .drop-down {
            z-index: 9;
            position: absolute;
            top: calc(100% + 5px);
            min-width: 100%;
            box-sizing: border-box;
            width: 100%;
            border-radius: 8px;
            border: none;
            background: var(--gnrique-white, #FFF);
            box-shadow: 0px 4px 13px 0px rgba(141, 141, 141, 0.08), 0px 7px 9px 0px rgba(141, 141, 141, 0.10);

            &::v-deep .drop-down-list {
                display: flex;
                padding: 4px 0px;
                flex-direction: column;
                align-items: flex-start;
                flex: 1 0 0;
                padding: 0px 0px;

                .drop-down-option{
                    display: flex;
                    align-items: center;
                    padding: 10px 20px;
                    cursor: pointer;
                    width: 100%;
                    box-sizing: border-box;
                    transition: background-color .2s ease-in-out;
                    margin: 0;
                    border: none;


                    color: var(--gris-gris-fonc, #393939);
                    font-size: 11pt;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                    &:hover {
                        background-color: #F2F2F2;
                    }
                }

                .no-options{
                    display: flex;
                    align-items: center;
                    padding: 10px 20px;
                    cursor: pointer;
                    width: 100%;
                    box-sizing: border-box;
                    transition: background-color .2s ease-in-out;
                    margin: 0;
                    border: none;


                    color: var(--gris-gris-fonc, #393939);
                    font-size: 11pt;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }

        &.collapse-top {
            .drop-down {
                top: calc(-100% - 50px);
                transform-origin: bottom;
            }
        }
    }
</style>
