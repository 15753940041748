<template>

    <jumbotron class="property-management-company-header background-property-manager-header" 

        :title="tradeName ? tradeName : businessName" 

        :subTitle="companyAddress.formatted ? companyAddress.formatted : ''"

        :headerNav="propertyManagementMainNav"
        
        :logoImgUrl="Object.keys(logo).length ? logo.url : ''">

    </jumbotron>

</template>

<script>
    import Jumbotron from '~/components/navigation/header/jumbotron'
    import { mapGetters } from 'vuex'

    export default {
        components: {
            Jumbotron
        },

        async fetch() {

            const { store } = this.$nuxt.context

            await store.dispatch('property-manager/fetchLogoRelationship', this.propertyManagementCompanyId)
        },

        computed: {
            ...mapGetters({
                businessName: 'property-manager/getBusinessName',
                propertyManagementCompanyId: 'property-manager/getId',
                tradeName: 'property-manager/getTradeName',
                companyAddress: 'property-manager/getAddress',
                logo: 'property-manager/getRelatedLogo',
                relatedBankAccounts: 'property-manager/getRelatedBankAccounts'
            }),

            propertyManagementMainNav() {
                return [
                    {
                        name: this.$t('dashboard'),
                        path: '/dashboard'
                    },
                    {
                        name: this.$t('properties'),
                        path: '/dashboard/properties'
                    },
                    {
                        name: this.$t('landlords'),
                        path: '/dashboard/landlords'
                    },
                    {
                        name: this.$t('tenants'),
                        path: '/dashboard/tenants'
                    },
                    {
                        name: this.$t('suppliers'),
                        path: '/dashboard/suppliers'
                    },
                    {
                        name: this.$t('bank'),
                        path: '/dashboard/bank-accounts',
                        disabled: !this.relatedBankAccounts.length
                    },
                    {
                        name: this.$t('accounting'),
                        path: '/dashboard/accounting/listing/accounting-entry-sets',
                        disabled: !this.relatedBankAccounts.length
                    }
                ]
            }
        }

    }
</script>

<i18n>
    {
        "fr": {
            "dashboard": "Accueil",
            "landlords": "Propriétaires",
            "properties": "Lots",
            "tenants": "Locataires",
            "suppliers": "Fournisseurs",
            "accounting": "Comptabilité",
            "bank": "Banque"
        }
    }
</i18n>

<style lang="scss">
    .property-management-company-header {
        .company-logo {
            width: 70px;
            height: 70px;
            background-color: white;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            box-sizing: border-box;
            cursor: pointer;

            .logo-url {
                width: 100%;
                max-height: 100%;
            }
        }
    }
</style>
