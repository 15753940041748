<template>

    <div class="payout-consent-result">

        <div class="header">

            <div class="title">

                <svg-icon class="icon" width="30" height="30" viewbox="-1 -8 20 30" strokeWidth="4.76" iconStrokeColor="#53A25A"><succes-icon/></svg-icon>

                <h3 class="group-title">{{isConfirmedWire ? $t('wire_confirmed') : $t('confirm_wire')}}</h3>

            </div>

            <p class="group-subtitle" v-if="isConfirmedWire === false">{{ $t('confirm_wire_description') }}</p>

        </div>

        <table-list class="table-list" :tBodies="payoutsRows" :tHead="payoutsHeader" v-if="isConfirmedWire === false" />

        <hr class="separator" v-if="sendNotificationToReceiver">

        <form-input :onSubmit="createNotificationMethod" v-if="sendNotificationToReceiver && payouts[0].status === 'booked'">

            <div class="report fullsize">

                <div class="report-header">

                    <h3 class="Label">{{$t('report_generated')}}</h3>

                    <button-input class="button background-transparent green-text-color small-padding" v-if="managementReportFile" :text="$t('see_pdf')" iconImg="view" iconColor="#BED000" @click="fullscreen = true"/>

                </div>

                <div class="text-area-input">

                    <p class="group-subtitle" v-if="sendNotificationConfirm">{{ $t('notification_confirmed') }}</p>

                    <template v-else>

                        <text-area-input name="management_report_message" :label="$t('send', {profileEmail: profileEmailAdress})" :placeholder="$t('management_report_message_placeholder')" v-model="notificationMessage" :rules="'required'" />

                        <button-input class="joined-file button green-text-color background-info-light" :text="$t('management_report', { year: this.$moment().year() })" iconImg="paperclip" />

                    </template>

                </div>

                <button-input class="button background-green grey-darker-text-color" :text="$t('send_report_to_client')" iconImg="message" iconColor="white" type="submit" :disabled="sendNotificationConfirm" :loading="loading" />

            </div>

        </form-input>

        <preview-pdf class="preview" :file="managementReportFile" v-if="fullscreen" @close="fullscreen = false" />

    </div>

</template>

<script>
    import PreviewPdf from '~/components/preview-pdf.vue'
    import ButtonInput from '~/components/inputs/button-input.vue'
    import TextAreaInput from '~/components/inputs/text-area-input.vue'
    import SvgIcon from '~/components/svg-icon.vue'
    import SuccesIcon from '~/components/icons/rent-status-icons/succes-icon.vue'
    import { mapGetters } from 'vuex'

    export default {
        name: 'PayoutConsentResult',

        components: {
            PreviewPdf,
            ButtonInput,
            TextAreaInput,
            SvgIcon,
            SuccesIcon
        },

        async fetch() {
            for (let payout of this.payouts) {

                let payoutBankAccount = this.$store.getters['tyltoApi/get'](`payout-bank-accounts/${payout['_jv']['relationships']['beneficiary']['data'].id}`)

                payout['beneficiary'] = payoutBankAccount
            }
        },

        data() {
            return {
                fullscreen: false,
                loading: false,
                sendNotificationConfirm: false
            }
        },

        props: {
            payouts: {
                type: Array,
                required: true
            },
            isConfirmedWire: {
                type: Boolean,
                required: true
            },
            sendNotificationToReceiver: {
                type: Object,
                required: false
            }
        },

        computed: {
            payoutsHeader() {
                return [
                    {
                        type: 'string',
                        label: this.$t('beneficiary_holder_name'),
                        field: 'beneficiary_holder_name',
                        highlight: true,
                    },
                    {
                        type: 'string',
                        label: this.$t('beneficiary_bank_name'),
                        field: 'beneficiary_bank_name',
                    },
                    {
                        type: 'clipboard-text',
                        label: this.$t('beneficiary_iban'),
                        field: 'beneficiary_iban'
                    },
                    {
                        type: 'clipboard-text',
                        label: this.$t('beneficiary_bic'),
                        field: 'beneficiary_bic',
                    },
                    {
                        type: 'clipboard-text',
                        label: this.$t('amount'),
                        field: 'payout_amount',
                        highlight: true,
                    },
                    {
                        type: 'clipboard-text',
                        label: this.$t('reference'),
                        field: 'payout_reference',
                        highlight: true
                    }
                ]
            },

            payoutsRows() {
                return this.payouts.map(payout => {
                    return {
                        beneficiary_holder_name: payout['beneficiary'].holder_name,
                        beneficiary_bank_name: payout['beneficiary'].bank_name,
                        beneficiary_iban: payout['beneficiary'].iban
                            .replaceAll(' ', '')
                            .match(/.{1,4}/g).join(' '),
                        beneficiary_bic: payout['beneficiary'].bic,
                        payout_amount: this.$n(payout.amount / 100, 'currency'),
                        payout_reference: payout['reference']
                    }
                })
            },

            notificationMessage: {
                get() {
                    return this.$store.getters[`notification/getMessage`]
                },
                set(newValue) {
                    return this.$store.commit(`notification/setMessage`, newValue)
                }
            },

            ...mapGetters({
                profileEmailAdress: 'profile/getEmailAddress',
                managementReportFile: 'management-report/getRelatedDocument'
            })
        },

        methods: {
            async createNotificationMethod(profile) {
                try {

                    this.loading = true

                    this.$store.commit('notification/setRelatedFile', this.managementReportFile)

                    this.$store.commit('notification/setRelatedReceiver', this.sendNotificationToReceiver)

                    await this.$store.dispatch('notification/createNotification', {
                        params: {
                            notify: 'management_report_created'
                        }
                    })

                    this.sendNotificationConfirm = true

                    this.$toast.success(this.$t('sended', { email: this.sendNotificationToReceiver.email_address }))

                } catch(error) {

                    throw error

                } finally {

                    this.loading = false
                }
            }
        }
    }
</script>

<i18n>
    {
        "fr": {
            "wire_confirmed": "Le virement a bien été effectué",
            "confirm_wire": "Vous n’avez plus qu’à effectuer le virement",
            "confirm_wire_description": "Effectuez le virement manuellement depuis l’interface de votre banque.",
            "beneficiary_holder_name": "Nom du titulaire",
            "amount": "Montant",
            "beneficiary_bank_name": "Nom de l'établissement",
            "beneficiary_iban": "IBAN",
            "beneficiary_bic": "BIC",
            "report_generated": "Le rapport de gestion a été généré",
            "see_pdf": "Voir le rapport en PDF",
            "send": "Envoyez le rapport à {profileEmail}",
            "send_report_to_client": "Envoyer le rapport au client",
            "reference": "Référence bancaire",
            "notification_confirmed": "La notification a bien été envoyée !",
            "sended": "E-mail envoyé à {email}",
            "management_report_message_placeholder": "Entrez votre message ici",
            "management_report": "Rapport de gestion {year}"
        }
    }
</i18n>

<style lang="scss" scoped>
    .payout-consent-result {
        .group-subtitle{
            font-size: 14px;
            color: #8D939F;
            margin-bottom: 10px;
        }

        .group-title{
            font-size: 24px;
            color: #333333;
            margin-bottom: 24px;
        }

        .icon{
            background-color: #D7FFE7;
            border-radius: 96px;
            padding: 5px;
            stroke-width: 4;
        }

        .table-list {
            margin-top: 20px;
        }

        .separator {
            margin: 16px auto;
            height: 1px;
            width: 100%;
            background: #DEE3EF;
            border-radius: 5px;
            border: none;
        }

        .report {
            background: #EFEFEF;
            mix-blend-mode: normal;
            border-radius: 6px;
            padding: 20px 26px;
            .report-header{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                .Label {
                    font-weight: 700;
                    font-size: 16px;
                    color: #565E74;
                }
            }

            .text-area-input{
                position: relative;
                .joined-file {
                    position: absolute;
                    top: 170px;
                    right: 12px;
                    padding: 2px 12px;
                }
            }

            .group-subtitle {
                margin-bottom: 24px;
            }
        }

        .header{
            .title{
                display: flex;
                gap: 12px;
                margin-bottom: 24px;
            }

            .see_all{
                padding: 0;
            }
        }
    }
</style>
