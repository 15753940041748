<template>

    <form-group :class="['text-input', { 'disabled': disabled }]" :label="label" :name="name" :rules="rules"
                :tooltip="!value.length ? tooltip : ''" :disabled="disabled" v-slot="{ id, errors, setErrors }"
                ref="formGroup">

        <div class="group">

            <svg-icon :class="['icon', { 'reverse': iconReverse }]" v-if="iconImg === 'calendar'"
                      :iconColor="iconColor ? iconColor : '#DEE3EF'" width="16" height="16" @click.native="emitFocus">
                <calendar-icon/>
            </svg-icon>

            <svg-icon :class="['icon', { 'reverse': iconReverse }]" v-else-if="iconImg === 'search'"
                      :iconColor="iconColor" width="16" height="16" @click.native="emitFocus">
                <search-icon/>
            </svg-icon>

            <the-mask :class="['input', { 'errors': errors.length }]" type="text" :id="id" :mask="mask" :masked="true"
                      :value="value" @input="emitInput($event.replaceAll(' ', ''))" :disabled="disabled"
                      :placeholder="placeholderIfExist" @focus.native="emitFocus" ref="input" v-if="mask"/>

            <input :class="['input', { 'errors': errors.length }]" type="text" :id="id" :value="value"
                   :placeholder="placeholderIfExist" :disabled="disabled" @input="emitInput($event.target.value, rules, name)"
                   ref="input" v-else/>

        </div>

    </form-group>

</template>

<script>
import SvgIcon from '~/components/svg-icon'
import CalendarIcon from '~/components/icons/input-icons/calendar-icon'
import FormGroup from '~/components/inputs/form-group'

export default {
    components: {
        SvgIcon,
        CalendarIcon,
        FormGroup
    },

    props: {
        label      : {
            type    : String,
            required: false
        },
        name       : {
            type    : String,
            required: true
        },
        value      : {
            type    : String,
            required: true
        },
        placeholder: {
            type   : String,
            default: ''
        },
        mask       : {
            type   : String,
            default: ''
        },
        rules      : {
            type   : [String, Object],
            default: ''
        },
        tooltip    : {
            type    : String,
            required: false
        },
        iconImg    : {
            type    : String,
            required: false
        },
        iconColor  : {
            type    : String,
            required: false
        },
        iconReverse: {
            type   : Boolean,
            default: false
        },
        disabled   : {
            type   : Boolean,
            default: false
        }
    },

    methods: {
        setFocus() {
            this.$refs['input'].focus();
        },

        emitInput(value, rules, name) {
            this.$emit('input', value, rules, name)
        },

        formattedMask(mask) {
            let res = mask.split('').map(char => {
                if (char === '#') return Math.floor(Math.random() * Math.floor(9))
                else return char
            })

            return res.join('')
        },

        emitFocus() {
            this.$emit('focus')
        },

        setErrors(errors) {
            this.$refs.formGroup.setErrors(errors)
        },
    },

    computed: {
        placeholderIfExist() {
            if (this.placeholder !== '') return 'Ex : ' + this.placeholder
            else if (this.mask !== '') return 'Ex : ' + this.formattedMask(this.mask)
        }
    }
}
</script>

<style lang="scss">
$input-height: 50px;
$border-color: #DEE3EF;

.text-input {
    .group {
        position: relative;
        display: flex;

        .input {
            height: $input-height;
            border: 1px solid $border-color;
            border-radius: 4px;
            padding: 0 20px;
            font-size: 11pt;
            box-sizing: border-box;
            flex: 1;

            &::placeholder {
                opacity: 0.5;
            }

            &.errors {
                background-color: #FBE8E8;
                border-color: #F5A623;
            }
        }

        .icon {
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            left: 16px;

            & ~ .input {
                padding-left: 48px;
            }

            &.reverse {
                left: auto;
                right: 16px;

                & ~ .input {
                    padding-left: 20px;
                    padding-right: 48px;
                }
            }
        }
    }

    &.disabled {
        opacity: 0.5;
    }
}
</style>
